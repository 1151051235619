<template>
    
        <v-stepper-vertical v-model="step_stepper" v-if="!loading">

            <template v-slot:default="{ step }">

                <!-- Step 1 Provider or Connection-->
                <v-stepper-vertical-item
                    :complete="step > 1" 
                    value="1"
                    :editable="!company.id && !company.user_id"
                    subtitle="Company can be associated to a connection or a provider"
                    hide-actions
                >
                    <template v-slot:title>
                        Select where to associate the company
                        <v-chip v-if="company.provider && company.provider.country_code" class="ml-10" variant="outlined" color="accent">Provider</v-chip>
                        <v-chip v-if="company.user" class="ml-10" variant="outlined" color="accent">Connection</v-chip>
                    </template>

                    <v-btn class="ml-2" color="primary" @click="firstStep(false)">
                        Connection
                    </v-btn>
                    <v-btn class="ml-5" color="primary" @click="firstStep(true)">
                        Provider
                    </v-btn>

                </v-stepper-vertical-item>

                <!-- Step 2 Select-->
                <v-stepper-vertical-item
                    :complete="step > 2" 
                    value="2"
                    :editable="step > 1 && !company.id && !company.user_id"
                    hide-actions
                >

                    <template v-slot:title>
                        Choose the connection or provider
                        <v-chip v-if="company.provider && company.provider.country_code" class="ml-10" variant="outlined" color="accent">{{ company.provider.country_code }}-{{ company.provider.party_id }}</v-chip>
                        <v-chip v-if="company.user" class="ml-10" variant="outlined" color="accent">{{ company.user.username }}</v-chip>
                    </template>

                    <v-autocomplete
                        v-if = "!company.provider"
                        v-model="company.user_id"
                        :items="connections"
                        :rules="[v => !!v || 'Item is required']"
                        label="Connection"
                        single-line
                        variant="outlined"
                        @update:modelValue="step_stepper = 3"
                    ></v-autocomplete>

                    <v-autocomplete
                        v-else
                        v-model="company.provider_id"
                        :items="externalProviders"
                        :rules="[v => !!v || 'Item is required']"
                        label="Provider"
                        single-line
                        variant="outlined"
                        @update:modelValue="step_stepper = 3"
                    ></v-autocomplete>
            
                </v-stepper-vertical-item>

                <!-- Step 3 company data -->
                <v-stepper-vertical-item
                    :complete="step > 3" 
                    value="3"
                    title="Fill the company data"
                    hide-actions
                >

                    <!-- Name-->
                    <v-text-field
                        v-model="company.name"
                        label="Name"
                        class="pt-2"
                        :rules="[v => (v && v.length <= 63) || 'Item required, max 63 characters']"
                        variant="outlined"
                    ></v-text-field>

                    <!-- Technical contacts -->
                    <v-row>
                        <v-col class="pb-2"
                        cols="12"
                        sm="12"
                        md="12"
                        >
                        <span class="item-label">Technical Contacts</span>
                        </v-col>
                    </v-row>
                    <template v-if="company.technical_contacts">
                            <v-row v-for="(value, index) in company.technical_contacts">
                                <v-col class="pt-1"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                <v-row class="pt-3 pl-3">
                                    <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ index + 1 }} - </span>
                                    <v-text-field
                                        v-model="company.technical_contacts[index].name"
                                        label="Name"
                                        variant="underlined"
                                        hide-details
                                    ></v-text-field>
                                </v-row>
                                </v-col>
                                <v-col class="pt-1 pl-6"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                <v-row class="pt-3 pl-3">
                                    <v-text-field
                                        v-model="company.technical_contacts[index].email"
                                        label="Email"
                                        variant="underlined"
                                        hide-details
                                    ></v-text-field>
                                </v-row>
                                </v-col>
                                <v-col class="pt-1 pl-6"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                <v-row class="pt-3">
                                    <v-text-field
                                        v-model="company.technical_contacts[index].phone"
                                        label="Phone"
                                        variant="underlined"
                                        hide-details
                                    ></v-text-field>
                                    <v-btn class=" ml-2"
                                        icon="mdi-close"
                                        variant="flat"
                                        @click="() => company.technical_contacts.splice(index, 1)"
                                    />
                                </v-row>
                                </v-col>
                            </v-row>
                            <v-row :set="newItemIndex = company.technical_contacts.length" class="mt-0 pt-0">
                                <v-col
                                    class="pt-1"
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                <v-row class="pt-3 pl-3 pb-3">
                                    <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ newItemIndex + 1 }} - </span>
                                    <v-btn class="mt-4"
                                    variant="text"
                                    max-width="auto"
                                    @click="() => company.technical_contacts.push({ name: null, email: null, phone: null })">
                                    <span color="grey">Add +</span>
                                    </v-btn>
                                </v-row>
                                </v-col>
                            </v-row>
                    </template>

                    <!-- Contact Channels-->
                    <v-row>
                        <v-col class="pt-6"
                        cols="12"
                        sm="12"
                        md="12"
                        >
                        <span class="item-label">Contact Channels</span>
                        </v-col>
                    </v-row>
                    <template v-if="company.channels">
                            <v-row v-for="(value, index) in company.channels" :key="`${index}-channels`">
                                <v-col class="pt-1"
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-row class="pt-3 pl-3">
                                    <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ index + 1 }} - </span>
                                    <v-text-field
                                        v-model="company.channels[index].description"
                                        label="Description"
                                        variant="underlined"
                                        hide-details
                                    ></v-text-field>
                                </v-row>
                                </v-col>
                                <v-col class="pt-1 pl-6"
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                <v-row class="pt-3">
                                    <v-text-field
                                        v-model="company.channels[index].url"
                                        label="URL"
                                        variant="underlined"
                                        hide-details
                                    ></v-text-field>
                                    <v-btn class="ml-2"
                                        icon="mdi-close"
                                        variant="flat"
                                        @click="() => company.channels.splice(index, 1)"
                                    />
                                </v-row>
                                </v-col>
                            </v-row>
                            <v-row :set="newItemIndex = company.channels.length" class="mt-0 pt-0">
                                <v-col
                                    class="pt-1"
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                <v-row class="pt-3 pl-3 pb-3">
                                    <span class="text-caption pr-3 pt-4" style="line-height:34px;">{{ newItemIndex + 1 }} - </span>
                                    <v-btn class="mt-4"
                                        variant="text"
                                        max-width="auto"
                                        @click="() => company.channels.push({ description: null, url: null })"
                                    >
                                        <span color="grey">Add +</span>
                                    </v-btn>
                                </v-row>
                                </v-col>
                            </v-row>
                    </template>

                    <!-- Start Charging Methods-->
                    <v-select
                        v-model="company.start_charging_methods"
                        :items="['RFID', 'COMMANDS', 'RFID_AND_COMMANDS']"
                        label="Start Charging Methods"
                        class="pt-6"
                        single-line
                        variant="outlined"
                    ></v-select>

                    <!-- Notes-->
                    <v-text-field
                        v-model="company.notes"
                        label="Notes"
                        class="pt-2"
                        :rules="[v => v ? (v && v.length <= 255) || 'Max 255 characters' : true]"
                        variant="outlined"
                    ></v-text-field>

                    <!-- Map URL-->
                    <v-text-field
                        v-model="company.map_url"
                        label="Map URL"
                        class="pt-2"
                        :rules="[v => v ? (v && v.length <= 255) || 'Max 255 characters' : true,
                        v => v ? (v && v.startsWith('https')) || 'URL must start with https://' : true]"
                        variant="outlined"
                    ></v-text-field>

                    <v-row class="d-flex justify-end pr-4 pb-6">
                        <v-btn 
                            class="ml-2"
                            color="accent"
                            variant="flat" 
                            @click="save" 
                            :disabled="!company.name"
                            prepend-icon="mdi-content-save"
                        >
                            {{ buttonSave }}
                        </v-btn>
                    </v-row>

                </v-stepper-vertical-item>

            </template>

        </v-stepper-vertical>

</template>

<script>
import { EventBus } from "@/event-bus"
export default {
    name: "company-form",
    props: {
        previousCompany: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    inject: ['$api'],
    data() {
        return {            
            loading: false,
            step_stepper: 1,
            connections: [],
            externalProviders: [],
            company: {
                id: null,
                technical_contacts: [],
                channels: [],
                user_id: null,
            }
        }
    },
    mounted() {
        let vm = this
        vm.loading = true

        //nextTick is necesary to access props from mounted
        vm.$nextTick(function () {
            vm.company = {
                ...vm.previousCompany,
                technical_contacts: Array.isArray(this.previousCompany.technical_contacts) ? this.previousCompany.technical_contacts : [],
                channels: Array.isArray(this.previousCompany.channels) ? this.previousCompany.channels : [],
            }

            if(vm.company.id) vm.step_stepper = 3

            if(vm.company.user_id){
                vm.firstStep(false)
                vm.step_stepper = 3
            }

        })

        vm.loading = false
    },
    computed: {
        formCompany: {
            get: function() {
                const newCompany = { 
                user_id: null,
                provider_id: null,
                technical_contacts: [],
                channels: [],
                start_charging_methods: null,
                name: null,
            }
            return this.company || newCompany
            },

            set: function() {
                return this.company
            }
            
        },
        buttonSave: function() { return this.formCompany.id ? 'Save' : 'Create company' }
    },
    methods: {
        firstStep(value) {
            this.step_stepper = 2
            this.company.provider = value

            if (value) {
                this.getProviders()
                this.company.user_id = null
            } else {
                this.getConnections()
                this.company.provider_id = null
            }
        },
        async getConnections(){
            await this.$api.get(`/connections`)
            .then(res => {

                if(this.company.user_id) {
                    res.data = res.data.filter(c => c.id == this.company.user_id)
                    this.company.user = res.data[0]
                }

                this.connections = res.data
                    .map(c => {
                        return {
                            ...c, 
                            title: `${c.company?.name || c.username} - ${c.type == 'provider' ? 'DIRECT' : c.type.toUpperCase() }`, 
                            value: c.id 
                        } 
                    })
            })
            .catch(this.showError)
        },
        async getProviders(){
            await this.$api.get("/providers-external")
            .then(res => {
              this.externalProviders = res.data
                .map(p => {
                    return { 
                        ...p, 
                        title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                    } 
                })
            })
            .catch(this.showError)
        },
        save(){
            if( this.formCompany.id ){
                this.$api.patch(`/company/${this.formCompany.id}`, this.formCompany)
                    .then(resp => {
                        this.formCompany = resp.data
                        this.showSuccess("Data saved successfully")
                    })
                    .catch(resp => {
                        this.showError(resp)
                    })

            } else {

                this.$api.post(`/company`, this.formCompany)
                    .then(() => {
                        this.showSuccess("Data saved successfully")
                        EventBus().emit("company-created")
                    })
                    .catch(this.showError)

            }
        }
    }
}
</script>

<style scoped>
    :deep(.v-expansion-panel-text){
        padding-inline-start: 50px !important;
        padding-inline-end: 50px !important;
    }
</style>