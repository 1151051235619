<template>
    <v-container>
        <v-col cols="12">
            <v-row align="center" justify="center" style="height: 600px;">
                <v-form @submit.prevent="login">
                    <v-card class="elevation-12" style="width: 600px;">
                        <v-toolbar color="primary">
                            <v-toolbar-title style="margin: 0 auto">
                                <div class="d-flex align-center justify-center">
                                    <v-avatar size="54" rounded="0">
                                        <v-img src="@/assets/wenea-ico.png"/>
                                    </v-avatar>
                                    <span class="display-1 ml-4 align-self-center">OCPI Backoffice</span>
                                </div>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field
                                type="email"
                                v-model="email"
                                label="E-mail"
                                prepend-icon="mdi-account"
                                variant="underlined"
                            ></v-text-field>
                            <v-text-field
                                type="password"
                                v-model="password"
                                label="Password"
                                prepend-icon="mdi-lock"
                                variant="underlined"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn 
                                prepend-icon="mdi-login"
                                variant="flat"
                                block
                                type="submit"
                                color="accent"
                                :loading="loading"
                            >
                                Log in
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-row>
        </v-col>
    </v-container>

    <v-snackbar v-model="snackbar">
        {{ errorMessage }}
        <v-btn color="error" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            password: "",
            errorMessage: "",
            loading: false,
            snackbar: false
        };
    },
    methods: {
        login() {
            let email = this.email;
            let password = this.password;
            this.loading = true;

            this.$store
                .dispatch("login", { email, password })
                .then(() => {
                    this.loading = false;
                    this.$router.push("/");
                })
                .catch(err => {
                    this.loading = false;
                    this.errorMessage = err.response.data;
                    this.snackbar = true;
                });
        }
    }
};
</script>
