import axios from 'axios'
import store from './store'


const backendUrl = window.location.hostname === "localhost" ? "http://localhost:8899" : window.location.origin
console.log(`API URL target: ${backendUrl}/api/v1`)

const api = axios.create({
    baseURL: `${backendUrl}/api/v1`
})

/**
 * Si alguna de las respuestas HTTP a la API devuelve error 401 (Unauthenticatd), hacemos logout 
 * y redirijimos a la pantalla de login
 */
api.interceptors.response.use(
    response => { return response },
    error => {
        if (error.response.status === 401) { store.dispatch('logout') }
        return Promise.reject(error)
    }
)

export default api