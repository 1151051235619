<template>
    <v-card variant="outlined" style="width: 100%">
      <v-card-text class="pa-8">
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col md="6">
              <v-text-field
                v-model="kwh"
                class="accent"
                label="kWh"
                variant="outlined"
                density="compact"
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-text-field>
            </v-col>

            <v-col md="6">
              <v-text-field
                v-model="minutes"
                class="accent"
                label="Minutes"
                variant="outlined"
                density="compact"
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-text-field>
            </v-col>

            <v-col md="6">
              <v-autocomplete
                v-model="chargePoint"
                :items="selectableChargePoints"
                class="accent"
                label="Charge point"
                variant="outlined"
                density="compact"
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              >
              </v-autocomplete>
            </v-col>

            <v-col md="6">
              <v-select
                v-model="connector"
                :items="chargePointConnectors"
                class="accent"
                label="Connector"
                item-value="id"
                variant="outlined"
                density="compact"
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              >
                <template v-slot:selection="{ item }">
                  <v-row>
                    <v-chip class="ml-2" color="primary" size="small">{{ item.raw.id }}</v-chip>
                    <span class="ml-1" style="line-height: 24px;">{{ item.raw.power_type }}</span>
                  </v-row>
                </template>
                <template v-slot:item="{props, item}">
                    <v-list-item v-bind="props" v-if="item" title>
                        <v-chip color="primary" size="small">{{ item.raw.id }}</v-chip>
                        <span class="ml-2">{{ item.raw.power_type }}</span>
                        <v-chip class="ml-2" color="accent" variant="outlined" size="small">{{ item.raw.max_power }} kWh</v-chip>
                    </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col md="12">
              <v-autocomplete
                v-model="provider"
                :items="emspProviders"
                class="accent"
                label="Provider"
                variant="outlined"
                density="compact"
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Result -->
          <v-row :class="`justify-end ${result ? 'pr-4' : 'pr-1'} pt-4`" align="center">
            <v-text-field
              class="result-input accent"
              label="Price with vat excluded"
              v-model="excl_vat"
              variant="outlined"
              density="compact"
              hide-details
              readonly
            ></v-text-field>
            <span class="ml-2">{{ currency }}</span>
            <template v-if="inc_vat">
              <v-text-field
                class="result-input ml-4"
                label="Price with vat included"
                color="accent"
                v-model="inc_vat"
                variant="outlined"
                density="compact"
                hide-details
                readonly
              ></v-text-field>
              <span class="ml-2">{{ currency }}</span>
            </template>
            <span v-if="applied_tariff_id" class="ml-4">
              Aplicated tariff 
              <v-chip class="ml-2 result-chip" 
                variant="outlined" 
                size="small"
                color="accent"
                :to="`/tariffs/${applied_tariff_id}`" 
              >
                {{ applied_tariff_id }}
              </v-chip>
            </span>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  data(){
    return {
      valid: true,
      result: null,
      kwh: 0,
      minutes: 0,
      chargePoint: null,
      connector: null,
      provider: null,

      chargePoints: [],
      emspProviders: []
    }
  },
  inject: ['$api'],
  computed: {
    selectableChargePoints() {
      return this.chargePoints.map(cp => { return { title: cp.charge_point_reference_name, value: cp } })
    },
    chargePointConnectors() {
      return this.chargePoint?.connectors
    },
    excl_vat() {
      return this.result?.invoice?.total_cost?.excl_vat ? this.result.invoice.total_cost.excl_vat : 0
    },
    inc_vat() {
      return this.result?.invoice?.total_cost?.inc_vat ? this.result.invoice.total_cost.inc_vat : 0
    },
    currency() {
      return this.result ? this.result.currency : null
    },
    applied_tariff_id() {
      return this.result ? this.result.applied_tariff_id : null
    }
  },
  mounted() {
    this.$api.get(`/tools/chargepoints-connectors`)
      .then(resp => {this.chargePoints = resp.data })
      .catch(this.showError)

    this.$api.get("/providers-external")
          .then(res => {
              this.emspProviders = res.data
                  .filter(p => p.role && p.role.indexOf('EMSP') > -1)
                  .map(p => { 
                      return {
                          title: p.user.company?.name ? 
                            `${p.country_code}-${p.party_id} ${p.user.company.name} (${p.user.type})` 
                            : `${p.country_code}-${p.party_id} ${p.user.username} (${p.user.type})` ,
                          value: p.id
                      }
                  })
          })
          .catch(this.showError)
  },
  methods:{
    calculatePrice() {
      if (!this.$refs.form || !this.$refs.form.validate()) {
        return this.result = null
      }

      this.$api.get(`/calculate-charge-price?kwh=${this.kwh}&minutes=${this.minutes}&connector=${this.connector}&provider=${this.provider}`)
        .then(resp => this.result = resp.data)
        .catch(_ => {
            this.result = null
            this.showError('Incorrect data entered or there is no tariff for this provider and connector.')
          })
    }
  }
}
</script>

<style scoped>
  :deep(.result-input){
    max-width: 150px;
  }

  :deep(.result-input)input{
    text-align: center;
  }

  :deep(.result-chip){
    min-width: 45px;
    justify-content: center;
  }
</style>