<template>
  <div>
    <v-data-table
        :headers="[
            {key: 'action', sortable:false, cellClass: 'td-linkto', width: '5px'},
            {title: 'Id.', key: 'id' },
            {title: 'Provider', key: 'provider'}
        ]"
        :items="providersWithoutTariffs"
        :loading="loading"       
        class="accent-table rounded-table"
        :sort-by="[{ key: 'id', order: 'asc' }]"
        density="compact"
        no-data-text="No providers found without assigned tariffs"
    >
       <template v-slot:item.action="{ item }">
            <v-btn variant="plain"
                size="small" 
                :to="`/providers/${item.id}/connectors-without-tariffs`" 
                >
                <v-icon size="small" >mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.provider="{ item }">
            <ProviderName
                :provider="{ id: item.id, country_code: item.provider_name.split('-')[0] , party_id: item.provider_name.split('-')[1] }"
            />
        </template>
    </v-data-table>    
  </div>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        ProviderName
    },
    inject: ['$api'],
    data() {
      return {
        loading: false,
        providersWithoutTariffs: []
      }
    },
    async mounted(){  
        this.loadProvidersWithoutTariffs()      
    },
    methods: {
      loadProvidersWithoutTariffs(){
        this.loading = true

        this.$api.get(`/providers-without-tariffs`)
            .then(resp => this.providersWithoutTariffs = resp.data)
            .catch(this.showError)
            .then(() => this.loading = false)
      }
    }
}
</script>