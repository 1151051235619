<template>
  <div>
    <v-card
      variant="outlined"
    >
      <v-card-title class="pt-2 pb-6">Import locations from provider</v-card-title>

      <v-card-text>
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12" lg="2" xl="1">
              <v-menu
                ref="menu"
                class="filter-field"
                v-model="showDateFromPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                  <template v-slot:activator="{ props }">
                      <v-text-field
                        v-bind="props"
                        class="filter-field accent"
                        v-model="selectedDateFrom"
                        label="Date from"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        variant="outlined"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="selectedDateFromPicker"
                      @update:modelValue="updateFromPickerSelected"
                      no-title
                  ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-menu
                ref="menu"
                v-model="showDateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-bind="props"
                    class="filter-field"
                    v-model="selectedDateTo"
                    label="Date to"
                    prepend-inner-icon="mdi-calendar"
                    color="accent"
                    readonly
                    clearable
                    variant="outlined"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDateToPicker"
                    @update:modelValue="updateToPickerSelected"
                    no-title
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="selectedOffset"
                :rules="[rules.isANumber]"
                label="Offset"
                color="accent"
                variant="outlined"
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="selectedLimit"
                :rules="[rules.isANumber]"
                label="Limit"
                color="accent"
                clearable
                variant="outlined"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="selectedMaxPages"
                :rules="[rules.isANumber]"
                label="Max pages"
                color="accent"
                clearable
                variant="outlined"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="ocpiToCountryCode"
                :rules="[rules.length2]"
                label="OCPI to country code"
                color="accent"
                clearable
                variant="outlined"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="ocpiToPartyId"
                :rules="[rules.length3]"
                label="OCPI to party id"
                color="accent"
                clearable
                variant="outlined"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-text-field 
                class="filter-field"
                v-model="delayBetweenRequests"
                :rules="[rules.isANumber]"
                label="Time between requests"
                color="accent"
                variant="outlined"
              >
                <template v-slot:append-inner>s</template>
              </v-text-field>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
                <v-btn v-if="!loading"
                  :disabled="!valid"
                  block
                  variant="outlined"
                  color="accent"
                  @click="updateLocations"
                >
                  Import<v-icon end>mdi-table-arrow-up</v-icon>
                </v-btn>

                <v-btn v-else
                  block
                  variant="outlined"
                  color="accent"
                  @click="stopFetchLocations"
                >
                  Force stop
                </v-btn>
            </v-col>

            <v-col cols="12" lg="2" xl="1" v-if="loading">
              <v-chip class="ml-2" variant="outlined" color="accent">
                  <v-progress-circular
                      class="mr-2"
                      :size="16"
                      width="2"
                      indeterminate
                      color="accent"
                  />
                  <template v-if="stopping">
                      Stopping
                  </template>
                  <template v-else>
                      Exported {{ locationCollectionReport.fetched || 0 }} of {{ locationCollectionReport.total ? locationCollectionReport.total : '?' }}
                  </template>
              </v-chip>
            </v-col>

            <v-col cols="12" lg="2" xl="1">
              <v-btn 
                  variant="text" @click="showReport = !showReport" block
              >
                  Last report <v-icon end>{{ showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <transition name="slide-y-transition">
            <div v-if="showReport" class="ml-4 mb-2" style="">
              <v-btn variant="text" icon @click="showReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
              <v-chip class="ml-2" variant="outlined" color="blue">Updated {{ locationCollectionReport.updated }}</v-chip>
              <v-chip class="ml-2" variant="outlined" color="green">Created {{ locationCollectionReport.created }}</v-chip>
              <v-chip class="ml-2" variant="outlined" color="red">Errors {{ locationCollectionReport.errors }}</v-chip>
              <v-chip class="ml-2" variant="outlined"><v-icon start color="grey">mdi-calendar</v-icon> {{ dateFullFormat(locationCollectionReport.created_at) }}</v-chip>
              <v-chip class="ml-2" variant="outlined" :color="locationCollectionReport.success ? 'green' : 'red'">
                Finished <v-icon end :color="locationCollectionReport.success ? 'green' : 'red'">{{ locationCollectionReport.success ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
              </v-chip>
            </div>
          </transition>
        </v-row>

      </v-card-text>
    </v-card>  

    <JsonDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import Bws from "@/plugins/BackofficeWebsocket.js"
import JsonDialog from "@/components/JsonDialog"

export default {
  name: "import-locations-from-provider",
  props: ["providerId"],
  components: {
    JsonDialog
  },
  inject: ['$api'],
  data() {
    return {
        valid: true,
        loading: false,
        stopping: false,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDateFromPicker: false,
        selectedDateTo: null,
        showDateToPicker: false,
        selectedOffset: null,
        selectedLimit: 10,
        selectedMaxPages: null,
        ocpiToCountryCode: null,
        ocpiToPartyId: null,
        delayBetweenRequests: 3,
        showReport: false,
        locationCollectionReport: null,
        rules: {
          isANumber: v => (!v || !isNaN(v)) || 'Must be a number',
          length2: v => (!v || v.length === 2) || '2 characters are required',
          length3: v => (!v || v.length === 3) || '3 characters are required',
        },
        selectedDateFromPicker: null,
        selectedDateToPicker: null,
    };
  },
  mounted() {
    this.getLastLocationCollectionReport()

    // Nos suscribimos a Redis para nuevos reportes
     this.reportsChannel = Bws.getSubscription(`location-import:location_collection:provider-${this.providerId}`)
    
    this.reportsChannel.on('message', (data) => {
      this.locationCollectionReport = data
      this.showReport = true
      
      if (data.hasOwnProperty('success')) {
        this.loading = false
        this.stopping = false
        if (data.errorMessage) {
          this.showError(data.errorMessage)
        } else {
          this.showSuccess('A new report of a location import process has been received.')
        }
      }

      this.$emit('data-imported')
    }) 
  },
  methods: {
    getLastLocationCollectionReport(){
      this.$api.get(`/provider/${this.providerId}/reports/location_collection`)
        .then(res => {
          this.locationCollectionReport = res.data
          this.loading = false
        })
        .catch(this.showError)
    },
    updateLocations() {
        if (!this.providerId || !this.$refs.form.validate()) { return }

        let queryParams = {
          provider_id: this.providerId,
          offset: this.selectedOffset ? this.selectedOffset : 0,
          limit: this.selectedLimit && this.selectedLimit > 0 ? this.selectedLimit : 10,
          delay_between_requests: this.delayBetweenRequests
        }

        if (this.selectedDateFrom) { queryParams.date_from = new Date(this.selectedDateFrom).toISOString() }
        if (this.selectedDateTo) { queryParams.date_to = new Date(this.selectedDateTo).toISOString() }
        if (this.selectedMaxPages) { queryParams.maxPages = this.selectedMaxPages }
        if (this.ocpiToCountryCode) { queryParams['OCPI-to-country-code'] = this.ocpiToCountryCode }
        if (this.ocpiToPartyId) { queryParams['OCPI-to-party-id'] = this.ocpiToPartyId }

        this.loading = true
        this.reportsChannel.emit('import', queryParams)
    },
    stopFetchLocations() {
      this.stopping = true
      this.reportsChannel.emit('stop', { provider_id: this.providerId })
    },
    showReportDialog(){
      EventBus().emit(
          "dialog-json:open",
          { title: 'Location collection report', json: this.locationCollectionReport }
      )
    },
    updateFromPickerSelected() {
        this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
        this.showDateFromPicker = false
    },

    updateToPickerSelected() {
        this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
        this.showDateToPicker = false
    },
  }
};
</script>