<template>
  <div>
    <div class="text-right mb-3">
        <v-btn variant="outlined" color="accent" @click="showDialog_addGroup">
            <v-icon start>mdi-plus</v-icon>
            Add group
        </v-btn>
    </div>

    <v-data-table
        :headers="[
            { value: 'action', sortable:false, cellClass: 'td-linkto', width: '5px' },
            { title: 'Id.', key: 'id' },
            { title: 'Name', key: 'name' },
            { title: 'Private?', key: 'is_private' },
            { title: 'Backend Id', key: 'backend_id' },
            { title: 'Updated at', key: 'updated_at' },
            { title: 'Actions', key: 'actions', sortable: false }
        ]"
        class="accent-table rounded-table"
        :items="locationGroups"
        disable-sort
        disable-pagination   
        density="compact"   
    >
      <template v-slot:item.action="{ item }">
            <v-btn 
                variant="plain"
                :to="`/location-group/${item.id}`" 
                >
                <v-icon>mdi-arrow-top-right</v-icon>
            </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-2 mt-2 mb-2"
          color="accent"
          elevation="0"
          :disabled="!provider || !provider.role.includes('EMSP')"
          @click.stop="sendLocations(item)"
        >Send</v-btn>
        <v-btn
          class="mr-2"
          color="accent"
          elevation="0"
          :disabled="!provider || !provider.role.includes('EMSP')"
          @click.stop="deleteLocationGroupUser(item)"
        >Delete</v-btn>
      </template>
    </v-data-table>

    <ProviderAddLocationGroupDialog />
    <SendLocationGroupDialog />
  </div>
</template>
<script>
import ProviderAddLocationGroupDialog from "@/components/provider/ProviderAddLocationGroupDialog";
import SendLocationGroupDialog from "@/components/provider/SendLocationGroupDialog";
import { EventBus } from "@/event-bus"

export default {
  name: "provider-location-groups",
  components: {
      ProviderAddLocationGroupDialog,
      SendLocationGroupDialog
  },
  props: ["provider"],
  inject: ['$api'],
  data() {
    return {
      locationGroups: [],
      loadingDeleteLocation: false
    }
  },
  watch: {

  },
  mounted() {
    this.loadGroups()

    EventBus().on("dialog-user-add-location-group:close", (res) => {
        this.loadGroups()
    })
  },
  methods: {
    async loadGroups(){
      let id = this.provider.id

      if (this.$store.state.user.type === "admin" && id) {
        this.locationGroups = await this.$api
            .get(`provider/${id}/location-groups`)
            .then((resp) => resp.data)
            .catch(this.showError)
      }
    },
    showDialog_addGroup() {
      EventBus().emit(
          "dialog-provider-add-location-group:open",
          this.provider
      )
    },
    sendLocations(item) {
      EventBus().emit(
          "dialog-send-location-group:open",
          { group_id: item.id, provider_id: this.provider.id }
      )
    },
    async deleteLocationGroupUser(item) {
      if(confirm("Are you sure?")){
        this.loadingDeleteLocation = true
        let providerId = this.provider.id
        let locationGroupId = item.id

        await this.$api
          .delete(`location-group-user/${providerId}/${locationGroupId}`)
          .catch(this.showError)
          .then((_) => {
            this.loadGroups()
          })

        this.loadingDeleteLocation = false
      }
    }
  },
};
</script>