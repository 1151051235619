<template>
  <div>
    <v-row class="filer-row"> 
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-menu
          ref="menu"
          class="filter-field"
          v-model="showDateFromPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          hide-details
        >
            <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  class="filter-field accent"    
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  variant="outlined"
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFromPicker"
                @update:modelValue="updateFromPickerSelected"   
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDateToPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details
        >
            <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  class="filter-field accent"
                  v-model="selectedDateTo"
                  label="Date to"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  variant="outlined"
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateToPicker"
                @update:modelValue="updateToPickerSelected"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-btn 
          class="mr-2"
          :loading="loading"
          @click="onFilter"
          color="accent"
          variant="text"
      >
          SEARCH
      </v-btn>

      <v-btn 
          color="accent"
          :loading="loadingCSV"
          variant="outlined" 
          @click="createCSV"
      >
          <v-icon start>mdi-file-download-outline</v-icon> CSV
      </v-btn>
      
    </v-row>

    <v-divider />

    <v-data-table
        :headers="[
            { title: 'Id.', key: 'id' },
            { title: 'Ocpi id', key: 'ocpi_id' },
            { title: 'Start Date', key: 'start_date_time' },
            { title: 'End Date', key: 'end_date_time' },
            { title: 'Total cost', key: 'total_cost' },
            { title: 'Total energy', key: 'total_energy' },
            { title: 'Time', key: 'total_time' },
            { title: 'Last updated', key: 'last_updated' },
        ]"
        :items="cdrs"
        disable-sort
        class="accent-table rounded-table"
        @update:options="this.updateTableOptions"
        density="compact"   
    >
        <template v-slot:item.start_date_time="{ item }">
          {{ dateFullFormat(item.start_date_time) }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
          {{ dateFullFormat(item.end_date_time) }}
        </template>
        <template v-slot:item.total_time="{ item }">
          {{ hoursToTimeTextFormat(item.total_time) }}
        </template>
        <template v-slot:item.total_cost="{ item }">
          <template v-if="item.total_cost">{{  item.total_cost.excl_vat }} {{ item.currency }}</template>
          <template v-else>-</template>
        </template>
        <template v-slot:item.last_updated="{ item }">
          {{ dateFullFormat(item.last_updated) }}
        </template>
    </v-data-table>

    <ImportCdrsFromProvider :provider-id="providerId" v-if="hasRole('CPO')" @data-imported="getCdrs()" class="mt-6"></ImportCdrsFromProvider>
  </div>
</template>
<script>
import ImportCdrsFromProvider from "@/components/provider/ImportCdrsFromProvider";

export default {
  name: "provider-cdrs",
  props: ["providerId","providerRole"],
  components: {
    ImportCdrsFromProvider
  },
  inject: ['$api'],
  data() {
    return {
      cdrs: [],
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,
      selectedDateFromPicker: null,
      selectedDateToPicker: null,

      loadingCSV: false,
        loading: false,
        pagination: {
            itemsPerPageOptions: [10, 20, 30, 50, 100],
            serverItemsLength: 0
        },
        tableOptions: {
            itemsPerPage: 25,
            page: 1,
        },      
        initialLoad: true,
    };
  },
  watch: {
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
            this.onFilter()
        },
        immediate: true
    },
  },
  methods: {
    async loadCdrs() {
      this.loading = true
            await this.getCdrs(true)
                .then(resp => {
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                    this.cdrs = resp.data
                })
                .catch(this.showError)
                .finally(() => this.loading = false)
    },
    hasRole(role){
        return this.providerRole && this.providerRole.includes(role)
    },
    async getCdrs(paginatedResponse){
            let queryParams = ''
            if(paginatedResponse) { 
                queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}` 
                if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }

            return await this.$api
                .get(`provider/${this.providerId}/cdrs${this.hasRole('CPO') ? `?role=CPO`:'?role=EMSP'}&${queryParams}`)
                .then( resp => {return resp} )
                .catch(this.showError)
    },
    async onFilter(){
        if(this.loading) return
        this.urlPush('dateFrom', this.selectedDateFrom)
        this.urlPush('dateTo', this.selectedDateTo)

        await this.loadCdrs()

        if (this.selectedDateFrom) {
            this.cdrs = this.cdrs.filter(cdr => this.dateFormat(cdr.last_updated) >= this.dateFormat(this.selectedDateFrom))
        }

        if (this.selectedDateTo) {
            this.cdrs = this.cdrs.filter(cdr => this.dateFormat(cdr.last_updated) <= this.dateFormat(this.selectedDateTo))
        }
    },
    updateTableOptions(args){
        this.tableOptions = args
        if(!this.initialLoad){
            this.loadCdrs()
        }
    },  
    async createCSV(){
            this.loadingCSV = true
            const cdrs = await this.getCdrs(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)
            
            
            let content = '"Id";"OCPI Id";"Start Date";"End Date";"Total cost";"Total energy";"Time";"Last updated"\r\n'
            
            cdrs.forEach(cdr => {
                content += `"${cdr.id}";"${cdr.ocpi_id}";"${cdr.start_date_time}";"${cdr.end_date_time}";"${cdr.total_cost}";"${cdr.total_energy}";"${cdr.total_time}";"${cdr.last_updated}"\r\n`
            })

            this.downloadBlob(content, `cdrs.csv`, "text/csv")
            this.tableLoading = false
    },
    updateFromPickerSelected() {
        this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
        this.showDateFromPicker = false
    },

    updateToPickerSelected() {
        this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
        this.showDateToPicker = false
    },
  }
};
</script>