<template>
    <v-card 
        class="elevation-0"
        :loading="loading"
        v-click-outside="(e) => onClickOutside(e)"
    >
        <v-row align="center">  
            
            <v-btn
                icon
                @click="show = !show"
                class="mt-3 mr-4"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-slide-y-transition group>
                <v-col v-if="show" class="pl-0 pt-0 pb-1">
                    <v-text-field
                        variant="underlined"
                        v-model="search_str"
                        clearable
                        placeholder="ID / OCPI ID Search"
                        color="accent"
                        hide-details
                        autofocus
                        width="200"
                        @click="onClick"
                        @keyup.enter="search"
                        density="compact"
                    ></v-text-field>
                </v-col>

                <v-col class="d-inline mt-2 pl-0 py-0" style="min-width: fit-content;" v-if="show">
                    <v-btn
                        variant="flat"
                        color="accent"
                        elevation="0"
                        rounded="0"
                        class="ml-1 rounded-s"
                        size="small"
                        style="min-width: 140px;"
                        @click.stop="search"
                    >
                        Search {{ search_model }}
                    </v-btn>
                    <v-menu transition="slide-y-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn
                            v-bind="props"
                            variant="flat"
                            rounded="0"
                            elevation="0"
                            color="accent rounded-e v-btn-groove"
                            size="small"
                            >
                                <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <v-list v-model="search_model" class="non-v-click-outside">

                            <v-list-item
                                v-for="(item, index) in search_model_options"
                                class="non-v-click-outside"
                                :value="item"
                                @click="search_model = item"
                            >
                                <v-list-item-title class="non-v-click-outside">{{ item }}</v-list-item-title>
                            </v-list-item>
                            
                        </v-list>
                    </v-menu>
                </v-col>
            </v-slide-y-transition>
        </v-row>

        <v-progress-linear color="accent" indeterminate v-if="loading"></v-progress-linear>

        <v-row class="elevation-20 mt-0 d-block" style="position: absolute; right: 0;" v-if="show">
            <v-col class="py-0 px-0" v-if="sessions.length" >
                <v-data-table
                    :items="sessions"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Sender Id.', key: 'sender_provider_id' },
                        { title: 'Receiver Id.', key: 'receiver_provider_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Created at', key: 'created_at' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" size="small" icon :to="`/session/${item.id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col class="py-0 px-0" v-if="cdrs.length" >
                <v-data-table
                    :items="cdrs"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Session Id.', key: 'charging_session_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Total Cost', key: 'total_cost.incl_vat' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" size="small" icon :to="`/session/${item.charging_session_id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col v-if="no_data && search_str" class="accent white--text no-data ">
                <span>No data found</span>
            </v-col>
        </v-row>

    </v-card>
</template>
<script>
import _ from "lodash"

export default {
    data() {
        return {
            show: false,
            loading: false,
            no_data: false,
            search_str: null,
            search_model: 'Sessions',
            search_model_options: ['Sessions','Cdrs'],
            sessions: [],
            cdrs: []
        };
    },
    watch: {
        search_str: function (newValue) {
            if (!newValue) {
                this.sessions = []
                this.cdrs = []
                this.no_data = false
                return
            }
        },
        search_model: function (newValue, oldValue) {
            if (typeof newValue === 'undefined') this.search_model = oldValue

            if (!this.search_str) {
                return
            }
            this.search()
        },
        show: function (newValue) {
            if (!newValue) {
                this.sessions = []
                this.cdrs = []
                this.no_data = false
            }
        }
    },
    inject: ['$api'],
    methods: {
        async search() {

            if(!this.search_str) {
                return
            }
            
            this.no_data = false
            this.loading = true

            const data = await this.$api
                .get(`tools/search/${this.search_str}/${this.search_model}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            this.loading = false

            if (data) {
                this.sessions = data.sessions || []
                this.cdrs = data.cdrs || []
                this.no_data = false
            } 
            
            if (!this.sessions.length && !this.cdrs.length) {
                this.no_data = true
            }
        },
        onClickOutside(event) {
            if(event.srcElement.className.includes("non-v-click-outside")) return

            this.sessions = []
            this.cdrs = []
            this.no_data = false
            this.show = false
        },
        onClick() {
            if (this.no_data) {
                this.search()
            }
        },
    },
};
</script>

<style scoped>

    :deep(.v-btn){
        height: 36px !important;
        top: -5px;
        width: 36px !important;
    }

    .v-btn-groove {
        border-left: 1px solid #000000;
    }

    .no-data {
        text-align: center;
        width: 300px;
    }

    .v-toolbar__content,
    .v-card {
        overflow: visible !important;
    }
    
</style>