import { createApp } from 'vue'
import mixins from './plugins/mixins'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import api from './axios.instance'
import 'regenerator-runtime/runtime'
import Ws from '@adonisjs/websocket-client'
import CustomWsPlugin from './plugins/CustomWsPlugin'

import JsonViewer from 'vue-json-viewer'
// https://www.npmjs.com/package/vue-clipboard2
import VueClipboard from 'vue-clipboard2'

// https://www.highcharts.com/blog/tutorials/highcharts-vue-wrapper/
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'

import "vuetify/styles"
import './assets/css/global-styles.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(vuetify)
app.mixin(mixins)

// Proporcionar $api a toda la aplicación
app.provide('$api', api)

app.use(CustomWsPlugin, { adonisWS: Ws })

app.use(JsonViewer)
app.use(VueClipboard)

Highcharts.setOptions({
    credits: false,
    title: false,
    chart: {
        backgroundColor: 'transparent'
    },
    global: {
        useUTC: false
    }
})
app.use(HighchartsVue)


async function checkUser() {
    //Si ya tenemos token, iniciamos sesión y hacemos llamada para obtener los datos del usuario
    if (localStorage.getItem('token')) {
        api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    
        let user = await api.get('/user/info')
            .then(resp => resp.data )
            .catch(console.error)

        if( user ){
            store.commit('auth_success', { token: localStorage.getItem('token'), user })
        } else {
            console.log("Can not fetch GET /user/info")
        }
    }
}

checkUser()


app.mount('#app')