<template>
    <v-container v-if="provider">
        
        <AppBar
            :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: name, icon: 'mdi-earth', to: `/provider/${provider_id}` },
            { text: 'Commands' }
            ]"
        />

        <!-- Provider Commands -->
        <div class="d-flex align-center">
            <v-icon start color="accent">mdi-console-line</v-icon>
            <h2>Commands</h2>
        </div>
        <ProviderCommands :providerId="provider.id" :role="this.$route.params.role" ></ProviderCommands>            

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar";
import ProviderCommands from "@/components/provider/ProviderCommands";

export default {
    name: "provider-commands",
    components: {
        AppBar,
        ProviderCommands
    },
    inject: ["$api"],
    data() {
        return {
            url: null,

            providerLoading: true,
            providerIcon: null,

            provider: null,
            user: null,
            credentials: null,
            tokens: null,
            endpoints: null,
            panel: [0, 1],

            tablePagination: {
                itemsPerPageOptions: [10, 20, 30, 50, -1],
                serverItemsLength: 0,
            },
            tableOptions: {
                itemsPerPage: 10,
                page: 1,
            }

        }
    },
    computed: {
        name() {
            return this.provider
                ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
                : "-"
        },
        provider_id(){
            return this.provider ? this.provider.id : 0
        },
    },
    mounted() {
        this.url = this.$route.params.role.toUpperCase()
        this.loadData()
    },
    methods: {
        async loadData() {
            let id = this.$route.params.id
            if (!id) {
                return
            }
    
            this.providerLoading = true
            let provider = await this.$api
                .get(`/provider/${id}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            if (!provider) {
                this.showError(`Provider #${id} not found`)
                return
            }
            this.provider = provider
            if(!this.hasRole('EMSP') && !this.hasRole('CPO')){  
                this.$router.push(`/provider/${this.provider.id}`)
            };
            this.providerLoading = false
        },
        hasRole(role){
            return this.provider && this.provider.role && this.provider.role.includes(role) && (this.url == role)
        },
    }
}
</script>