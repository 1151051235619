<template>
    <v-container fluid>

        <AppBar :breadcrumbs="[
            { text: 'Commands', to: '/commands'},
            { text: commandName, icon: 'mdi-console-line' }
        ]"/>

        <v-card :loading="loading" variant="outlined">

            <!-- Command Start -->
            <v-row v-if="command">
                <v-col cols="12" md="4">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>START_SESSION</v-list-item-title>
                            <v-list-item-subtitle>Command</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{adonisDateFullFormat(command.created_at)}}</v-list-item-title>
                            <v-list-item-subtitle>Created at</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{adonisDateFullFormat(command.updated_at)}}</v-list-item-title>
                            <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{command.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>

                <v-col cols="12" md="2">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{command.dtm_session_id}}</v-list-item-title>
                            <v-list-item-subtitle>DTM Transaction Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2" v-if="this.command.session">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>
                                <v-btn :to="`/session/${this.command.session.id}`" variant="text">
                                    <v-icon start>mdi-none</v-icon>
                                    {{ command.session.id }}
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>Session Id</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="6">
                    <v-list-item lines="two">
                        <v-list-item-title>{{ command.authorization_reference || "-"}} </v-list-item-title>
                        <v-list-item-subtitle>Authorization Ref.</v-list-item-subtitle>
                    </v-list-item>
                </v-col>
                <v-col cols="12">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{command.response_url}}</v-list-item-title>
                            <v-list-item-subtitle>Response URL</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                
            </v-row>

            <!-- Provider -->
            <v-divider class="mb-2" />
            <h3 class="ml-4">By Provider</h3>
            <v-row v-if="provider">
                <v-col cols="12" md="6">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>
                                <v-btn :to="`/provider/${this.provider.id}`" variant="text">
                                    <v-icon start>mdi-earth</v-icon>
                                    {{ provider.user.company.name }}
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>Company</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                
                <v-col cols="12" md="4">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{ `${provider.role} (${provider.country_code}-${provider.party_id})` }}</v-list-item-title>
                            <v-list-item-subtitle>Roles</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{provider.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- Location -->
            <v-divider class="mb-2" />
            <h3 class="ml-4">Target Location</h3>
            <v-row v-if="location">
                <v-col cols="12" md="6">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>
                                <LocationName
                                    :location="{ id: this.location.id, name: location.name }"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                
                <v-col cols="12" md="4">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>
                                <ProviderName
                                    :provider="{ id: location.provider.id, country_code: location.provider.country_code, party_id: location.provider.party_id }"
                                    type="title"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Owner</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item lines="two">
                        
                            <v-list-item-title>{{location.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- Evse -->
            <h3 class="ml-4 mt-1" v-if="command">EVSE</h3>
            <v-row v-if="command">
                <v-col cols="12" md="4">
                    <v-list-item lines="two"> 
                        <v-list-item-title>{{ command.evse_id }}</v-list-item-title>
                        <v-list-item-subtitle>Evse Id.</v-list-item-subtitle>
                    </v-list-item>
                </v-col>

                <v-col cols="12" md="5">
                    <v-list-item lines="two"> 
                        <v-list-item-title>{{ command.evse_uid }}</v-list-item-title>
                        <v-list-item-subtitle>Evse UID</v-list-item-subtitle>
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- Connector -->
            <h3 class="ml-4 mt-1" v-if="command">Connector</h3>
            <v-row v-if="command">
                <v-col cols="12" md="4">
                    <v-list-item lines="two"> 
                        <v-list-item-title>{{ command.connector.id }}</v-list-item-title>
                        <v-list-item-subtitle>Connector Id.</v-list-item-subtitle>
                    </v-list-item>
                </v-col>

                <v-col cols="12" md="5">
                    <v-list-item lines="two"> 
                        <v-list-item-title>{{ command.connector.ocpi_id }}</v-list-item-title>
                        <v-list-item-subtitle>Connector OCPI Id.</v-list-item-subtitle>
                    </v-list-item>
                </v-col>

                <v-col cols="12" md="3">
                    <v-list-item lines="two"> 
                        <v-list-item-title>{{ command.connector.backend_id }}</v-list-item-title>
                        <v-list-item-subtitle>Connector Core Id.</v-list-item-subtitle>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>

        <h2 class="mt-4 mb-1 ml-2">Data</h2>
        <v-expansion-panels focusable multiple v-if="command">
            <!-- Request -->
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <div class="title">
                        Request: {{ command.req_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-divider />
                    <p class="px-4 pt-4 pb-4">JSON Received: {{ command.req_http_received }}</p>
                </v-expansion-panel-text>
            </v-expansion-panel>

            <!-- Response -->
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <div class="title">
                        Response: {{ command.resp_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-divider />
                    <p class="px-4 pt-4">Result: {{ command.resp_result }}</p>
                    <p class="px-4">JSON Received: {{ command.resp_http_received }}</p>
                    <p class="px-4 pb-4">JSON Sent: {{ command.resp_message_sent }}</p>
                </v-expansion-panel-text>
            </v-expansion-panel>

            <!-- Result -->
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <div class="title">
                        Result: {{ command.res_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <v-divider />
                    <p class="px-4 pt-4">Result: {{ command.res_result }}</p>
                    <p class="px-4">JSON Received: {{ command.res_http_received }}</p>
                    <p class="px-4 pb-4">JSON Sent: {{ command.res_message_sent }}</p>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"

export default {
    name: "providers",
    components: {
        AppBar,
        ProviderName,
        LocationName
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            command: null,
            provider: null,
            location: null
        }
    },
    computed: {
        commandName: function(){
            return this.command ? `Start Session ${this.command.id}` : "-"
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            const id = this.$route.params.id
            if( !id ){ this.showError('START_SESSION not found'); return; }

            this.loading = true
            this.$api.get(`command/START_SESSION/${id}`)
                .then(resp => {
                    this.command = resp.data
                    this.provider = resp.data.provider
                    this.location = resp.data.location

                })
                .catch(e => { this.showError(e) })
                .then(_ => { this.loading = false })
            
        }
    }
};
</script>
