<template>
    <v-container fluid>
        <AppBar title="Location Groups" icon="mdi-folder-marker" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />

        </v-row>

        <!-- Data table -->
        <v-data-table
            v-if="groups"
            :headers="[
                { key: 'linkto', sortable: false, cellClass: 'td-linkto', width: '5px' },
                { title: 'Id.', key: 'id' },
                { title: 'Name', key: 'name' },
                { title: 'Reference', key: 'reference' },
                { title: 'Private', key: 'is_private' },
                { title: 'Backend Id', key: 'backend_id' },
                { title: 'Updated at', key: 'updated_at' },
            ]"
            :items="groups"
            :loading="tableLoading"
            :search="tableSearch"
            disable-sort
            disable-pagination
            hide-default-footer
            :items-per-page="-1"
            density="compact"
            class="accent-table rounded-table"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn variant="plain" density="compact" size="small" slim :to="`/location-group/${item.id}`">
                    <v-icon start >mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.is_private="{ item }">
                <v-icon v-if="item.is_private">mdi-check</v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import _ from "lodash"

export default {
    components: {
        AppBar,
        SearchTextField,
        SearchButton,
    },
    inject: ["$api"],
    data() {
        return {
            selectedSearch: null,
            tableLoading: false,
            groups: [],
            tableSearch: null,
        };
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },          
    },
    methods: {
        load() {
            this.tableLoading = true;
            this.$api
                .get("location-groups")
                .then((resp) => {
                    this.groups = resp.data;
                })
                .catch(this.showError)
                .then((_) => {
                    this.tableLoading = false;
                });
        },
        onFilter(){
            if(this.tableLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)

            this.load()
        },
        sumEvsesFromChargePoints(chargepoints) {
            if (!chargepoints || !chargepoints.length) {
                return 0;
            }
            const totalEvses = chargepoints.map((cp) => cp.evses.length);
            if (!totalEvses) {
                return 0;
            }
            return totalEvses.reduce((a, b) => a + b, 0);
        },
    },
};
</script>
