/**
 * EventBus has been removed from Vue 3 completely.
 * https://v3-migration.vuejs.org/breaking-changes/events-api
 * 
 * This is a simple implementation of an event bus using Vue 3's Composition API, to avoid new libraries.
 * https://stackoverflow.com/a/77577193
 */

import { ref, watch } from 'vue'

const bus = ref(new Map())

export function EventBus() {
  const emit = (event, props) => {
    const currentValue = bus.value.get(event)
    const counter = currentValue ? ++currentValue[1] : 1
    bus.value.set(event, [props, counter])
  }

  const on = (event, callback) => {
    watch(() => bus.value.get(event), (val) => {
      callback(val[0])
    })
  }

  const off = (event) => {
    bus.value.delete(event)
  }

  return {
    emit,
    on,
    off,
    bus,
  }
}