<template>
    <v-container fluid>

        <AppBar title="Searcher" icon="mdi-magnify" />

        <v-chip class="mb-3" label>
            <v-icon start size="large">mdi-information-outline</v-icon>
            OCPI Id. Search for locations, evses, connectors, sessions, cdrs and tariffs.
        </v-chip>

        <v-row density="compact" align="center">  
            <v-col md="5" lg="4" xl="3" class="pr-0">
                <v-text-field
                    class="accent"
                    v-model="search_str"
                    placeholder="ID / OCPI ID Search"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    density="compact"
                    variant="outlined"
                    hide-details
                    autofocus
                    @keyup.enter="search"
                ></v-text-field>
            </v-col>
            <v-col md="5" lg="4" xl="3">
                <v-select
                    v-model="selected_models"
                    :items="search_model_options"
                    multiple
                    class="accent"
                    density="compact"
                    variant="outlined"
                    item-color="accent"
                    hide-details
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < maximum_chips"
                            color="accent"
                            @click:close="() => selected_models.splice(selected_models.indexOf(item), 1)"
                            size="small"
                            variant="outlined"
                        >
                            {{ item.title }}
                        </v-chip>
                        <v-chip v-else-if="index == maximum_chips" color="accent" size="small" variant="outlined">
                            ...{{ selected_models.length - maximum_chips }} more
                        </v-chip>
                    </template>
                </v-select>
            </v-col>
            <v-btn class="ml-1" color="accent" height="40" @click="search" :loading="loading">
                Search
            </v-btn>
        </v-row>

        <v-row>
            <v-col cols="12" class="py-0" v-if="locations.length" >
                <v-data-table
                    :items="locations"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Provider', key: 'provider' },
                        { title: 'Name', key: 'name' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Last updated', key: 'last_updated' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-map-marker</v-icon><h3 class="white--text">Locations</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim :to="`/location/${item.id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.provider="{ item }">
                        {{ item.country_code }}-{{ item.party_id }}
                    </template>
                    <template v-slot:item.last_updated="{ item }">
                        {{ dateFullFormat(item.last_updated) }}
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="12" class="py-0" v-if="evses.length">
                <v-data-table
                    :items="evses"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Status', key: 'status' },
                        { title: 'UId.', key: 'uid' },
                        { title: 'Evse Id.', key: 'evse_id' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-ev-station</v-icon><h3 class="white--text">Evses</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim
                            :to="`/chargepoint/${item.chargepoint_id}`"
                        >
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
            
            <v-col cols="12" class="py-0" v-if="connectors.length">
                <v-data-table
                    :items="connectors"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Status', key: 'status' },
                        { title: 'Evse Id.', key: 'evse_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table py-0"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-power-plug</v-icon><h3 class="white--text">Connectors</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim
                            :to="`/chargepoint/${item.evse.chargepoint_id}`"
                        >
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="12" class="py-0" v-if="sessions.length" >
                <v-data-table
                    :items="sessions"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Sender Id.', key: 'sender_provider_id' },
                        { title: 'Receiver Id.', key: 'receiver_provider_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Created at', key: 'created_at' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-sine-wave</v-icon><h3 class="white--text">Sessions</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim :to="`/session/${item.id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="12" class="py-0" v-if="cdrs.length" >
                <v-data-table
                    :items="cdrs"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Session Id.', key: 'charging_session_id' },
                        { title: 'Location Id.', key: 'location_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Total Cost', key: 'total_cost.incl_vat' },
                        { title: 'Created at', key: 'created_at' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-credit-card-outline</v-icon><h3>Cdrs</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim :to="`/session/${item.charging_session_id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="12" class="py-0" v-if="tariffs.length" >
                <v-data-table
                    :items="tariffs"
                    :headers="[
                        { key: 'click', sortable: false, cellClass: 'td-linkto' },
                        { title: 'Id.', key: 'id' },
                        { title: 'Provider Id.', key: 'provider_id' },
                        { title: 'Currency', key: 'currency' },
                        { title: 'Created at', key: 'created_at' },
                        { title: 'Updated at', key: 'updated_at' },
                        { title: 'Deleted at', key: 'deleted_at' },
                    ]"
                    disable-sort
                    hide-default-footer
                    density="compact"
                    class="accent-table"
                >
                    <template v-slot:top="{ page, pageCount, items, updateOptions }">
                        <v-toolbar density="compact" flat color="accent">
                            <v-icon class="ml-5" start size="small" color="white">mdi-tag-text-outline</v-icon><h3 class="white--text">Tariffs</h3>
                            
                            <v-spacer/>
                            
                            <!-- Footer -->
                            <span class="mx-8 text-caption table-footer white--text">
                                {{items.length}} total items - Page {{ page }} of {{ pageCount }} 
                            </span>
                            <v-btn
                                icon
                                color="white"
                                class="mr-1"
                                :disabled="page == 1"
                                @click="(options) => updateOptions({...options, page: page - 1 })"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                color="white"
                                class="ml-1"
                                :disabled="page == pageCount"
                                @click="(options) => updateOptions({...options, page: page + 1 })"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.click="{ item }">
                        <v-btn variant="plain" density="compact" size="small" slim :to="`/tariffs/${item.id}`">
                            <v-icon>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col class="text-center" v-if="no_data">
                <span>No data found</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    components: {
        AppBar
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            no_data: false,
            search_str: null,
            maximum_chips: 3,
            selected_models: [ 'Sessions' ],
            search_model_options:  [
                'Locations',
                'Evses',
                'Connectors',
                'Sessions',
                'Cdrs',
                'Tariffs'
            ],
            locations: [],
            evses: [],
            connectors: [],
            sessions: [],
            cdrs: [],
            tariffs: []
        };
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize)
    },
    watch: {
        search_str: function (newValue) {
            if (!newValue) {
                this.locations = []
                this.evses = []
                this.connectors = []
                this.sessions = []
                this.cdrs = []
                this.tariffs = []
                this.no_data = false
                return
            }
        }
    },
    methods: {
        async search() {

            if(!this.search_str || !this.selected_models.length) {
                return this.showError("All fields must be filled out")
            }
            
            this.no_data = false
            this.loading = true
            const search_model = this.selected_models.join('&')

            const data = await this.$api
                .get(`tools/search/${this.search_str}/${search_model}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            this.loading = false

            if (data) {
                this.locations = data.locations || []
                this.evses = data.evses || []
                this.connectors = data.connectors || []
                this.sessions = data.sessions || []
                this.cdrs = data.cdrs || []
                this.tariffs = data.tariffs || []
                this.no_data = false
            } 
            
            if (!this.locations.length && !this.evses.length && !this.connectors.length 
                && !this.sessions.length && !this.cdrs.length && !this.tariffs.length) {
                this.no_data = true
            }
        },
        onResize(){
            const device_width = window.innerWidth
            if (device_width > 1735 && device_width < 1904 || device_width > 2090) {
                this.maximum_chips = 3
            } else if (device_width > 1400) {
                this.maximum_chips = 2
            } else {
                this.maximum_chips = 1
            }
        }
    },
}
</script>
