<template>
  <div>
    <v-row class="filer-row">
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-menu
          ref="menu"
          class="filter-field"
          v-model="showDateFromPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          hide-details
        >
            <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  class="filter-field accent"
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  variant="outlined"
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFromPicker"
                @update:modelValue="updateFromPickerSelected"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDateToPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details
        >
            <template v-slot:activator="{ props }">
                <v-text-field
                  v-bind="props"
                  class="filter-field accent"
                  v-model="selectedDateTo"
                  label="Date to"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  variant="outlined"
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateToPicker"
                @update:modelValue="updateToPickerSelected"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-btn 
        :loading="tableLoading"
        @click="onFilter"
        color="accent"
        variant="text"
      >
        SEARCH
      </v-btn>
    </v-row>

    <v-divider />

    <v-data-table-server
        :headers="[
            { key: 'action', sortable:false, cellClass: 'td-linkto', width: '5px' },
            { title: 'Id.', key: 'id' },
            { title: 'Start Date', key: 'start_date_time' },
            { title: 'End Date', key: 'end_date_time' },
            { title: 'CPO / EMSP', key: 'sender_receiver' },
            { title: 'UID', key: 'ocpi_id' },
            { title: 'Location', key: 'location', width:'200px' },
            { title: 'Charge point', key: 'chargepoint', width:'200px' },
            { title: 'Evse', key: 'evse.uid' },
            { title: 'Energy (kWh)', key: 'kwh' },
            { title: 'Id. DTM', key: 'dtm_session_id' },
            { title: 'Last updated', key: 'last_updated' },
        ]"
        :items="sessions"
        disable-sort
        class="accent-table rounded-table"
        :items-per-page="tableOptions.itemsPerPage"
        :items-length="pagination.serverItemsLength"
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        @update:options="this.updateTableOptions" 
        density="compact" 
    >
        <template v-slot:item.action="{ item }">
            <v-btn 
                variant="plain"
                size="small"
                :to="`/session/${item.id}`" 
                >
                <v-icon>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.sender_receiver="{ item }">
            <div class="d-flex align-center">
                <!-- Sender -->
                <v-chip v-if="item.sender_id === 1" size="small" class="bg-secondary-chip text-capitalize" variant="flat">{{ item.sender_name }}</v-chip>
                <strong v-else>
                <ProviderName
                    :provider="item.senderProvider"
                    role="cpo"
                />
                </strong>
                <!-- Arrow -->
                <v-icon color="secondary">mdi-arrow-right</v-icon>
                <!-- Receiver -->
                <v-chip v-if="item.receiver_id === 1" size="small" class="bg-accent text-capitalize" variant="flat">{{ item.receiver_name }}</v-chip>
                <strong v-else>
                <ProviderName
                    :provider="item.receiverProvider"
                    role="emsp"
                />
                </strong>
            </div>
        </template>
        <template v-slot:item.location="{ item }">
            <LocationName
                :location="{ id: item.location_id, name: item.location_name }"
                type="table"
            />
        </template>
        <template v-slot:item.chargepoint="{ item }">
            <ChargepointName
                :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_name }"
                type="table"
            />
        </template>
        <template v-slot:item.start_date_time="{ item }">
            {{ dateFullFormat(item.start_date_time) }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
            {{ dateFullFormat(item.end_date_time) }}
        </template>
        <template v-slot:item.last_updated="{ item }">
            {{ dateFullFormat(item.last_updated) }}
        </template>
    </v-data-table-server>

    <ImportSessionsFromProvider :provider-id="providerId" v-if="hasRole('CPO')" @data-imported="loadSessions()" class="mt-2"></ImportSessionsFromProvider>
  </div>
</template>
<script>
import ImportSessionsFromProvider from "@/components/provider/ImportSessionsFromProvider";
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
  name: "provider-sessions",
  props: ["providerId","providerRole"],
  components: {
    ImportSessionsFromProvider,
    ProviderName,
    LocationName,
    ChargepointName
  },
  inject: ['$api'],
  data() {
    return {
      sessions: [],
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,
      pagination: {
        itemsPerPageOptions: [25, 50, 100, -1],
        serverItemsLength: 0
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1
      },
      initialLoad: true,
      tableLoading: false,
      selectedDateFromPicker: null,
      selectedDateToPicker: null,
    }
  },
  watch: {
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
            this.onFilter()
        },
        immediate: true
    },
  },
  methods: {
    async loadSessions() {
      let queryParams = `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
      if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }

      await this.$api.get(`provider/${this.providerId}/sessions${this.hasRole('CPO') ? `?role=CPO`:'?role=EMSP'}&${queryParams}`)
        .then( resp => {
            if( Array.isArray(resp.data) ){
                this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                this.sessions = resp.data.map(session => {
                return {
                    id: session.id,
                    start_date_time: session.start_date_time,
                    end_date_time: session.end_date_time,
                    sender_id: session.sender_provider_id,
                    sender_name: session.senderProvider?.user?.username,
                    receiver_id: session.receiver_provider_id,
                    receiver_name: session.receiverProvider?.user?.username,
                    ocpi_id: session.ocpi_id,
                    location_id: session.location.id,
                    location_name: session.location.name,
                    chargepoint_id: session.evse.chargepoint.id,
                    chargepoint_name: session.evse.chargepoint.reference_name,
                    evse_uid: session.evse.uid,
                    kwh: session.kwh,
                    dtm_session_id: session.dtm_session_id,
                    last_updated: session.last_updated,
                    receiverProvider: session.receiverProvider,
                    senderProvider: session.senderProvider
                    }
                })
            }     
        })
        .catch(this.showError)
        .finally(() => {
            this.tableLoading = false
            this.initialLoad = false
        })
    },
    hasRole(role){
            return this.providerRole && this.providerRole.includes(role)
    },
    updateTableOptions(args){
        this.tableOptions = args
        if(!this.initialLoad){
            this.loadSessions()
        }
    },
    async onFilter(){
        if(this.tableLoading) return
        this.urlPush('dateFrom', this.selectedDateFrom)
        this.urlPush('dateTo', this.selectedDateTo)
        await this.loadSessions()

        if (this.selectedDateFrom) {
            this.sessions = this.sessions.filter(session => this.dateFormat(session.start_date_time) >= this.dateFormat(this.selectedDateFrom))
        }

        if (this.selectedDateTo) {
            this.sessions = this.sessions.filter(session => this.dateFormat(session.start_date_time) <= this.dateFormat(this.selectedDateTo))
        }
    },
    updateFromPickerSelected() {
        this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
        this.showDateFromPicker = false
    },

    updateToPickerSelected() {
        this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
        this.showDateToPicker = false
    },
  },
};
</script>