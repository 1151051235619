<template>
    <v-container fluid>

        <AppBar title="Allowed real-time authorizations" icon="mdi-key-wireless" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>     

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />
        </v-row>

        <!-- Data table -->
        <v-data-table
            class="accent-table rounded-table"
            :items="allowedRTAs"
            :headers="[
                { title: 'Id.', key: 'id' },
                { title: 'Provider', key: 'provider' },
                { title: 'Location id.', key: 'location' },
                { title: 'Chargepoint id.', key: 'chargepoint' },
                { title: 'RFID', key: 'rfid' },
                { title: 'Created at', key: 'created_at' },
            ]"
            density="compact"
            :loading="loading"
            :search="tableSearch"
            :custom-filter="dataTableDeepSearch"
            :sort-by="[{ key: 'created_at', order: 'desc' }]"
        >
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                    role="emsp"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    :location="{ id: item.location_id, name: item.location_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_reference }"
                    type="table"
                />
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ dateFullFormat(item.created_at) }}
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    name: "real-time-authorizations",
    components: {
        AppBar,
        SearchTextField,
        SearchButton,
        ProviderSelectorTemplate,
        ProviderName,
        LocationName,
        ChargepointName
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            allowedRTAs: [],
            providers: [],
            selectedSearch: null,
            tableSearch: null,
            selectedProvider: null,
        };
    },
    mounted() {
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load(){
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        return { 
                            ...p, 
                            title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)
        },
        async loadRTA(){
            this.loading = true
            await this.$api.get(`/allowed-real-time-authorizations?limit=300`)
                .then((resp) => this.allowedRTAs = resp.data)
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        async onFilter(){
            if(this.loading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)
            this.urlPush('provider', this.selectedProvider)

            await this.loadRTA()

            if(this.selectedProvider){
                this.allowedRTAs = this.allowedRTAs.filter(allowedRTA => allowedRTA.provider_id == this.selectedProvider)
            }
        }
    }
};
</script>