<template>
    <v-container fluid>

        <AppBar :breadcrumbs="[
            { text: 'HTTP Internal Logs' }
        ]"/>

        <!-- Send CDR to Provider -->
        <v-row>
            <v-col cols="12" sm="6" md="4" >
                <v-card 
                    variant="outlined" 
                    @click="onInternalServiceChange('core')"
                    :class="selected === 'core' ? 'selected-card' : ''"
                    
                >
                    <v-card-title class="d-flex justify-center">
                        CORE
                    </v-card-title>

                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-card 
                    variant="outlined" 
                    @click="onInternalServiceChange('dtm')"
                    :class="selected === 'dtm' ? 'selected-card' : ''"
                >
                    <v-card-title class="d-flex justify-center">
                        DTM
                    </v-card-title>

                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <v-card 
                    variant="outlined" 
                    @click="onInternalServiceChange('cpo')"
                    :class="selected === 'cpo' ? 'selected-card' : ''"
                >
                    <v-card-title class="d-flex justify-center">
                        CPO
                    </v-card-title>

                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer />
            
            <v-btn 
                v-if="httpInternalLogsChannelConnected"
                class="mr-3"
                color="accent"
                variant="outlined" 
                @click="closeWSChannel"
            >
                <v-icon left>mdi-pause</v-icon> Pause
            </v-btn>

            <v-btn 
                v-else
                class="mr-3"
                color="accent"
                variant="outlined" 
                @click="onInternalServiceChange(selected)"
            >
                <v-icon start>mdi-play</v-icon> Resume
            </v-btn>
            
        </v-row>

        <!-- Requests -->
        <v-row>
            <!-- OUT -->
            <v-col>
                <v-card variant="outlined">
                    <v-toolbar dark color="accent" elevation="0">
                        <v-app-bar-nav-icon><v-icon>mdi-phone-outgoing</v-icon></v-app-bar-nav-icon>
                        <v-toolbar-title>Out</v-toolbar-title>
                    </v-toolbar>
                    <v-list density="compact" class="pa-0 text-subtitle-2">
                        <v-list-group
                            v-for="item in requestsOut"
                            no-action
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" active-class="bg-grey-lighten-2">
                                    <span>{{ dateTimeFormat(item.timestamp) }}</span>
                                    <span class="pl-4">{{ `${item.request.method} ${truncateUrl(item.request.url, 32)}` }}</span>
                                </v-list-item>
                            </template>

                            <div>

                                <div class="pa-2 text-center">
                                    <v-chip size="small">UTC Date: {{ item.timestamp }}</v-chip>
                                    <div>{{ item.request.full_url }}</div>
                                </div>

                                <v-divider />

                                <div class="pa-2">
                                    <h4>Request</h4>
                                    <div class="d-flex flex-column">
                                        <span class="text-overline">Body: </span>
                                        <json-viewer v-if="item.request.body" :value="item.request.body" :expand-depth="1" copyable />
                                        <span v-else class="pl-1 json-text-orange">null</span>
                                    </div>
                                    <div>
                                        <span class="text-overline">Headers: </span>
                                        <json-viewer v-if="item.request.headers" :value="item.request.headers" :expand-depth="0" copyable />
                                    </div>
                                </div>

                                <v-divider class="mt-2 mx-0" />

                                <div class="pa-2">
                                    <v-row class="mt-2 mx-0">
                                        <h4 class="mr-auto">Response {{ `${item.response.http_status}: ${item.response.status_message}` }}</h4>
                                        <p class="mr-3"><span class="text-body-2">Time: </span><span class="text-body-2 green--text text--lighten-1">{{ item.response.duration }} s</span></p>
                                    </v-row>
                                    <p>
                                        <span class="text-overline">Content: </span>
                                        <json-viewer v-if="item.response.content" :value="item.response.content" :expand-depth="1" copyable />
                                    </p>
                                    <p>
                                        <span class="text-overline">Headers: </span>
                                        <json-viewer v-if="item.response.headers" :value="item.response.headers" :expand-depth="0" copyable />
                                    </p>
                                </div>

                                <v-divider />

                            </div>

                        </v-list-group>
                    </v-list>
                </v-card>
            </v-col>

            <!-- In -->
            <v-col>

                <v-card variant="outlined">
                    <v-toolbar dark dense color="accent" elevation="0">
                        <v-app-bar-nav-icon><v-icon>mdi-phone-incoming</v-icon></v-app-bar-nav-icon>
                        <v-toolbar-title>In</v-toolbar-title>
                    </v-toolbar>

                    <v-list density="compact" class="pa-0 text-subtitle-2">
                        <v-list-group
                            v-for="item in requestsIn"
                            no-action
                        >
                            <template v-slot:activator="{ props }">
                                <v-list-item v-bind="props" active-class="bg-grey-lighten-2">
                                    <span>{{ dateTimeFormat(item.timestamp) }}</span>
                                    <span class="pl-4">{{ `${item.request.method} ${truncateUrl(item.request.url, 32)}` }}</span>
                                </v-list-item>
                            </template>

                            <div class="pa-2">

                                <div class="text-center">
                                    <v-chip size="small">UTC Date: {{ item.timestamp }}</v-chip>
                                    <div>{{ item.request.method }} {{ item.request.full_url }}</div>
                                </div>
                                <v-divider class="my-2" />

                                <h4>Request</h4>
                                <div>Body: <json-viewer v-if="item.request.body" :value="item.request.body" :expand-depth="1" copyable /></div>
                                <div>Headers: <json-viewer v-if="item.request.headers" :value="item.request.headers" :expand-depth="0" copyable /></div>
                                <v-divider class="my-2" />

                                <h4>Response {{ `${item.response.http_status}: ${item.response.status_message}` }}</h4>
                                <p>Content: <json-viewer v-if="item.response.content" :value="item.response.content" :expand-depth="1" copyable /></p>
                                <p>Headers: <json-viewer v-if="item.response.headers" :value="item.response.headers" :expand-depth="0" copyable /></p>
                                <v-divider class="mt-2" />

                            </div>

                        </v-list-group>
                    </v-list>
                </v-card>

            </v-col>
        </v-row>

        <div class="d-flex justify-center mt-2 mb-2">
            <v-btn v-if="requestsIn.length >= limit || requestsOut.length >= limit" @click="loadMoreEvents" text center>
                <v-icon>mdi-refresh</v-icon>
                More Events
            </v-btn>
        </div>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import Bws from "@/plugins/BackofficeWebsocket.js"

export default {
    name: "http-internal-logs",
    components: {
        AppBar,
    },
    inject: ['$api'],
    data() {
        return {
            requestsIn: [],
            requestsOut: [],
            httpInternalLogsChannelConnected: false,
            selected: null,
            limit: 29,
            offset: 0,
        };
    },
    async mounted() {
        this.onInternalServiceChange('core')
    },
    methods: {
        truncateUrl(string, length){
            if( string.length < length ){
                return string
            } else {
                return '...'+string.slice(-1*length)
            }
        },
        onInternalServiceChange(key){
            this.requestsIn = []
            this.requestsOut = []
            this.limit = 29
            this.offset = 0
            this.getLogs(key)
        },
        async getLogs(key){
            this.selected = key

            const reqin = await this.$api
            .get(`/last-http-internal-logs/${key}/in`, {
                params: {
                    limit: this.limit,
                    offset: this.offset,
                }
            })
            .then(resp => resp.data)
            .catch(this.showError)

            this.requestsIn.push(...reqin)

            const reqout = await this.$api
                .get(`/last-http-internal-logs/${key}/out`, {
                    params: {
                        limit: this.limit,
                        offset: this.offset,
                    }
                })
                .then(resp => resp.data)
                .catch(this.showError)

            this.requestsOut.push(...reqout)

            // Cerramos el canal anterior si existe
            this.closeWSChannel()

            // Nos suscribimos a Redis para nuevos logs
            this.httpInternalLogsChannel = Bws.getSubscription(`http-logs:internal-${key}`)
            this.httpInternalLogsChannelConnected = true
            
            this.httpInternalLogsChannel.on("in", (data) => {
                this.requestsIn.unshift(data)
                this.requestsIn = this.requestsIn.slice(0, 64)
                this.limit++
                this.offset++
            })
            this.httpInternalLogsChannel.on("out", (data) => {
                this.requestsOut.unshift(data)
                this.requestsOut = this.requestsOut.slice(0, 64)
                this.limit++
                this.offset++
            })
        },
        closeWSChannel() {
            if (this.httpInternalLogsChannel) {
                this.httpInternalLogsChannel.close()
                this.httpInternalLogsChannel = null
                this.httpInternalLogsChannelConnected = false
            }
        },
        loadMoreEvents() {
            this.offset += 29
            this.limit += 29
            this.getLogs(this.selected)
        },
    },
    destroyed() {
        this.closeWSChannel()
    },
};
</script>
<style>
    .jv-code {
        padding: 0 !important
    }
    .json-text-orange {
        white-space: nowrap;
        color: #e08331;
        font-size: 14px;
        font-family: Consolas, Menlo, Courier, monospace;
    }
    .selected-card {
        border: 1px solid black !important
    }
</style>