<template>
    <v-container fluid>

        <AppBar title="Alerts" icon="mdi-bell-outline" />

        <!-- Data table -->
        <v-data-table
            class="accent-table rounded-table alerts-table"
            :headers="[
                { title: '', key: 'data-table-group' },
                { title: 'Content', key: 'content' },
                { title: 'Provider', key: 'provider_id' },
                { title: 'Created at', key: 'created_at' },
                { key: 'actions', width: 56 }
            ]"
            :items="alerts"
            :loading="loading"
            :group-by="[{ key: 'key' }]"
            :sort-by="[{ key: 'created_at', order: 'desc' }]"
            density="compact"  
        >
            <template v-slot:group-header="{ columns, item, toggleGroup, isGroupOpen }">
                <tr>
                    <td :colspan="columns.length">
                        <v-row class="d-flex align-center">
                            <v-btn  
                                @click="toggleGroup(item)" 
                                :icon="isGroupOpen(item) ? 'mdi-minus' : 'mdi-plus'" 
                                variant="text"
                                size="x-small" 
                            />
                            {{ item.items[0]?.raw?.message }}
                            <v-badge :content="item.items.length" color="red" inline class="ml-2" />
                            <v-spacer/>
                            <v-btn
                                class="mr-4"
                                variant="text"
                                size="small" 
                                color="red"
                                @click="deleteAlertsGroup(item.value)"
                            >
                                Delete group
                            </v-btn>
                        </v-row>
                    </td>
                </tr>
            </template>
            <template v-slot:item.provider_id="{ item }">
                <ProviderName v-if="item.provider_id"
                    :provider="providers.find(p => p.id == item.provider_id)"
                />
            </template>
            <template v-slot:item.content="{ item }">
                <json-viewer v-if="item.content"
                    class="pa-2 text-left"
                    :value="item.content"
                    :expand-depth="1"
                    >
                </json-viewer>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ adonisDateFullFormat(item.created_at) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn 
                    class="mr-4"
                    icon="mdi-delete" 
                    variant="text"
                    color="red" 
                    size="small" 
                    @click="deleteAlert(item)"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import Bws from "@/plugins/BackofficeWebsocket.js"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "evse",
    components: { 
        AppBar, 
        ProviderName 
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            alerts: [],
            providers: []
        }
    },
    mounted() {
        this.load()

        const alertsSubscription = Bws.getSubscription('backoffice:alerts')
        alertsSubscription.on('new_alerts', () => this.load())       

        this.$api.get("/providers")
                .then(res => this.providers = res.data)
                .catch(this.showError)
    },
    methods: {
        async load(){
            this.loading = true

            this.$api.get(`alerts`)
                .then((resp) => this.alerts = resp.data)
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        async deleteAlert(alert){
            this.loading = true

            this.$api.delete(`alerts`, { data: alert })
                .then(() => { this.load(); this.showSuccess('Alert has been removed') })
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        async deleteAlertsGroup(key){
            this.loading = true

            this.$api.delete(`alerts/groups/${key}`,)
                .then(() => { this.load(); this.showSuccess('Alerts group has been removed') })
                .catch(this.showError)
                .then(() => this.loading = false)
        }
    }
};
</script>

<style scoped>
    .jv-container.jv-light {
        background: none;
    }
    >>> .alerts-table thead tr th:first-child,
    >>> .alerts-table tbody .v-data-table__tr td:first-child {
        padding: 0;
        width: 0;
        min-width: 5px;
    }
</style>