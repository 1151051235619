<template>
    <v-btn
        :loading="loading"
        @click="searchFunction"
        color="accent"
        variant="text"
    >
        SEARCH
    </v-btn>
</template>

<script>

export default ({
    props: ["loading", "searchFunction"],
})
</script>
