<template>
    <v-btn color="accent" width="22rem" :href="getDTMUrlByEnv()" >
        DTM Session {{ dtmSessionId }}
        <v-icon small right>mdi-arrow-top-right</v-icon>
    </v-btn>
</template>
  
<script>
    export default {
    name: "dtmSessionButton",
    props: ["dtmSessionId"],
    
    methods: {
      getDTMUrlByEnv() {
        let url = "";
        switch (process.env.VUE_APP_SUB_ENV) {
          case "DEV":
            url = `https://dtm-des.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
          case "PRE":
            url = `https://dtm-pre.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
          case "PRO":
            url = `https://dtm-pro.wenea.site/#/transaction/${this.dtmSessionId}`;
            break;
        }
        return url;
      },
    }
  }
</script>