import store from '@/store'
import { getWsInstance } from './CustomWsPlugin'

export default {

    async connect () {
        return new Promise((resolve, reject) => {
            const ws = getWsInstance()
            const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
            let wsPort = window.location.port === '' ? '' : ':8899'
            if( window.location.port === "4433" ){
                //En producción utilizamos un puerto especial para el SSL
                wsPort = ":4433"
            }
            const wsDomain = `${wsProtocol}//${window.location.hostname}${wsPort}`
    
            ws.disconnect()
            ws.connect(
            {
                wsDomain,
                jwtToken: localStorage.getItem("token")
            },
            {
                path: 'adonis-ws',
                reconnection: true,
                reconnectionAttempts: 300,
                reconnectionDelay: 5000
            }
            )
            
            ws.socket.on("open", () => {
                console.log('WS Connected to Worker')
                store.commit('ws_connected')

                // Worker events
                let alertsSubscription
                try {
                    alertsSubscription = this.getSubscription('backoffice:alerts')
                    if (alertsSubscription) alertsSubscription.on('new_alerts', raw => store.commit('set_alerts', parseInt(raw)))
                } catch (error) {
                    console.error('Error getting subscription:', error)
                }
                resolve()
            })
    
            ws.socket.on("close", () => {
                console.error('WS Disconnected from Worker')
            })
    
            ws.socket.on("error", (err) => {
                console.error("WS Error: ", err)
            }) 
    
        })
    },

    disconnect () {
        const ws = getWsInstance()
        ws.disconnect()
    },

    getSubscription(channel){
        const ws = getWsInstance()
        if (!ws.socket) {
            console.log('WS not connected')
            return null
        }
        let wsChannel = ws.socket.getSubscription(channel)
        if ( !wsChannel ) {
            wsChannel = ws.subscribe(channel)
        }
        return wsChannel

    }
}