<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="bg-accent">
                <v-icon left class="mr-2">mdi-vector-polyline</v-icon><span>Create new connection</span>
            </v-card-title>

            <ConnectionForm :user="{}">
                <template v-slot:actions>
                    <v-btn @click="show = false" variant="text">Close</v-btn>
                </template>
            </ConnectionForm>
        </v-card>
    </v-dialog>
</template>
<script>
import { ref, onMounted } from "vue"
import { EventBus } from "@/event-bus"
import ConnectionForm from "@/components/connection/ConnectionForm"

export default {
    components: {
        ConnectionForm
    },
    setup() {
        const show = ref(false)
        
        onMounted(() => {
            EventBus().on("dialog-create-connection:open", () => show.value = true)
            EventBus().on("dialog-create-connection:close", () => show.value = false)
        })
        return { show }
    }
}
</script>