<template>
    <div>
        <v-row class="mx-0 mb-2">
            <v-col v-for="(item, index) in Object.keys(sessionsPerProvider)" :key="`btn-${item}`"
                cols="6" sm="1" class="clickable outlined" v-ripple
                @click="showProviderSerie(index)">
                <div class="pb-2" :style="`border-bottom: 3px solid ${colorPerProvider.get(item)}`">
                    <strong>{{ item }}</strong>
                </div>
            </v-col>
        </v-row>

        <highcharts :options="chartOptions"></highcharts>

    </div>
</template>

<script>
export default {
    props: {
        sessions: {
            type: Array,
            required: true
        },
        colorPerProvider: {
            type: Map, // [provider_id]: color_hex
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'spline',
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    xDateFormat: '%e %b %Y'
                },
                xAxis: {
                    type: 'datetime',
                    labels: { format: "{value:%e %b %Y}", step: 2 }
                },
                yAxis: {
                    title: {
                        text: undefined
                    }
                },
                plotOptions: {
                    spline: { marker: { enabled: false } },
                    series: { lineWidth: 3 }
                },
                series: []
            },
            colors: this.colors
        }
    },
    created(){
        this.load()
    },
    watch: {
        sessions: function () {
            this.load()
        }
    },
    computed: {
        sessionsPerProvider(){
            const sessions = this.sessions
                .reduce(
                    (sessions, item) => {
                        sessions[item.provider] = sessions[item.provider] || []
                        sessions[item.provider].push(item)
                        return sessions
                    }, Object.create(null)
                )

            // Providers are sorted by number of sessions and with alphabetical value
            return Object.keys(sessions)
                .sort((a, b) => {
                    const countA = sessions[a].reduce((count, s) => count + Number(s.sessions), 0)
                    const countB = sessions[b].reduce((count, s) => count + Number(s.sessions), 0)

                    if (countA == countB) {
                        return a.localeCompare(b)
                    }

                    return countB - countA
                })
                .reduce(
                    (obj, key) => { 
                        obj[key] = sessions[key]
                        return obj;
                    }, Object.create(null))
        }
    },
    methods: {
        load() {
            this.chartOptions.series = []
            for (const [key, value] of Object.entries(this.sessionsPerProvider)) {
                const serie = {
                    name: key,
                    data: this.chartData(value),
                    color: this.colorPerProvider.get(key),
                    visible: true
                }
                this.chartOptions.series.push(serie)
            }
        },
        showProviderSerie(index){
            this.chartOptions.series[index].visible = !this.chartOptions.series[index].visible
        },
        chartData(sessionsPerProvider) {
            const chartData = []
            for (const providerSessions of sessionsPerProvider) {
                chartData.push({
                    y: Number(providerSessions.sessions),
                    x: new Date(providerSessions.period_date).getTime(),
                })
            }

            return chartData
        }
    }
}
</script>