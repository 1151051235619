<template>
  <v-data-table
    v-if="invoices"
    :headers="[
      { key: 'action', sortable: false, cellClass: 'td-linkto', width: 50 },
      { title: 'Day', key: 'start_date_time', width: 110 },
      { title: 'Provider', key: 'provider' },
      { title: 'Charge point', key: 'chargepoint' },
      { title: 'Energy', key: 'kwh', width: 100 },
      { title: 'Tariff', key: 'tariff', width: 100 },
      { title: 'Cost', key: 'total_cost' },
    ]"
    :items="invoices"
    :items-per-page="5"
    :footer-props="{ 'items-per-page-options': [5,10,20,-1] }"
    :sort-by="[{ key: 'start_date_time', order: 'asc' }]"
    density="compact"
    class="accent-table rounded-table"
  >
    <template v-slot:item.action="{ item }">
      <v-btn variant="plain" size="small" :to="`/session/${item.id}`">
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.start_date_time="{ item }">
      {{ dateFormat(item.start_date_time) }}
    </template>
    <template v-slot:item.provider="{ item }">
        <ProviderName
            :provider="item.receiverProvider"
            role="emsp"
        />
    </template>
    <template v-slot:item.chargepoint="{ item }">
        <ChargepointName
            :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_name }"
            type="table"
        />
    </template>
    <template v-slot:item.kwh="{ item }"> {{ item.kwh }} kWh </template>
    <template v-slot:item.tariff="{ item }">
      <v-btn
        v-if="item.cdr && item.cdr.tariff_id"
        variant="text"
        :to="`/tariffs/${item.cdr.tariff_id}`"
      >
        <v-icon left>mdi-tag-text-outline</v-icon> {{ item.cdr.tariff_id }}
      </v-btn>
    </template>
    <template v-slot:item.total_cost="{ item }">
      <strong>
        <v-chip
          v-if="!item.end_date_time"
          class="text-chip text-uppercase px-1"
          size="small"
          label
          variant="outlined"
          color="accent"
        >
          In progress
        </v-chip>
        <v-chip
          v-if="(item.total_cost && item.end_date_time) || item.cdr"
          class="font-weight-bold text-uppercase px-1"
          size="small"
          label
          variant="outlined"
          color="accent"
        >
          {{ item.total_cost }} {{ item.cdr.currency }}
        </v-chip>
        <v-tooltip v-if="!item.total_cost && item.end_date_time && !item.cdr" bottom>
            <template v-slot:activator="{ props }">
                <v-icon v-bind="props"
                    :color="item.kwh <= 0.2 ? 'warning' : 'error'"
                >
                    mdi-close-circle
                </v-icon>
            </template>
            <span v-if="item.kwh <= 0.2">Less or equal than 0.2 kWh, it is correct to not have CDR</span>
            <span v-else>CDR should have been created</span>
        </v-tooltip>
      </strong>
    </template>
    <template v-slot:tfoot>
      <v-btn
        style="position: absolute"
        class="my-4 prepend-footer-content"
        variant="text"
        size="small"
        @click="$emit('seeAllClicked')"
        ><v-icon left>mdi-arrow-top-right</v-icon>See all</v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
  name: "invoice-summary-table",
  components: {
    ProviderName,
    ChargepointName
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
};
</script>