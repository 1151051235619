<template>
    <v-icon 
        :right="right"
        :color="colorPriority(priority)"
    >
        {{ priorityIcon(priority) }}
    </v-icon>
</template>

<script>
export default ({
    props: {
        priority: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            required: true
        },
        right: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        priorityInPercentage: function(){
            return ( (this.priority - this.min) / (this.max - this.min) ) * 100
        }
    },
    methods: {
        colorPriority(){
            if (this.priorityInPercentage <= 20){
                return 'blue'
            }
            if (this.priorityInPercentage > 20 && this.priorityInPercentage <= 40 ){
                return 'green'
            }
            if (this.priorityInPercentage > 40 && this.priorityInPercentage <= 60 ){
                return 'amber'
            }
            if (this.priorityInPercentage > 60 && this.priorityInPercentage <= 80 ){
                return 'orange'
            }
            if (this.priorityInPercentage > 80){
                return 'red'
            }
        },
        priorityIcon(){
            if (this.priorityInPercentage <= 20){
                return 'mdi-chevron-double-down'
            }
            if (this.priorityInPercentage > 20 && this.priorityInPercentage <= 40 ){
                return 'mdi-chevron-down'
            }
            if (this.priorityInPercentage > 40 && this.priorityInPercentage <= 60 ){
                return 'mdi-equal'
            }
            if (this.priorityInPercentage > 60 && this.priorityInPercentage <= 80 ){
                return 'mdi-chevron-up'
            }
            if (this.priorityInPercentage > 80){
                return 'mdi-chevron-double-up'
            }
        }
    }
})
</script>