<template>

  <v-container fluid>

    <AppBar title="Tariffs" icon="mdi-tag-text-outline" />

    <Tariffs />
  </v-container>

</template>

<script>
import AppBar from "@/components/AppBar"
import Tariffs from "@/components/tariff/Tariffs"

export default {
    components: {
        AppBar,
        Tariffs
    },
};
</script>