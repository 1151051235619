<template>
  <div>
    <v-data-table
        :headers="[
            {key: 'go_to', sortable:false, cellClass: 'td-linkto' },
            {title: 'Id.', key: 'id' },
            {title: 'Start', key: 'start_date_time'},
            {title: 'End', key: 'end_date_time'},
            {title: 'CPO / EMSP', key: 'sender_receiver' },
            {title: 'Ocpi Id.', key: 'ocpi_id' },
            {title: 'Energy (kWh)', key: 'kwh'},
            {title: 'Id. DTM', key: 'dtm_session_id'},
            {title: 'Backend uuid', key: 'backend_uuid'},
            {title: 'Actions', key: 'actions'},
        ]"
        :items="sessionsWithoutCdrs"
        :loading="loading"  
        class="accent-table rounded-table"
        :sort-by="[{ key: 'id', order: 'asc' }]"
        density="compact"
        no-data-text="No sessions found without CDRs"
    >
       <template v-slot:item.go_to="{ item }">
            <v-btn 
                variant="plain"
                size="small"
                :to="`/session/${item.id}`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.sender_receiver="{ item }">
          <div class="d-flex align-center">
            <!-- Sender -->
            <v-chip v-if="item.sender_id === 1" class="bg-secondary-chip text-capitalize" variant="flat" size="small">{{ item.sender_name }}</v-chip>
            <ProviderName
                v-else
                :provider="{ id: item.sender_id, country_code: item.sender_name.split('-')[0] , party_id: item.sender_name.split('-')[1] }"
                role="cpo"
            />
            <!-- Arrow -->
            <v-icon color="secondary">mdi-arrow-right</v-icon>
            <!-- Receiver -->
            <v-chip v-if="item.receiver_id === 1" class="bg-accent text-capitalize" variant="flat" size="small">{{ item.receiver_name }}</v-chip>
            <ProviderName
                v-else
                :provider="{ id: item.receiver_id, country_code: item.receiver_name.split('-')[0] , party_id: item.receiver_name.split('-')[1] }"
                role="emsp"
            />
          </div>
        </template>
        <template v-slot:item.start_date_time="{ item }">
            {{ dateFullFormat(item.start_date_time)  }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
            {{ dateFullFormat(item.end_date_time) }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
              size="small"
              variant="outlined"
              color="accent"
              :disabled="!!item.backend_uuid && processed_backend_uuids.includes(item.backend_uuid)"
              :loading="!!item.backend_uuid && processed_backend_uuids.includes(item.backend_uuid)"
              @click="() => generateCdr(item.backend_uuid)"
            >
              <v-icon left>mdi-credit-card-multiple-outline</v-icon> Generate CDR
            </v-btn>
        </template>
    </v-data-table>

  </div>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        ProviderName
    },
    inject: ['$api'],
    data() {
      return {
        loading: false,
        sessionsWithoutCdrs: [],
        processed_backend_uuids: [],
        alert: {
          show: false,
          success: false,
          message: null
        }
      }
    },
    async mounted(){  
        this.loadSessionsWithoutCdrs()      
    },
    methods: {
      loadSessionsWithoutCdrs(){
        this.loading = true

        this.$api.get(`/sessions-without-cdrs`)
            .then(resp => this.sessionsWithoutCdrs = resp.data.map(session => {
                  return {
                      id: session.id,
                      ocpi_id: session.ocpi_id,
                      sender_id: session.senderProvider?.id,
                      sender_name: session.senderProvider?.user?.username,
                      receiver_id: session.receiverProvider?.id,
                      receiver_name: session.receiverProvider?.user?.username,
                      dtm_session_id: session.dtm_session_id,
                      kwh: session.kwh,
                      start_date_time: session.start_date_time,
                      end_date_time: session.end_date_time,
                      backend_uuid: session.backend_uuid
                  }
              })
            )
            .catch(this.showError)
            .then(() => this.loading = false)
      },
      generateCdr(backend_uuid){
        this.processed_backend_uuids.push(backend_uuid)
        if (!backend_uuid) {
          this.showError('Session has no backend_uuid')
          return 
        }

        this.$api.post(`generate-cdr/${backend_uuid}`)
          .then(() => this.showCustomAlert('success', 'Process has been executed.', { to:`/cdr-processes?search=${backend_uuid}`, text:'Check CDR process', icon:'mdi-arrow-top-right' }))
          .catch(this.showError)
          .then(() => {
            setTimeout(() => {
              this.processed_backend_uuids = this.processed_backend_uuids.filter(uuid => uuid != backend_uuid)
              this.loadSessionsWithoutCdrs()
            }, 2000)
          })
      }
    }
}
</script>