<template>
    <v-data-table-server
        :headers="[
            { key: 'click', sortable:false, cellClass: 'td-linkto', width: '5px' },
            { title: 'Id.', key: 'id' },
            { title: 'Name', key: 'name' },
            { title: 'Provider', key: 'provider' },
            { title: 'Publish', key: 'publish' },
            { title: 'Charge points', key: 'chargepoints', width: '600px' },
            { title: 'Deleted', key: 'deleted_at' },
            { title: 'Map', key: 'action', sortable: false }
        ]"
        :items="locations"
        :loading="tableLoading"
        :items-per-page="options.itemsPerPage"
        :items-length="pagination.serverItemsLength"
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        @update:options="this.updateTableOptions"
        disable-sort
        density="compact"
        class="accent-table rounded-table"
    >   

        <template v-slot:item.click="{ item }">
            <v-btn variant="plain" density="compact" size="small" slim :to="`/location/${item.id}`">
                <v-icon start>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>

        <template v-slot:item.name="{ item }">
            <LocationName
                :location="item"
                extra="no-arrow"
                type="table"
            />
        </template>

        <template v-slot:item.provider="{ item }">
            <ProviderName
                :provider="item.provider"
            />
        </template>

        <template v-slot:item.action="{ item }">
            <v-btn 
                @click.stop="openMaps(item.coordinates)"
                size="small"
                variant="text"
                icon="mdi-map"
            />
        </template>
        
        <template v-slot:item.deleted_at="{ item }">
            {{ dateFullFormat(item.deleted_at) }}
            <v-chip 
                v-if="item.deleted_at" 
                class="ml-4" 
                variant="outlined" 
                color="error" 
                size="small"
            >
                REMOVED
            </v-chip>
        </template> 

        <template v-slot:item.publish="{ item }" >
            <v-icon 
                :color="item.publish ? 'success' : 'error'"
            >
            {{ item.publish ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
        </template>

        <template v-slot:item.chargepoints="{ item }">
            <ChargepointName
                v-for="(chargepoint) in item.chargepoints"
                :key="`${chargepoint.id}`"
                class="ml-2"
                :chargepoint="{ id: chargepoint.id, name: chargepoint.reference_name }"
                type="table"
            />
        </template>

    </v-data-table-server>

</template>

<script>
    import ProviderName from "@/components/provider/ProviderName"
    import LocationName from "@/components/location/LocationName"
    import ChargepointName from "@/components/chargepoint/ChargepointName"

    export default {
    name: "locationsTable",
    components: {
    ProviderName,
    LocationName,
    ChargepointName,
    },
    props: ["locations", "tableLoading", "options", "pagination", "selectedSearch", "updateTableOptions"],
    
}

</script>