<template>
    <v-container fluid>

        <AppBar title="Export Locations to Core" icon="mdi-database-export" />

        <div class="d-flex my-4">
            <v-icon color="accent">mdi-database-export-outline</v-icon>
            <h3 class="ml-2">Select Locations to export to Core</h3>
        </div>

        <v-textarea
            class="mb-4 text-overline query-textarea" 
            style="font-size: 1rem!important"
            v-model="sqlquery"
            auto-grow
            variant="outlined"
            hide-details
            background-color="grey lighten-4"
        >
            <template v-slot:prepend-inner>
                SELECT id FROM (
            </template>
            <template v-slot:append-inner>
                ) AS a
            </template>
        </v-textarea>

        <v-row v-if="!loading" class="ma-0" align="center">
            <v-btn @click="exportLocations" color="accent">Export</v-btn>
            <v-chip v-if="results" class="ml-2" variant="outlined" color="accent">{{ exported }} Locations exported to Core</v-chip>
        </v-row>

        <v-row v-else class="ma-0" align="center">
            <v-btn  @click="forceStop" color="accent">Force Stop</v-btn>
            <v-chip class="ml-2" variant="outlined" color="accent">
                <v-progress-circular
                    class="mr-2"
                    :size="16"
                    width="2"
                    indeterminate
                    color="accent"
                />
                <template v-if="stopping">
                    Stopping
                </template>
                <template v-else>
                    Exported {{ exported }} of {{ total ? total : '?' }}
                </template>
            </v-chip>
        </v-row>

        <v-card v-if="results" 
            class="mt-4"
            variant="outlined"
        >
            <v-data-table
                class="results-data-table" 
                :items="results"              
                :headers="[
                    { key: 'data-table-group' },
                    { title: 'Location id', key: 'location_id' },
                    { title: 'Chargepoint id', key: 'chargepoint_id' },
                    { title: 'Success?', key: 'success' },
                    { title: 'Message', value: 'message' },
                ]"
                density="compact"
                :group-by="[ {key: 'location_id'} ]"
                :sort-by="[ { key: 'chargepoint_id', order: 'asc' } ]"
                :loading="loading"
            >
                <template v-slot:loader>
                    <v-progress-linear v-model="progress" color="accent"></v-progress-linear>
                </template>
                <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
                    <tr>
                        <!-- data-table-group -->
                        <td></td>
                        <!-- Location id -->
                        <td>
                            <v-row class="ma-0 pa-0" align="center">
                                <v-btn  
                                    @click="toggleGroup(item)" 
                                    :icon="isGroupOpen(item) ? 'mdi-minus' : 'mdi-plus'" 
                                    variant="text"
                                    size="small" 
                                />
        
                                <div style="white-space: nowrap;">
                                    <v-btn class="px-1" 
                                        style="min-width: 16px;"
                                        variant="plain" 
                                        icon="mdi-arrow-top-right"
                                        size="small" 
                                        :to="`/location/${item}`"
                                    />
                                    {{ item.value }}
                                </div>
                            </v-row>
                        </td>
                        <!-- Chargepoint id -->
                        <td>
                            <template v-if="item.items.some(i => !!i.chargepoint_id)">
                                {{ item.items.filter(i => !!i.chargepoint_id).map(i => i.chargepoint_id) }}
                            </template>
                        </td>
                        <!-- Success? -->
                        <td>
                            <v-icon :color="item.items.every(i => i.success) ? 'success' : 'error'">
                                {{ item.items.every(i => i.success) ? 'mdi-check-circle' : 'mdi-close-circle' }}
                            </v-icon>
                        </td>
                        <td :colspan="columns.length - 3"></td>
                    </tr>
                </template>
                <template v-slot:item.location_id="{ item }">
                    <div style="white-space: nowrap;">
                        <v-btn class="px-1" 
                            style="min-width: 16px;"
                            variant="plain" 
                            icon="mdi-arrow-top-right"
                            size="small" 
                            :to="`/location/${item.location_id}`"
                        />
                        {{ item.location_id }}
                    </div>
                </template>
                <template v-slot:item.chargepoint_id="{ item }">
                    <div v-if="item.chargepoint_id" style="white-space: nowrap;">
                        <v-btn class="px-1" 
                            style="min-width: 16px;"
                            variant="plain" 
                            icon="mdi-arrow-top-right"
                            size="small" 
                            :to="`/chargepoint/${item.chargepoint_id}`"
                        />
                        {{ item.chargepoint_id }}
                    </div>                       
                </template>
                <template v-slot:item.success="{ item }">
                    <v-icon :color="item.success ? 'success' : 'error'">
                        {{ item.success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import Bws from "@/plugins/BackofficeWebsocket.js"

export default {
    components: {
        AppBar
    },
    data() {
        return {
            sqlquery: `SELECT DISTINCT lo.id FROM locations lo
            LEFT JOIN charge_points cp ON cp.location_id = lo.id
            LEFT JOIN evses e ON e.chargepoint_id = cp.id
            LEFT JOIN connectors c ON c.evse_id = e.id
            WHERE lo.provider_id = 52 
                AND c.max_electric_power >= 150000 
                AND lo.deleted_at is null 
                AND e.deleted_at is null 
                AND lo.publish = true
            LIMIT 0`,
            loading: false,
            stopping: false,
            results: null,
            progress: 0,
            total: 0,
            exported: 0
        }
    },
    async mounted(){
         
        this.wsChannel = Bws.getSubscription('export-locations-to-core:results')

        this.wsChannel.on("message", (data) => {
            this.results = data.results
            this.progress= data.progress
            this.total = data.total
            this.exported = data.exported

            if (data.finished) {
                this.loading = false
                this.stopping = false
            }
        })

        this.wsChannel.on("error", (error) => {
            this.showError(error)
            this.loading = false
            this.stopping = false
        }) 
    },
    methods: {
        async exportLocations(){
            this.loading = true
            
            // Removed previous answer to update table
            this.results = []
            this.progress = 0
            this.total = 0
            this.exported = 0

            try {
                this.wsChannel.emit('export', { sqlquery: this.sqlquery })
            } catch(e) {
                this.loading = false
                this.showError(e)
            }
        },
        async forceStop(){
            this.stopping = true
            this.wsChannel.emit('stop')
        }
    }
}
</script>

<style scoped>
    :deep(.query-textarea .v-field__input){
        margin: 0 !important;
        padding-top: 5px;
        padding-bottom: 32px;
        padding-left: 18px;
        padding-bottom: 5px;
    }
    :deep(.query-textarea .v-field){
        display: block !important;
    }
    :deep(.query-textarea .v-field__append-inner){
        padding-top: 0px;
        opacity: 0.7;
    }
    :deep(.query-textarea .v-field__prepend-inner){
        padding-top: 0px;
        opacity: 0.7;
    }
    :deep(.query-textarea .v-field__outline){
        border-radius: inherit;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    :deep(.results-data-table thead tr:first-child th:first-child),
    :deep(.results-data-table tbody tr td:first-child) {
        display: none;
    }
</style>
