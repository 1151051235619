<template>
    <v-container fluid>

        <AppBar title="Users" icon="mdi-account-multiple" />

            <v-divider />
            <v-data-table
                class="accent-table rounded-table"
                :headers="[
                    { title: 'Id.', key: 'id'},
                    { title: 'Username', key: 'username' },
                    { title: 'email', key: 'email' },
                    { title: 'Type', key: 'type' },
                    { title: '', key: 'deleted_at', width: '114px', sortable: false },
                    { title: '', key: 'actions', width: '128px', align: 'center', sortable: false  }
                ]"
                :items="adminUsers"
                :loading="tableLoading"     
                :sort-by="[{ key: 'id', order: 'asc' }]"        
                density="compact"
            >
                <template v-slot:item.deleted_at="{ item }">
                    <v-chip v-if="item.deleted_at"
                        color="error"
                        variant="outlined"
                        size="small"
                    >
                        REMOVED
                    </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip v-if="item.deleted_at" bottom>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props"
                                icon color="success" variant="text"
                                @click="reactivateUser(item.id)"
                            >
                                <v-icon>mdi-account-reactivate</v-icon>
                            </v-btn>
                        </template>
                        <span>Reactivate</span>
                    </v-tooltip>

                    <v-tooltip v-else bottom>
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props"
                                    icon color="error" variant="text"
                                @click="deleteUser(item.id)"
                            >
                                <v-icon>mdi-account-off</v-icon>
                            </v-btn>
                        </template>
                        <span>Remove</span>
                    </v-tooltip>
                </template>
            </v-data-table>

            <v-card class="mt-2" variant="outlined">
            <v-card-title>Token Encrypt Tool</v-card-title>
            <v-card-text>
                <p>Encrypt/decrypt the entered token.</p>
                <v-row class="mt-2">
                    <v-col sm="8" lg="6">
                        <v-text-field label="Token" placeholder="cb523d8758d44003v66cd1at011c5ae9" 
                            v-model="encryptTool.token"
                            :loading="encryptTool.loading" 
                            :messages="[encryptTool.messages]" 
                            :error="encryptTool.error" 
                            :success="encryptTool.success" 
                            :disabled="encryptTool.disabled" 
                            @input="() => this.encryptTool = { ...this.encryptTool, loading: false, messages: null, success: false, error: false }"
                            variant="outlined" 
                            clearable
                            density="compact"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col sm="4" lg="4">
                        <v-btn 
                        class="mx-2"
                        variant="outlined"
                        color="accent"
                        @click="encryptToken(encryptTool.token)"
                        >Encrypt</v-btn>

                        <v-btn 
                        class="mx-2"
                        variant="outlined"
                        color="accent"
                        @click="decryptToken(encryptTool.token)"
                        >Decrypt</v-btn>
                    </v-col>
                    <v-col sm="12" lg="4" v-if="encryptTool.encryptedToken">
                        <p>{{encryptTool.encryptedToken}}</p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    name: "wenea",
    components: {
        AppBar
    },
    inject: ['$api'],
    data() {
        return {
            adminUsers: [],
            tableLoading: false,
            encryptTool: { token: null, encryptedToken: null, loading: false, messages: null, success: false, disabled: false, error: false }
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        load() {
            this.tableLoading = true
            this.$api.get("/admin-users")
                .then(res => this.adminUsers = res.data)
                .catch(this.showError)
                .then(() => this.tableLoading = false)
        },
        deleteUser(user_id) {
            this.tableLoading = true

            this.$api.delete(`user/${user_id}`)
                .then(this.showSuccess("User modified successfully"))
                .catch(this.showError)
                .then(() => {
                    this.tableLoading = false
                    this.load()
                })
        },
        reactivateUser(user_id){
            this.tableLoading = true

            this.$api.patch(`user/${user_id}`, { deleted_at: null })
                .then(this.showSuccess("User modified successfully"))
                .catch(this.showError)
                .then(() => {
                    this.tableLoading = false
                    this.load()
                })
        },
        encryptToken(token) {
            if (!token || token === '') {
                this.encryptTool = { ...this.encryptTool, messages:'The field is required.', error: true }
                return
            }

            const data = {
                string: token
            }

            this.encryptTool = { ...this.encryptTool, loading: true, disabled: true }
            this.$api.post("/token/encrypt", data)
                .then(res => this.encryptTool = { ...this.encryptTool, messages: `Encrypted token: ${res.data.encrypted}`, success: true })
                .catch(() => this.encryptTool = { ...this.encryptTool, messages:'An error occurred while encrypt token.', error: true })
                .then(() => this.encryptTool = { ...this.encryptTool, loading: false, disabled: false })
        },
        decryptToken(token) {
            if (!token || token === '') {
                this.encryptTool = { ...this.encryptTool, messages:'The field is required.', error: true }
                return
            }

            const data = {
                token
            }

            this.encryptTool = { ...this.encryptTool, loading: true, disabled: true }
            this.$api.post("/token/decrypt", data)
                .then(res => {
                    if (res.data.decrypted) {
                        this.encryptTool = { ...this.encryptTool, messages: `Decrypted token: ${res.data.decrypted}`, success: true }
                    } else {
                        this.encryptTool = { ...this.encryptTool, messages:'The token entered is not correct.', error: true }
                    }
                })
                .catch(() => this.encryptTool = { ...this.encryptTool, messages:'An error occurred while decrypt token.', error: true })
                .then(() => this.encryptTool = { ...this.encryptTool, loading: false, disabled: false })
        }
    }
};
</script>

<style scoped>
    :deep(.v-messages) {
        font-size: 16px;
    }
    :deep(.v-messages__message) {
        line-height: 16px;
    }
</style>
