<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <v-btn-toggle
        class="pt-2"
        v-model="groupBy"
        dense
        multiple
      >
        <v-btn 
          value="provider"
          color="accent"
          text>
          Provider
        </v-btn>

        <v-btn 
          value="charge_point"
          color="accent"
          text>
          Charge point
        </v-btn>
      </v-btn-toggle>
    </div>

    <template v-if="isGroupedByProvider">
      <v-data-table
        class="accent-theme rounded-table"
        item-key="provider_id"
        :headers="[]"
        :items="groupedCollidedTariffs"
        :page.sync="providerPage"
        :items-per-page.sync="providersPerPage"
        :expanded.sync="collidedTariffsOfSelectedProvider"
        :loading="loading" 
        no-data-text="No collided tariffs found"
        show-expand
        single-expand
        hide-default-header
        hide-default-footer
        density="compact"
    >
      <template v-slot:item.data-table-expand="{ toggleExpand, isExpanded, item }">
        <v-row 
          class="pointer bg-accent"
          align="center" 
          @click.stop="goTo(`/provider/${item.provider_id}`)"
        >
          <v-btn @click.stop="toggleExpand(item)" :ref="item.provider_id" variant="text">
            <v-icon v-if="isExpanded(item)">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
          <span class="text-subtitle-1">{{ item.provider_name }}</span>
        </v-row>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="pa-0">
          <CollidedTariffsTable 
            :items="item.collidedTariffs" 
            :groupByChargePoint="isGroupedByChargePoint" 
            :page="page" 
            :itemsPerPage="itemsPerPage"
            light-theme 
            hide-default-footer 
            hide-provider-info>
          </CollidedTariffsTable>
        </td>
      </template>
    </v-data-table>
      
    </template>
    <template v-else>
      <CollidedTariffsTable
        class="rounded-table" 
        :items="groupedCollidedTariffs" 
        :loading="loading" 
        :groupByChargePoint="isGroupedByChargePoint" 
        :page="page" 
        :itemsPerPage="itemsPerPage" 
        hide-default-footer>
      </CollidedTariffsTable>
    </template>

    <!-- Table footer -->
    <v-row
      class="mt-0 mx-0 px-1"
      align="center"
      justify="center"
    >
      <template v-if="isGroupedByProvider">
        <span class="mr-2 text-caption table-footer">
          Providers
        </span>
        <span class="mr-3 text-caption table-footer">
          {{ providersOffset }}-{{ providersLimit }} of {{ providersToPaginate }}
        </span>

        <v-btn
          variant="text"
          color="accent"
          :disabled="!hasPreviousProvidersPage"
          @click="formerProvidersPage"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          variant="text"
          color="accent"
          :disabled="!hasNextProvidersPage"
          @click="nextProvidersPage"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <v-spacer></v-spacer>

      <span class="mr-8 text-caption table-footer">Rows per {{ isGroupedByProvider ? 'provider' : 'page' }}:</span>
      <v-select
        class="select-items-per-page mb-4"
        v-model="itemsPerPage"
        :items="itemsPerPageArray"
        variant="underlined"
        hide-details
      ></v-select>

      <span class="mx-3 text-caption table-footer">
        {{ offset }}-{{ limit }} of {{ itemsToPaginate }}
      </span>

      <v-btn
        variant="text"
        color="accent"
        :disabled="!hasPreviousPage"
        @click="formerPage"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        variant="text"
        color="accent"
        :disabled="!hasNextPage"
        @click="nextPage"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>

    <div class="napies">
      <template use="my_ul"></template>
    </div>
    
  </div>
</template>

<script>
import CollidedTariffsTable from "@/components/tools/CollidedTariffsTable";

export default {
  components: {
   CollidedTariffsTable
  },
  inject: ['$api'],
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 10,
      itemsPerPageArray: [5, 10, 15, 20],
      collidedTariffs: [],
      collidedTariffsOfSelectedProvider: [],
      groupBy: ['charge_point'],
      providerPage: 1,
      providersPerPage: 5,
    }
  },
  watch: {
    isGroupedByProvider(value) {
      if(value){
        this.collidedTariffsOfSelectedProvider = [this.groupedCollidedTariffs[0]]
      }
    },
    providerPage() {
      // Expand first item on page
      this.collidedTariffsOfSelectedProvider = [this.groupedCollidedTariffs[this.providersOffset - 1]]
    }
  },
  computed: {
        groupedCollidedTariffs: function(){
            if (this.isGroupedByProvider) {
              const collidedTariffsGroupedByProv = this.collidedTariffs.reduce((collidedTariffs, item) => ({
                      ...collidedTariffs,
                      [item.applicable_provider]: { provider_id: item.applicable_provider, provider_name: item.provider_name, collidedTariffs: [...(collidedTariffs[item.applicable_provider]?.collidedTariffs || []), item] }
                    }), {})

              return Array.from(Object.values(collidedTariffsGroupedByProv))
            }
            return this.collidedTariffs
        },
        isGroupedByProvider(){
          return this.groupBy.includes('provider')
        },
        isGroupedByChargePoint(){
          return this.groupBy.includes('charge_point')
        },
        // Pagination
        itemsToPaginate () {
          return this.isGroupedByProvider ? this.collidedTariffsOfSelectedProvider[0] ? this.collidedTariffsOfSelectedProvider[0].collidedTariffs.length : 0 : this.groupedCollidedTariffs.length
        },
        offset: function(){
            return ((this.page - 1) * this.itemsPerPage) + 1
        },
        limit: function(){
            return this.page == this.numberOfPages ? ((this.page - 1) * this.itemsPerPage) + (this.itemsToPaginate - ((this.page - 1) * this.itemsPerPage))  : ((this.page - 1) * this.itemsPerPage) + this.itemsPerPage
        },
        numberOfPages () {
          return Math.ceil(this.itemsToPaginate / this.itemsPerPage)
        },
        hasPreviousPage () {
          return this.page - 1 >= 1
        },
        hasNextPage () {
          return this.page + 1 <= this.numberOfPages
        },
        // Provider pagination
        providersToPaginate () {
          return this.groupedCollidedTariffs.length
        },
        providersOffset: function(){
            return ((this.providerPage - 1) * this.providersPerPage) + 1
        },
        providersLimit: function(){
            return this.providerPage == this.numberOfPages ? ((this.providerPage - 1) * this.providersPerPage) + (this.providersToPaginate - ((this.providerPage - 1) * this.providersPerPage))  : ((this.providerPage - 1) * this.providersPerPage) + this.providersPerPage
        },
        numberOfProvidersPages () {
          return Math.ceil(this.providersToPaginate / this.providersPerPage)
        },
        hasPreviousProvidersPage () {
          return this.providerPage - 1 >= 1
        },
        hasNextProvidersPage () {
          return this.providerPage + 1 <= this.numberOfProvidersPages
        },
    },
  async mounted(){  
      this.loadCollidedTariffs()      
  },
  methods: {
    loadCollidedTariffs(){
      this.loading = true

      /* this.$api.get(`/collided-tariffs`)
          .then(resp => this.collidedTariffs = resp.data)
          .catch(this.showError)
          .then(() => this.loading = false) */
        
        //todo delete fake data
         this.collidedTariffs = [
            {
                "charge_point_id": 28955,
                "charge_point_name": "VIRT_TEST_3",
                "connector_id": 35216,
                "applicable_provider": 92,
                "applicable_user": null,
                "provider_name": "ES-EFI",
                "priority": 1,
                "collided_tariffs": [
                    13,
                    39282,
                    39283
                ]
            },
            {
                "charge_point_id": 28956,
                "charge_point_name": "VIRT_TEST_4",
                "connector_id": 35217,
                "applicable_provider": 92,
                "applicable_user": null,
                "provider_name": "ES-EFI",
                "priority": 1,
                "collided_tariffs": [
                    13,
                    39282,
                    39283
                ]
            },
            {
                "charge_point_id": 28955,
                "charge_point_name": "VIRT_TEST_3",
                "connector_id": 35218,
                "applicable_provider": 92,
                "applicable_user": null,
                "provider_name": "ES-EFI",
                "priority": 1,
                "collided_tariffs": [
                    13,
                    39282,
                    39283
                ]
            }
        ]
        this.loading = false

    },
    nextPage () {
      if (this.hasNextPage) this.page += 1
    },
    formerPage () {
      if (this.hasPreviousPage) this.page -= 1
    },
    nextProvidersPage () {
      if (this.hasNextProvidersPage) this.providerPage += 1
    },
    formerProvidersPage () {
      if (this.hasPreviousProvidersPage) this.providerPage -= 1
    }
  }
}
</script>

<style scoped>
    :deep(.v-data-table-header){
        background: var(--v-accent-base);
    }

    :deep(.rounded-table)>.v-data-table__wrapper > table > tbody > tr:first-child > td  {
        border-radius: 5px 5px 0 0;
    }

    :deep(table)>thead > tr > th, :deep(.v-data-table).v-data-table-headerth.sortable.active .v-data-table-header__icon, :deep(.v-data-table-header)th.sortable.v-data-table-header__icon  {
        color: white !important;
    }

    :deep(.v-data-table__progress)>th {
      padding: 0 !important;
    }

    :deep(.accent-theme)>.v-data-table__wrapper > table > tbody, 
    .accent-theme > .v-data-table__wrapper > table > tbody button, 
    .accent-theme > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: var(--v-accent-base) !important;
      color: white !important;
    }

    :deep(.theme--light.v-data-table).v-data-table__empty-wrapper{
      background: white !important;
    }

    :deep(.theme--light.v-data-table.accent-theme)>.v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: none !important;
    }

    :deep(.v-data-table__expanded__content)>td > div {
      border-radius: 0!important;
    }

    :deep(.select-items-per-page.v-input){
      max-width: 50px;
    }

    :deep(.select-items-per-page).v-select__selection{
      font-size: 0.75rem;
    }

    :deep(.table-footer){
      letter-spacing: 0.0071428571em !important;
      color: black !important;
      font-weight: 400;
    }

    :deep(.pointer){
      cursor: pointer;
    }

    :deep(.v-table__wrapper){
        overflow: hidden;
    }
</style>