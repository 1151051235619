<template>
    <div class="d-flex flex-nowrap">
        <v-autocomplete
            class="component-select rounded-s"
            @update:modelValue="$emit('update:modelValue', $event)"
            :items="items"
            :label="label"
            single-line
            hide-details
            density="compact"
        >
            <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :title="item.raw.menuText"
                />
            </template>
        </v-autocomplete>
        
        <v-tooltip bottom :disabled="!tooltipLabel">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props"
                :loading="loading"
                @click="cb"
                elevation="0"
                color="accent"
                class="component-button">
                    <slot></slot>
                </v-btn>
            </template>
            <span>{{ tooltipLabel }}</span>
        </v-tooltip>
    </div>
</template>

<script>

export default ({ //TODO: Improve component style
    props: {
        // Object list with text(Displayed text of selected item), menuText(Text displayed in dropdown) and value(Returned value) parameters
        items: Array, 
        filter: Function, 
        label: String, 
        loading: Boolean, 
        'tooltip-label': String,
        cb: Function
    }
})
</script>

<style scoped>
    :deep(.component-select.v-input){
        margin-top: 6px!important;
        margin-bottom: 6px!important;
        max-width: 115px;
        color: white !important;
        border-color: rgb(var(--v-theme-accent)) !important;
        background-color: rgb(var(--v-theme-accent)) !important;
    }

    :deep(.component-button.v-btn){
        border-radius: 0 4px 4px 0;
        height: 40px;
        margin-top: 6px!important;
        margin-bottom: 6px!important;
    }
</style>
