<template>

  <v-container fluid>

    <AppBar title="Cron jobs" icon="mdi-calendar-check" />

    <CronJobs />
    
  </v-container>

</template>

<script>
import AppBar from "@/components/AppBar"
import CronJobs from "@/components/cron-jobs/CronJobs"

export default {
    components: {
      AppBar,
      CronJobs
    }
};
</script>