<template>
    <v-container v-if="provider">

        <AppBar
            :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: name, icon: 'mdi-earth', to: `/provider/${provider_id}` },
            { text: 'Cdrs' }
            ]"
        />
            <!-- CDRs -->
           <div>
                <div class="d-flex align-center">
                    <v-icon start color="accent">mdi-credit-card-multiple-outline</v-icon>
                    <h2>CDRs</h2>
                </div>
                <ProviderCdrs :providerId="provider.id" :providerRole="url"></ProviderCdrs>
           </div>

            <!-- Provider CDRs -->
           <div v-if="hasRole('EMSP')" class="mt-6">
                <div class="d-flex align-center">
                    <v-icon start color="accent">mdi-calculator-variant</v-icon>
                    <h2>CDR Processes</h2>
                </div>
                <CdrProcesses :providerId="provider.id" toolbar-right></CdrProcesses>
           </div>


    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar";
import ProviderCdrs from "@/components/provider/ProviderCdrs";
import CdrProcesses from "@/components/cdr-process/CdrProcesses"

export default {
    components: {
        AppBar,
        ProviderCdrs,
        CdrProcesses
    },
    inject: ['$api'],
    data() {
        return {
            url: null,
            provider: null,
            panel: [0,1],
        }
    },
    computed: {
        name() {
            return this.provider
                ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
                : "-"
        },
        provider_id(){
            return this.provider ? this.provider.id : 0
        },
    },
    mounted() {
        this.url = this.$route.params.role.toUpperCase();
        this.loadData();
    },  
    methods: {
        async loadData() {
            let id = this.$route.params.id
            if (!id) {
                return
            }
    
            this.providerLoading = true
            let provider = await this.$api
                .get(`/provider/${id}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            if (!provider) {
                this.showError(`Provider #${id} not found`)
                return
            }
            this.provider = provider
            if(!this.hasRole('EMSP') && !this.hasRole('CPO')){  
                this.$router.push(`/provider/${this.provider.id}`)
            };
            this.providerLoading = false
        },
        hasRole(role){
            return this.provider && this.provider.role && this.provider.role.includes(role) && (this.url == role)
        },
    }
}
</script>