<template>
    <v-container fluid>

        <AppBar title="Wenea" />

        <h2>Wenea</h2>
        <v-row v-if="info" class="ma-2">
            <v-col>
                <div class="text-h6">Host</div>
                <div>{{info.host}}</div>
            </v-col>
            <v-col>
                <div class="text-h6">Roles</div>
                <div v-if="info.roles.cpo"><span class="font-weight-bold">CPO</span>: {{info.roles.cpo.country_code}}-{{info.roles.cpo.party_id}}</div>
                <div v-if="info.roles.emsp"><span class="font-weight-bold">EMSP</span>: {{info.roles.emsp.country_code}}-{{info.roles.emsp.party_id}}</div>
            </v-col>
        </v-row>

        <h2 class="pl-1 mt-6">Provider credentials</h2>
        <v-data-table
            v-if="credentials"
            :headers="[
                {title: 'Id.', key: 'id'},
                {title: 'Token', key: 'token'},
                {title: 'Updated', key: 'updated_at'},
                {title: 'User', key: 'user.username'},
                {title: 'Type', key: 'user.type'},
                {title: 'Company', key: 'connection'},
            ]"
            :items="credentials"
            disable-pagination
            hide-default-footer
            class="accent-table rounded-table"
            density="compact"
        >
        <template v-slot:item.connection="{ item }">
            <ProviderName
                :provider="{ applicable_user: item.user.id, username: provider_name(item) }"
                extra="applicable_user"
            />
        </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "wenea",
    components: {
        AppBar,
        ProviderName
    },
    inject: ['$api'],
    data() {
        return {
            info: null,
            credentials: null,
            locations: null,
            evses: null,

            updateBackendDataLoading: false
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        load(){

            this.$api.get('wenea')
                .then(resp => { this.info = resp.data })
                .catch(this.showError)

            this.$api.get('wenea/credentials')
                .then(resp => { this.credentials = resp.data })
                .catch(this.showError)

        },
        openMaps(item){
            window.open(`https://www.google.com/maps/search/?api=1&query=${item.coordinates.latitude},${item.coordinates.longitude}`)
        },
        provider_name(item){
            return item?.user.company?.name || item.user.username
        }
    }
};
</script>