import { createVuetify } from 'vuetify'
import * as components from "vuetify/components"
import * as directives from 'vuetify/directives'
import { VStepperVertical, VStepperVerticalItem, VStepperVerticalActions } from 'vuetify/labs/components'

export default createVuetify({
    lang: {
        current: 'en',
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            defaultTheme: "light",
            light: {
                colors: {
                    primary: '#424242',     //oscuro
                    secondary: '#fbb800',   //amarillo
                    accent: '#8c4799',      //violeta
                    'accent-lighten1': '#a760b4'
                },
            }
        }
    },
    components: {
        ...components,
        VStepperVertical,
        VStepperVerticalItem,
        VStepperVerticalActions
    },
    directives,
    legacy: false
})
/*
TODO: Search if where we can find this, 'vuetify/lib/' does not exist in vuetify 3
VDataFooter.options.props.itemsPerPageOptions.default = () => [25, 50, 100, -1]
VDataTable.$_vuetify_subcomponents.VDataTable.options.props.itemsPerPage.default = 25
*/