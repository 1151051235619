<template>
  <v-dialog v-model="show" persistent max-width="1180px">
    <v-card>
      <v-card-title>
        <span class="headline">Set filters</span>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row class="mt-4" align="center" justify="center">
          <!-- Date range -->
          <v-col cols="12" md="3" v-if="selectedFilterBy == 'year'">
              <v-select 
                  v-model="selectedYear" 
                  :items="years" 
                  class="accent"
                  label="Year" 
                  prepend-inner-icon="mdi-calendar" 
                  density="compact"
                  variant="outlined"
                  hide-details 
                  :menu-props="{ contentClass: 'year-selector' }"
              >
                  <template v-slot:item="{ props }">
                      <v-list-item v-bind="props" color="accent" title>
                            <v-list-item-title 
                                class="text-left pepe" 
                                :style="`${selectedYear == props.value
                                    ? 'font-size: 1.625rem; line-height: 1.375rem; font-weight: 500;' 
                                    : 'font-size: 1rem; font-weight: 400;'} text-align: center !important;`"
                            >
                                {{ props.value }}
                            </v-list-item-title>
                      </v-list-item>
                  </template>
              </v-select>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'month'">
              <v-menu 
                  ref="menu" 
                  v-model="showMonthPicker" 
                  :close-on-content-click="false" 
                  transition="scale-transition"
                  offset-y
                  color="accent"
                  min-width="auto"
                  hide-details
                  >
                  <template v-slot:activator="{ props }">
                      <v-text-field 
                          v-bind="props"
                          v-model="selectedMonth" 
                          class="accent"
                          label="Month"
                          prepend-inner-icon="mdi-calendar" 
                          readonly
                          density="compact"
                          variant="outlined"
                          hide-details
                      >
                      </v-text-field>
                  </template>
                  <v-date-picker 
                      v-model="selectedMonthPicker"
                      @update:modelValue="updatePickerSelected"
                      header-color="accent" 
                      view-mode="months" 
                      no-title
                      hide-details
                  >
                  </v-date-picker>
              </v-menu>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'day'">
              <v-menu 
                  ref="menu" 
                  v-model="showDateFromPicker" 
                  :close-on-content-click="false"
                  transition="scale-transition" 
                  offset-y min-width="auto" 
                  hide-details
              >
                  <template v-slot:activator="{ props }">
                      <v-text-field 
                          v-bind="props"
                          v-model="selectedDateFrom" 
                          class="accent"
                          label="Date From" 
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          density="compact"
                          variant="outlined"
                          hide-details
                      >
                      </v-text-field>
                  </template>
                  <v-date-picker v-model="selectedDateFromPicker" no-title hide-details @update:modelValue="updateFromPickerSelected"></v-date-picker>
              </v-menu>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'day'">
              <v-menu 
                  ref="menu" 
                  v-model="showDateToPicker" 
                  :close-on-content-click="false" 
                  transition="scale-transition"
                  offset-y 
                  min-width="auto" 
                  hide-details
              >
                  <template v-slot:activator="{ props }">
                      <v-text-field 
                          v-bind="props"
                          v-model="selectedDateTo"
                          class="accent"
                          label="Date To"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          density="compact"
                          variant="outlined"
                          hide-details
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedDateToPicker" no-title hide-details @update:modelValue="updateToPickerSelected"></v-date-picker>
              </v-menu>
          </v-col>

          <v-btn-toggle class="pa-1" v-model="selectedFilterBy" mandatory variant="outlined">
              <v-btn class="min-with-80" value="year" color="accent"> Year </v-btn>
              <v-btn class="min-with-80" value="month" color="accent"> Month </v-btn>
              <v-btn class="min-with-80" value="day" color="accent"> Day </v-btn>
          </v-btn-toggle>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false" text>Cancel</v-btn>
        <v-btn @click="applyFilters" color="accent">Filter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
  components: {
    ProviderSelectorTemplate
  },
  inject: ["$api"],
  data() {
    return {
      show: false,
      providers: [],
      years: [],
      selectedYear: null,
      selectedMonth: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedFilterBy: null,
      showMonthPicker: false,
      showDateFromPicker: false,
      showDateToPicker: false,
      selectedMonthPicker: null,
      selectedDateFromPicker: null,
      selectedDateToPicker: null,
    };
  },
  watch: {
    show(show) {
      if (!show) {

        this.selectedYear = null
        this.selectedMonth = null
        this.selectedDateFrom = null
        this.selectedDateTo = null
        this.selectedFilterBy = null

        EventBus().emit("dialog-filters:close")
      }
    },
    selectedFilterBy: function (newValue) {
      if (!newValue) {
          this.selectedFilterBy = null
      }

      if (newValue == "day" && this.selectedDateFrom == null) {
          this.selectedMonth = null
          this.selectedYear = null
          this.selectedDateFrom = new Date().toISOString().substr(0, 10)
          this.selectedDateTo = new Date().toISOString().substr(0, 10)
      }

      if (newValue == "month" && this.selectedMonth == null) {
        //Current month
          this.selectedMonth = new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              1
          ).toISOString().substr(0, 7)

          this.selectedDateFrom = null
          this.selectedDateTo = null
          this.selectedYear = null
      }

      if (newValue == "year" && this.selectedYear == null) {
          this.selectedMonth = null
          this.selectedDateFrom = null
          this.selectedDateTo = null

          this.selectedYear = Number(new Date().getFullYear())
      }
    },
  },
  mounted() {
    const actualYear = Number(new Date().getFullYear())
    this.years = [...Array(40)].map((_, i) => actualYear - i)

    this.$api
      .get("/providers")
      .then((res) => {
        this.providers = res.data.map((p) => {
          return {
            ...p,
            title: p.role
              ? `${p.country_code}-${p.party_id} ${p.user.company_name} (${p.role})`
              : `${p.country_code}-${p.party_id} ${p.user.company_name}`,
            value: p.id,
          }
        })
      })
      .catch(this.showError)
  },
  created() {
    var vm = this
    EventBus().on("dialog-filters:open", async function (args) {

      vm.selectedYear = args.selectedYear
      vm.selectedMonth = args.selectedMonth
      vm.selectedDateFrom = args.selectedDateFrom
      vm.selectedDateTo = args.selectedDateTo
      vm.selectedFilterBy = args.selectedFilterBy || "month"

      vm.show = true
    })
  },
  methods: {
    applyFilters() {

      EventBus().emit("dialog-filters:filters-applied", {
        selectedYear: this.selectedYear,
        selectedMonth: this.selectedMonth,
        selectedDateFrom: this.selectedDateFrom,
        selectedDateTo: this.selectedDateTo,
        selectedFilterBy: this.selectedFilterBy,
      })

      this.show = false

    },

    updatePickerSelected() {
      this.selectedMonth = this.monthFormat(this.selectedMonthPicker.toISOString())
      this.showMonthPicker = false
    },

    updateFromPickerSelected() {
      this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
      this.showDateFromPicker = false
    },

    updateToPickerSelected() {
      this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
      this.showDateToPicker = false
    },
  },
}
</script>

<style scoped>
.min-with-80 {
  min-width: 80px !important;
  height: 40px !important;
}
</style>