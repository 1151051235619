<template>
    <highcharts :options="chartOptions"></highcharts>
</template>

<script>
export default {
    props: {
        sessions: {
            type: Array,
            required: true
        },
        colorPerProvider: {
            type: Map, // [provider_id]: color_hex
            required: true
        }
    },
    computed: {
        chartOptions(){
            return {
                chart: {
                    type: 'pie',
                    height: 450,
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        colors: this.colors,
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Transactions',
                    innerSize: '40%',
                    colorByPoint: true,
                    data: this.chartData
                }]
            }
        },
        colors() {
            const colors = []
            for (const data of this.chartData) {
                colors.push(this.colorPerProvider.get(data.name))
            }
            return colors
        },
        chartData() {
            const chartData = []
            for (const providerTransactions of this.sessions) {
                chartData.push({
                    name: providerTransactions.provider,
                    y: Number(providerTransactions.sessions)
                })
            }
            return chartData
        }
    },
}
</script>