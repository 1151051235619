<template>
    <v-card variant="outlined">
        <v-card-text>
            <div class="pa-2">
                <v-form 
                    ref="form"
                    v-model="valid"
                    @submit.prevent="send"
                >
                    <v-row>
                        <v-select
                            class="method-select"
                            v-model="method"
                            :items="methods"
                            :rules="[ v => !!v ]"
                            single-line
                            label="METHOD"
                            variant="outlined"
                            hide-details
                            density="compact"
                        ></v-select>
                        <v-combobox
                            :loading="loading"
                            class="url-input"
                            v-model="url"
                            :items="endpoints"
                            :rules="[ v => !!v ]"
                            single-line
                            label="Enter request URL"
                            return-object
                            variant="outlined"
                            hide-details
                            :append-icon="null"
                            density="compact">
                        </v-combobox>

                        <v-btn
                            class="ml-2"
                            elevation="0"
                            color="accent"
                            height="40"
                            type="submit"
                        >Send</v-btn>
                    </v-row>

                    <v-row class="mt-8">
                        <v-textarea
                            v-model="body"
                            :rules="[ validateBody ]"
                            label="Body"
                            rows="2"
                            auto-grow
                            variant="outlined"
                        ></v-textarea>
                    </v-row>
                </v-form>

                <v-divider class="mt-2 mb-n2 mx-n2"/>

                <v-row class="mt-6">
                    <div class="response-content pa-4" v-if="response" >
                        <v-row>
                            <json-viewer
                                style="width:100%;"
                                class="pa-2"
                                :value="response"
                                :copyable="{
                                copyText: 'Copy',
                                copiedText: 'Copied!',
                                timeout: 2000,
                                }"
                                :expand-depth="3"
                            >
                            </json-viewer>
                        </v-row>
                    </div>
                    <div class="response-content px-2 py-4"  v-else>
                        <span style="font-size:16px;font-weight:400;height:20px;line-height:20px;">Response</span>
                    </div>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "http-call-handler-tool",
  props: ["provider_id"],
  inject: ['$api'],
  data() {
    return {
        endpoints: [],
        
        valid: true,
        methods: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
        url: null,
        method: 'GET', 
        body: null,

        loading: false,
        response: null
    };
  },
  mounted() {
      this.load()
  },
  methods: {
    async load() {
        await this.$api
            .get(`provider/${this.provider_id}/endpoints`)
            .then((resp) => this.endpoints = resp.data.map(enpoint => enpoint.url))
            .catch(this.showError)
    },
    send() {

        // Empty the previous answer
        this.response = null
        
        if(!this.$refs.form.validate()) { return  }

        const data = {
            url: this.url,
            method: this.method,
            data: this.body ? JSON.parse(this.body) : null
        }

        this.loading = true
        this.$api
            .post(`provider/${this.provider_id}/http-call`, data)
            .then((resp) => {
                this.response = resp.data
            })
            .catch(this.showError)
            .then((_) => {
                this.loading = false
            });
    },
    validateBody(v) {
        if(!v || v == '') { return true }
    
        try {
            JSON.parse(v)
            return true
        } catch(e) {
            return false
        }
    }
  },
};
</script>

<style scoped>
    :deep(.method-select.v-input){
        max-width: 125px;
    }    
    :deep(.method-select .v-field--variant-outlined) {
        border-radius: 4px 0 0 4px;
    }
    :deep(.url-input .v-field--variant-outlined) {
        border-radius: 0 4px 4px 0;
    }
    :deep(.response-content){
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.38);
        border-width: 1px;
        border-style: solid;
        min-height: 66px;
        width: 100%;
    }
    :deep(.response-content:hover){
        border-color: black;
    }
</style>