<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    viewBox="0 0 1066.6667 1066.6667"
  >
    <g
      id="g8"
      inkscape:groupmode="layer"
      inkscape:label="logoWENEA"
      transform="matrix(1.3333333,0,0,-1.3333333,0,1066.6667)"
    >
      <g id="g10">
        <g id="g12">
          <g id="g18">
            <g id="g20">
              <path
                d="M 697.101,712.846 629.194,195.623 c -1.132,-9.62 -3.96,-16.977 -8.488,-21.786 v 0 c -4.527,-4.811 -11.884,-7.357 -22.069,-7.357 v 0 c -19.24,0 -30.559,8.206 -33.954,24.617 v 0 l -60.832,272.758 c -6.792,29.992 -19.524,52.91 -38.199,68.189 v 0 c -18.673,15.279 -46.119,22.918 -81.77,22.918 v 0 c -35.651,0 -63.663,-7.639 -83.469,-22.918 v 0 C 280.607,516.765 267.309,494.13 260.518,463.855 v 0 L 196.573,191.379 c -2.264,-7.922 -5.942,-14.147 -10.752,-18.391 v 0 c -4.81,-4.245 -12.45,-6.508 -22.636,-6.508 v 0 c -10.751,0 -18.675,2.263 -23.484,7.357 v 0 c -4.811,4.809 -7.923,12.166 -9.054,21.786 v 0 L 104.616,402.456 H 7 L 39.821,190.53 c 2.264,-15.279 5.942,-29.426 11.035,-42.158 v 0 c 5.093,-12.733 12.45,-23.768 21.788,-32.822 v 0 c 9.336,-9.054 21.22,-16.128 35.65,-21.221 v 0 c 14.43,-5.093 32.255,-7.639 53.194,-7.639 v 0 c 35.651,0 63.662,7.639 83.468,22.918 v 0 c 19.806,15.28 33.388,38.481 41.027,69.887 v 0 l 63.663,273.041 c 3.395,14.714 14.43,22.07 33.104,22.07 v 0 c 8.488,0 15.846,-1.697 21.787,-5.092 v 0 c 5.941,-3.396 9.902,-9.054 11.6,-16.978 v 0 l 60.551,-273.324 c 6.79,-31.123 19.806,-54.608 39.046,-69.887 v 0 c 19.24,-15.279 46.402,-22.918 81.771,-22.918 v 0 c 40.744,0 71.019,9.62 90.259,28.577 v 0 c 19.24,18.958 31.124,44.14 35.651,75.263 v 0 L 795,712.846 Z"
                :style="`fill: ${color};stroke:none`"
                id="path32"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "wenea-icon",
  props: {
    color: {
      type: String,
      default: "rgba(0, 0, 0, 0.54)",
    },
  },
};
</script>