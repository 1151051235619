<template></template>

<script>  
    export default {
        name: 'v-marker',
        props: {
            google: {
                type: Object,
                required: true
            },
            map: {
                type: Object,
                required: true
            },
            position: {
                type: Object,
                required: true
            }
        },
        async mounted() {
            new this.google.maps.Marker({
                position: this.position,
                map: this.map,
            })
        }
    }
</script>