<template>
    <v-container fluid>

        <AppBar title="Locations Amount by Provider" icon="mdi-map-marker" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />

            <v-spacer />

            <v-btn 
                color="accent"
                :loading="loadingCSV"
                variant="outlined"
                @click="createCSV"
                prepend-icon="mdi-file-download-outline"
            >
                CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table-server
            :headers="[
                { title: 'Provider', key: 'provider' },
                { title: 'Locations', key: 'locations' },
                { title: 'Locations with DC +50kW', key: 'locations_with_dc50' },
                { title: 'Locations with DC +100kW', key: 'locations_with_dc100' },
                { title: 'Locations with DC +150kW', key: 'locations_with_dc150' },
                { title: 'Locations with DC +300kW', key: 'locations_with_dc300' },
                { title: 'Most Common Countries', key: 'countries_list'}
            ]"
            :items="locationsAmount"
            :items-length="locationsAmount.length"
            :loading="tableLoading"
            disable-sort
            density="compact"
            class="accent-table rounded-table"
        >
        
            <template v-slot:item.provider="{ item }">
                <ProviderName
                :provider="{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }"
                />
            </template>

            <template v-slot:item.countries_list="{ item }">
                <v-chip
                    v-for="(country) in item.countries_list"
                    color="accent"
                    class="mr-1"
                    variant="outlined"
                    size="small"
                >
                    {{ country }}
                </v-chip>
            </template>

        </v-data-table-server>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "wenea",
    components: {
    AppBar,
    SearchTextField,
    SearchButton,
    ProviderSelectorTemplate,
    ProviderName
    },
    inject: ['$api'],
    data() {
        return {
            selectedSearch: null,
            tableLoading: false,

            selectedProvider: 1,

            providers: [],
            locationsAmount: [],
            loadingCSV: false,
            initialLoad: true,
        }
    },
    mounted(){
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {

        load() {   
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data
                        .filter(p => p.role.includes('CPO'))
                        .map(p => { 
                            return { 
                                ...p, 
                                title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                                value: p.id 
                            } 
                        })
                    })
                .catch(this.showError)
        },

        async loadLocationsAmount(){
            this.tableLoading = true
            this.getLocationsAmount()
                .then(resp => {
                    this.locationsAmount = resp.data
                })
                .catch(this.showError)
                .finally(() => this.tableLoading = false)
        },

        async getLocationsAmount(){

            let queryParams = ''

            if (this.selectedSearch && this.selectedSearch !== '') 
                { queryParams += `&orfilter[locations.provider_id, locations.party_id, locations.country_code]=${this.selectedSearch}` }

            if (this.selectedProvider) { queryParams += `&filter[locations.provider_id]=${this.selectedProvider}` }

            return await this.$api.get(`reports/locations-amount-by-provider?${queryParams}`)

        },

        onFilter(){
            if(this.tableLoading) return

            this.urlPush('search', this.selectedSearch)
            this.urlPush('provider', this.selectedProvider)

            this.loadLocationsAmount()
        },

        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadLocationsAmount()
            }
        },

        async createCSV(){
            this.loadingCSV = true
            const locationsAmount = await this.getLocationsAmount()
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)
            
            let content = '"Provider";"Locations";"Locations with DC+50";"Locations with DC+100";"Locations with DC+150";"Locations with DC+300";"Most common countries";\r\n'
            
            for( const row of locationsAmount ){
                content += `"${row.country_code}-${row.party_id}";"${row.locations}";"${row.locations_with_dc50}";"${row.locations_with_dc100}";"${row.locations_with_dc150}";"${row.locations_with_dc300}";"${row.countries_list.join(", ")}";\r\n`
            }

            this.downloadBlob(content, `locationsAmountByProvider.csv`, "text/csv")
            this.tableLoading = false
        }
    }
};
</script>
