import { createStore } from 'vuex' 
import api from '../axios.instance'
import router from '../router'

import BackofficeWebsocket from '@/plugins/BackofficeWebsocket'

var alertTimeOut = null

const store = createStore({
    state: {
        token: localStorage.getItem('token') || '',
        user: {},
        navigator: (window.innerWidth < 1264) ? false : true,
        snackbar: { show: false },
        alert: { show: false },
        alerts: 0
    },
    mutations: {
        async auth_success(state, {token, user}) {
            state.token = token
            state.user = user
            await BackofficeWebsocket.connect(user)
        },
        async auth_guest(state) {
            await BackofficeWebsocket.disconnect()
            state.token = ''
            state.user = {}
        },
        toggle_navigator(state){
            state.navigator = !state.navigator
        },
        snackbar_show(state, {text, type, timeout}){

            let {color} = colorIconMessage(type)

            state.snackbar.text = text
            state.snackbar.color = color
            state.snackbar.timeout = timeout || 6000
            state.snackbar.show = true
            
        },
        snackbar_hide(state){
            state.snackbar = { show: false }
        },
        alert_show(state, {text, type, timeout, button}){

            state.alert.text = text
            state.alert.type = type
            state.alert.show = true
            state.alert.button = button

        },
        alert_hide(state){
            state.alert = { show: false }
        },
        async ws_connected(state){
            state.isWsConnected = true
    
            //Obtenemos el estado actual de OCPI
            try {
    
                const resp = await api.get('state')
                state.alerts = resp.data.alerts
    
            } catch(e) {
                console.error(e.message)
                console.error(e)
            }
    
        },
        set_alerts(state, alerts){
            state.alerts = alerts
        }
    },
    actions: {
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                api.post('/user/login', credentials)
                    .then(async resp => {

                        const token = resp.data.token
                        localStorage.setItem('token', token)
                        api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                        let user = await getUser()
                        if( user instanceof Error ){
                            commit('auth_guest')
                            localStorage.removeItem('token')
                            reject(err)
                        } else {
                            commit('auth_success', {token, user})
                            resolve(resp)
                        }

                    })
                    .catch(err => {
                        console.error('/login error', err.response)
                        commit('auth_guest')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
        
            commit('auth_guest')

            localStorage.removeItem('token')
            delete api.defaults.headers.common['Authorization']

            if( router.currentRoute.path != '/login' ){ router.push('/login') }
            
            return "success"

        },
        snackbar({ commit }, properties){
            commit('snackbar_show', properties)
        },
        snackbar_hide({ commit }){
            commit('snackbar_hide')
        },
        alert({ commit }, properties){
            
            commit('alert_hide')
            commit('alert_show', properties)
            alertTimeOut = setTimeout( _ => { commit('alert_hide') }, properties.timeout || 12000 )
        },
        alert_hide({ commit }){
            clearTimeout(alertTimeOut)
            commit('alert_hide')
        }
    },
    getters: {
        isLoggedIn: state => state.token ? true : false,
        user: state => state.user,
        navigator: state => state.navigator,
        isSnackbarActive: state => state.snackbar.show,
        isAlertActive: state => state.alert.show
    }
})

async function getUser() {
    try {
        let resp = await api.get('/user/info')
        return resp.data
    } catch(err) {
        console.error('Cannot get /user/info data', err.response)
        return new Error(err)
    }
}

function colorIconMessage(type){

    let color = ""
    let icon = ""

    switch(type){
        case 'error':
            color = "red darken-2"
            icon = "mdi-alert-octagon"
        break
        case 'success':
            color = "green darken-1"
            icon = "mdi-check"
        break
        case 'info':
            color = "light-blue darken-1"
            icon = "mdi-information-variant"
        break
        case 'warning':
            color = "orange darken-1"
            icon = "mdi-alert"
        break
        default:
            color = "grey darken-1"
            icon = ""
        break
    }

    return {color, icon}
}

export default store
