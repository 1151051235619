<template>
    <v-container v-if="provider">
        <AppBar :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: name, icon: 'mdi-earth', to: `/provider/${provider_id}` },
            { text: 'Tokens' }
        ]" />
        
        <!-- OCPI Tokens -->
        <div v-if="hasRole('EMSP')">
            <div class="d-flex align-center">
                <v-icon start color="accent">mdi-smart-card-outline</v-icon>             
                <h2>Tokens</h2>
            </div>
            <ProviderTokens :providerId="provider.id"></ProviderTokens>
        </div>

        <div v-if="hasRole('CPO')">
            <!-- Wenea tokens into provider -->
            <div class="d-flex align-center mb-2">
                <v-icon start color="accent">mdi-smart-card-outline</v-icon> 
                <h2>Wenea tokens into provider</h2>
            </div>
            <TokensIntoProvider :provider-id="provider.id"></TokensIntoProvider>

            <!-- Token test tool -->
            <WeneaTokenTool  class="mt-6" :provider_id="provider.id" />
        </div>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar";
import ProviderTokens from "@/components/provider/ProviderTokens";
import TokensIntoProvider from "@/components/provider/TokensIntoProvider";
import WeneaTokenTool from "@/components/provider/WeneaTokenTool";

export default {
    name: "provider-tokens",
    components: {
        AppBar,
        ProviderTokens,
        TokensIntoProvider,
        WeneaTokenTool
    },
    inject: ['$api'],
    data() {
        return {
            url: null,
            provider: null,
        }
    },
    computed: {
        name() {
            return this.provider
                ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
                : "-"
        },
        provider_id() {
            return this.provider ? this.provider.id : 0
        },
    },
    mounted() {
        this.url = this.$route.params.role.toUpperCase()
        this.loadData()
    },
    methods: {
        async loadData() {
            let id = this.$route.params.id
            if (!id) {
                return
            }

            let provider = await this.$api
                .get(`/provider/${id}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            if (!provider) {
                this.showError(`Provider #${id} not found`)
                return
            }

            this.provider = provider
            if (!this.hasRole('EMSP') && !this.hasRole('CPO')) {
                this.$router.push(`/provider/${this.provider.id}`)
            }
        },
        hasRole(role) {
            return this.provider && this.provider.role && this.provider.role.includes(role) && (this.url == role)
        }
    }
}
</script>