<template>
    <div>

        <v-row>
            <v-col md="8">
                <v-text-field 
                    v-model="evseid" 
                    class="accent"
                    placeholder="ES*WEN*E123456*1" 
                    label="evse_id string"
                    variant="outlined"
                    density="compact"
                />
            </v-col>
            <v-col>
                <v-btn 
                    color="accent"
                    style="height: 40px;"
                    @click="parse" 
                >
                    Parse
                </v-btn>
            </v-col>
        </v-row>


        <json-viewer v-if="json"
            class="pt-6 px-4 text-left"
            :value="json"
            :expand-depth="10"
        >
        </json-viewer>

    </div>
</template>

<script>
export default {
    inject: ['$api'],
    data() {
        return {
            evseid: null,
            json: null
        }
    },
    methods: {
        async parse() {

            this.json = await this.$api.post(`/tools/parse-evse-id`, { evseid: this.evseid })
                .then(resp => resp.data)
                .catch(this.showError)

        }
    }
}
</script>