<template>
  <v-container fluid>
    <AppBar 
            :breadcrumbs="[
                { text: 'Billing tools', to: '/tools', icon: 'mdi-tools' },
                { text: 'Provider connectors without tariffs' },
            ]"
        />
    
    <v-data-iterator
      :items="connGroupedByLocFiltered"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          :tile="false" 
          class="mb-2"
          color="accent"
          dark
          flat
          rounded
          extension-height="0"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <template v-slot:extension>
            <v-progress-linear v-if="loading" class="ma-0" color="accent" height="5" indeterminate></v-progress-linear> 
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="{ items }">
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
        >
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
            >
            <v-card v-bind="item">
              <v-card-title 
                class="subheading font-weight-bold pointer" 
                @click.prevent="goCtrlClick($event,item.raw[0].location_id,`location`)"
                >
                {{ item.raw[0].location_name }}
              </v-card-title>

              <v-divider></v-divider>

              <v-data-table
                :headers="[
                  {title: 'connector', key: 'connector' }
                ]"
                :items="item.raw"
                :group-by="[{ key: 'charge_point_id', order: 'asc' }]"
                show-group-by
                hide-default-footer
                hide-default-header
              >
                <template v-slot:group-header="{ columns, item, toggleGroup, isGroupOpen }">
                    <tr>
                    <td :colspan="columns.length" class="px-4 py-2 pointer bg-grey-lighten-3">
                        <v-row class="d-flex align-center">
                            <VBtn
                                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                                size="small"
                                variant="text"
                                @click="toggleGroup(item)"
                            ></VBtn>
                            <div @click.prevent="goCtrlClick($event,item,`chargepoint`)">
                                <v-icon size="large">mdi-ev-station</v-icon>
                                <span class="mx-5 font-weight-bold">{{ item.items[0].raw.charge_point_name }}</span>
                            </div>
                        </v-row>
                    </td>
                </tr>
                </template>
                <template v-slot:item.connector="{ item }">
                  <v-row class="ml-0">
                    <v-icon size="large">mdi-power-plug</v-icon>
                    <v-chip size="small" class="ml-2">{{ item.connector_id }}</v-chip>
                    <span v-if="item.connector_power_type" class="text-caption ml-2 pt-1">{{ item.connector_power_type }}</span>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-list-item>
        
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-2 mx-0"
          align="center"
          justify="center"
        >
          
          <v-spacer></v-spacer>

          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                color="accent"
                class="ml-1"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <span
            class="mx-4
            grey--text"
          >
            {{ offset }} - {{ limit }} of {{ connGroupedByLocFiltered.length }}
          </span>
          <v-btn
            icon
            variant="text"
            color="accent"
            class="mr-1"
            :disabled="!hasPreviousPage"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            variant="text"
            color="accent"
            class="ml-1"
            :disabled="!hasNextPage"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
    
  </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    components: {
      AppBar
    },
    data() {
      return {
          loading: false,
          search: '',
          filter: {},
          sortDesc: false,
          page: 1,
          itemsPerPage: 8,
          itemsPerPageArray: [4, 8, 12],
          connGroupedByLoc: []
      }
    },
    inject: ['$api'],
    computed: {
        connGroupedByLocFiltered() {
            const search = this.search == null || this.search == "" ? "" : this.search.toString().toLowerCase()
            return this.connGroupedByLoc.filter(conn => this.hasValue(conn, search))
        },
        numberOfPages() {
          return Math.ceil(this.connGroupedByLocFiltered.length / this.itemsPerPage)
        },
        offset() {
            return ((this.page - 1) * this.itemsPerPage) + 1
        },
        limit() {
            return this.page == this.numberOfPages ? ((this.page - 1) * this.itemsPerPage) + (this.connGroupedByLocFiltered.length - ((this.page - 1) * this.itemsPerPage))  : ((this.page - 1) * this.itemsPerPage) + this.itemsPerPage
        },
        hasNextPage() {
          return this.page + 1 <= this.numberOfPages
        },
        hasPreviousPage() {
          return this.page - 1 >= 1
        },
    },
    async mounted(){  
        this.loadProvidersWithoutTariffs()      
    },
    methods: {
        loadProvidersWithoutTariffs(){
            const id = this.$route.params.id
            if (!id) {
                return
            }

            this.loading = true

            this.$api.get(`/providers/${id}/connectors-without-tariffs`)
                .then(resp => { 

                  const connGroupedByLoc = resp.data.reduce((locations, item) => ({
                    ...locations,
                    [item.location_id]: [...(locations[item.location_id] || []), item]
                  }), {})

                  this.connGroupedByLoc = Array.from(Object.values(connGroupedByLoc))

                })
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        hasValue(object, value){
          return Object.keys(object).some(j => {
              if(object[j] == null || object[j] == undefined){
                return false 
              }
              if (object[j] instanceof Object) {
                return this.hasValue(object[j], value)
              }
              return object[j].toString().toLowerCase().includes(value)
          })
        },
        nextPage () {
          if (this.hasNextPage) this.page += 1
        },
        formerPage () {
          if (this.hasPreviousPage) this.page -= 1
        },
        updateItemsPerPage (number) {
          this.itemsPerPage = number
        }
    }
}
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }

  :deep(.v-table__wrapper){
    overflow: hidden;
  }
</style>