<template>
    <v-chip
        :class="{'active-status': status == 'ACTIVE'}"
        :color="color"
        size="small"
        variant="outlined"
        style="width: 110px; place-content: center;"
    >
        <span>{{ status }}</span>
    </v-chip>
</template>

<script>
export default {
    name: "status-chip",
    props: {
        status: {
            type: String
        }
    },
    computed: {
        color() {
            switch (this.status.toLowerCase()) {
                case "completed":
                    return "success";
                case "invalid":
                    return "error";
                case "active":
                    return "info";
                case "pending":
                    return "warning"
                case "reservation":
                    return "purple"
                default:
                    return "";
            }
        }
    }
}
</script>

<style scoped>
    .active-status * {
        animation: reverse 2s infinite 100ms;
    }

    @keyframes reverse {
        0% {opacity: 1;}
        50% {opacity: 0;}
        100% {opacity: 1;}
    }
</style>