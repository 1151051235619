// This is a custom WebSocket plugin for Vue 3 that integrates with AdonisJS WebSocket.
// Custom version of Vue 2 WebSocket plugin for AdonisJS. adonis-vue-websocket

class WsPlugin{
    constructor({adonisWS = null} = {}) {
        
        this.adonisWS = adonisWS
        this.connected = false
        this.socket = null
        this.onOpen = () => { }
    }
    // Register a custom callback as meddler that gets called upon each event emission.
    // It can be bound to $on as well. 
    $onAny(callback) {
        this.meddler = callback
    }
    $emitToServer(subscriptionTopic, eventName, data = {}) {
        if(!this.socket){
            throw new Error('Socket is not connected')
        }
        if(!this.socket.ws){
            throw new Error('Socket is not ready, please check your internet connection')
        }
        // console.log('emitToServer subscriptionTopic:', subscriptionTopic)
        let subscription = this.socket.getSubscription(subscriptionTopic)
        if (!subscription) {
            subscription = this.socket.subscribe(subscriptionTopic);
        }
        subscription.emit(eventName, data)
    }
    connect({ wsDomain, jwtToken = null } = {}, options = {}) {
        if (!this.adonisWS || this.adonisWS === undefined) {
            throw new Error('Please define adonisWs in app.use(WsPlugin, { adonisWS: Ws })')
        }
        if (this.connected) {
            throw new Error('Socket is already connected')
        }
        const ws = new this.adonisWS(wsDomain, options)
        if (jwtToken) {
            ws.withJwtToken(jwtToken)
        }
        this.socket = ws.connect()
        this.socket.on('open', () => {
            this.connected = true
            this.onOpen()
        })

        this.socket.on('close', async () => {
            this.connected = false
        })

        this.socket.on('error', () => {
            this.connected = false
        })
        return this
    }
    subscribe(topic) {
        const subscription = this.socket.subscribe(topic)
        subscription.on("close", () => {
            console.log(`Subscription to ${topic} closed`)
        })
        return subscription
    }
    disconnect() {
        try {
            if (this.socket){
                this.socket.close()
                this.socket = null
            }
            this.connected = false

        } catch (e) {
            console.error('AdonisVue3Ws', e.message);
        }
    }
    onOpen(cb = () => { }) {
        this.onOpen = cb
    }
}

let wsInstance
export default {
    install(app, opt) {
        const wsPlugin = new WsPlugin(opt)
        app.config.globalProperties.$ws = wsPlugin
        app.provide('ws', wsPlugin)
        wsInstance = wsPlugin

    }
}

// Export the function to get the WebSocket instance
export function getWsInstance() {
    return wsInstance
}