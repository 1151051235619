<template>
    <v-container fluid>

        <AppBar v-if="provider" :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: name, icon: 'mdi-earth', to: `/provider/${provider_id}` },
            { text: 'HTTP Logs' }
        ]"/>

        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>

        <!-- Provider Info -->
        <v-card variant="outlined" class="mb-3">
            <v-card-text class="pa-0">
                <v-row v-if="provider">
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            <v-list-item-title>{{ provider.country_code }} - {{ provider.party_id }}</v-list-item-title>
                            <v-list-item-subtitle>Country - Party</v-list-item-subtitle>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            <v-list-item-title>{{ provider.user.username || "-" }}</v-list-item-title>
                            <v-list-item-subtitle>Username</v-list-item-subtitle>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            <v-list-item-title>{{ provider.user.ocpi_version || "-" }}</v-list-item-title>
                            <v-list-item-subtitle>OCPI version</v-list-item-subtitle>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            <v-list-item-title>{{ provider.role || "-" }}</v-list-item-title>
                            <v-list-item-subtitle >Roles</v-list-item-subtitle>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-tabs 
            fixed-tabs
            color="accent"
            v-model="numericTab"
        >
            <v-tab style="max-width: none;">
                IN / OUT
            </v-tab>
            <v-tab style="max-width: none;" v-if="requestsRta.length > 0">
                RTA
            </v-tab>
            <v-tab style="max-width: none;" v-if="requestsCommands.length > 0">
                COMMANDS
            </v-tab>
        </v-tabs>

        <v-tabs-window v-model="numericTab">
            <!-- IN / OUT -->
            <v-tabs-window-item class="pt-4">
                <v-row fluid>
                    <v-col>
                        <HttpCallList title="Out" :http_items="requestsOut" />
                    </v-col>

                    <v-col>
                        <HttpCallList title="In" :http_items="requestsIn" incoming />
                    </v-col>
                </v-row>

                <div class="d-flex justify-center mt-2 mb-2">
                    <v-btn 
                        v-if="requestsIn.length >= limit || requestsOut.length >= limit" 
                        @click="loadMoreEvents" 
                        variant="flat" 
                        center
                        prepend-icon="mdi-refresh"
                    >
                        More Events
                    </v-btn>
                </div>

            </v-tabs-window-item>

            <!-- RTA -->
            <v-tabs-window-item class="pt-4" v-if="requestsRta.length > 0">
                <HttpCallList title="Real-time authorization" :http_items="requestsRta" />
                
                <div class="d-flex justify-center mt-2 mb-2">
                    <v-btn 
                        v-if="requestsRta.length >= limit" 
                        @click="loadMoreEvents" 
                        variant="flat" 
                        center
                        prepend-icon="mdi-refresh"
                    >
                        More Events
                    </v-btn>
                </div>

            </v-tabs-window-item>

            <!-- COMMANDS -->
            <v-tabs-window-item class="pt-4" v-if="requestsCommands.length > 0">
                <HttpCallList title="Commands" :http_items="requestsCommands" incoming />
                
                <div class="d-flex justify-center mt-2 mb-2">
                    <v-btn 
                        v-if="requestsRta.length >= limit" 
                        @click="loadMoreEvents" 
                        variant="flat" 
                        center
                        prepend-icon="mdi-refresh"
                    >
                        More Events
                    </v-btn>
                </div>
                
            </v-tabs-window-item>
        </v-tabs-window>     

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import HttpCallList from "@/components/http/HttpCallList"
import Bws from "@/plugins/BackofficeWebsocket.js"

export default {
    name: "http-logs",
    components: {
        AppBar,
        HttpCallList
    },
    inject: ['$api'],
    data() {
        return {
            provider: null,
            loading: true,
            requestsIn: [],
            requestsOut: [],
            requestsRta: [],
            requestsCommands: [],
            numericTab: 0,
            tabNames: ['in/out', 'out:rta', 'in:commands'],
            limit: 29,
            offset: 0,
        };
    },
    watch: {
        numericTab() {
            this.offset = 0
            this.limit = 29
            this.logsSelector()
        }
    },
    computed: {
        name() {
            return this.provider
                ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
                : "-"
        },
        provider_id(){
            return this.provider ? this.provider.id : ''
        },
        selectedTab: {
            get() {
                return this.tabNames[this.numericTab]
            },
            set(value) {
                this.numericTab = this.tabNames.indexOf(value)
            }
        }
    },
    async mounted() {

        let id = this.$route.params.id

        // Obtenemos Provider
        this.provider = await this.$api
            .get(`/provider/${id}`)
            .then((resp) => resp.data)
            .catch(this.showError)

        if( !this.provider ){
            return
        }

        this.getLogs("in")
        this.getLogs("out")
        
       // Nos suscribimos a Redis para nuevos logs
        this.httpLogsChannel = Bws.getSubscription(`http-logs:provider-${id}`)
        
        this.httpLogsChannel.on("in", (data) => {
            this.requestsIn.unshift(data)
            this.requestsIn = this.requestsIn.slice(0, this.limit)
        })
        this.httpLogsChannel.on("out", (data) => {
            this.requestsOut.unshift(data)
            this.requestsOut = this.requestsOut.slice(0, this.limit)
        })

        this.loading = false

    },
    methods: {
        async getLogs(from) {
            const req = await this.$api
                .get(`/provider/${this.provider.id}/last-http-logs/${from}?limit=${this.limit}&offset=${this.offset}`)
                .then(resp => resp.data)
                .catch(this.showError)

            if (from === "in") {
                this.requestsIn.push(...req)
            } else if (from === "out") {
                this.requestsOut.push(...req)
            } else if (from === "out:rta") {
                this.requestsRta.push(...req)
            } else if (from === "in:commands") {
                this.requestsCommands.push(...req)
            }
        },

        logsSelector() {
            if (this.selectedTab === "in/out") {
                this.getLogs("in")
                this.getLogs("out")
            } else {
                this.getLogs(this.selectedTab)
            }
        },

        loadMoreEvents() {
                this.offset += 29
                this.limit += 29
                this.logsSelector()
        },
    },
    destroyed() {
        if (this.httpLogsChannel) {
            this.httpLogsChannel.close()
            this.httpLogsChannel = null
            console.log("httpLogs channel closed")
        }
    }
};
</script>
