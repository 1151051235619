<template>

  <v-container fluid class="pt-0">

    <AppBar title="Cdrs" icon="mdi-credit-card-outline" />

    <Cdrs/>

  </v-container>
  
</template>

<script>
import AppBar from "@/components/AppBar"
import Cdrs from "@/components/cdr/Cdrs"

export default {
    components: {
        AppBar,
        Cdrs
    }
}
</script>