<template>
    <v-container fluid>
        <AppBar title="Failured requests" icon="mdi-alert-octagon" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="tableSearch"
                    :searchFunction="onFilter"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    class="filter-field"
                    v-model="selectedType"
                    :items="types"
                    label="Type"
                    color="accent"
                    clearable
                    variant="outlined"
                    hide-details
                >
                    <template v-slot:selection="{ item }">
                        <v-chip class="text-uppercase" variant="outlined" small>{{item.raw}}</v-chip>
                    </template>
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <span class="text-uppercase">{{ item.raw }}</span>
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    class="filter-field"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Providers"
                    color="accent"
                    clearable
                    variant="outlined"
                    hide-details
                >
                </v-select>
            </v-col>
            
            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"  
                    hide-details 
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field"
                            v-model="selectedDateFrom"
                            label="Date from"
                            prepend-inner-icon="mdi-calendar"
                            color="accent"
                            readonly
                            clearable
                            variant="outlined"
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFromPicker"
                        @update:modelValue="dateFromPickerSelected"
                        no-title
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateToPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field"
                            v-model="selectedDateTo"
                            label="Date to"
                            prepend-inner-icon="mdi-calendar"
                            color="accent"
                            readonly
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateToPicker"
                        @update:modelValue="dateToPickerSelected"
                        no-title
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <SearchButton
                :loading="loading"
                :searchFunction="onFilter"
            />

        </v-row>
        
        <!-- Data table -->
        <v-data-table
            v-model:expanded="expanded"
            class="accent-table rounded-table"
            :items="filteredFailuredRequests"
            item-value="key"
            :headers="[
                { title: 'Type', key: 'type' },
                { title: 'Provider', key: 'provider', sortable: false },
                { title: 'Created at', key: 'timestamp' },
                { title: 'Actions', key: 'actions', sortable: false },
            ]"
            density="compact"
            :search="searchFilter"
            :custom-filter="dataTableDeepSearch"
            :loading="loading"        
            :sort-by="[{ key: 'timestamp', order: 'asc' }]"
            show-expand
        >
            <template v-slot:item.type="{ item }">
                <v-chip class="text-uppercase" variant="outlined" size="small">{{item.type}}</v-chip>
            </template>
            <template v-slot:item.timestamp="{ item }">
                {{ dateFullFormat(item.timestamp)  }}
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    v-if="!!item.user.provider_name"
                    :provider="{ id: item.user.provider_id, country_code: item.user.provider_name.split(' ')[0] }"
                />
            </template>
            <template v-slot:item.actions="{ item }">
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:expanded-row="{ columns, item }">
                <td :colspan="columns.length">
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span class="pl-2 py-2 text-overline">Request</span>
                            <json-viewer class="pa-0"
                                :value="item.request"
                                :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                                :expand-depth="1"
                                >
                            </json-viewer>
                        </v-col>
                        <v-divider vertical class="my-3"></v-divider>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span class="pl-2 py-2 text-overline">Response</span>
                            <json-viewer class="pa-0"
                                :value="item.response"
                                :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                                :expand-depth="1"
                                >
                            </json-viewer>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text" text @click="closeDelete">Cancel</v-btn>
                <v-btn class="mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AppBar from '@/components/AppBar'
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        AppBar,
        SearchTextField,
        SearchButton,
        ProviderName
    },
    inject: ['$api'],
    data() {
        return {
            loading: false,
            dialogDelete: false,
            tableSearch: null,
            searchFilter: null,
            expanded: [],
            failuredRequests: [],
            selectedfailuredRequest: {
                key: null,
                type: null
            },
            selectedType: null,
            selectedProvider: null,
            selectedDateFrom: null,
            selectedDateFromPicker: null,
            showDateFromPicker: false,
            selectedDateTo: null,
            selectedDateToPicker: null,
            showDateToPicker: false,
        }
    },
    watch:{
        selectedDateFrom(val) {
            if (val && val > this.selectedDateTo) {
                this.selectedDateTo = new Date(val)
            }
        },
        selectedDateTo(val) {
            if (val && val < this.selectedDateFrom) {
                this.selectedDateFrom = new Date(val)
            }
        },
        dialogDelete (val) {
        val || this.closeDelete()
        },
    },
    computed: {
        filteredFailuredRequests() {
            let filteredFailuredRequests = this.failuredRequests

            if (this.selectedType){
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.type == this.selectedType)
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.type == this.selectedType)
            }

            if (this.selectedProvider){
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.user.provider_id == this.selectedProvider)
            }

            if (this.selectedDateFrom){ 
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.timestamp >= this.selectedDateFrom)
            }

            if (this.selectedDateToPicker){  
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.timestamp <= this.selectedDateTo)
            }

            return filteredFailuredRequests
        },
        types() {
            const types = this.failuredRequests.map(input => input.type)
            return [ ...new Set(types) ]
        },
        providers() {
            const providers = this.failuredRequests.reduce((acc, input) => {
                const provider = { value: input.user.provider_id, title: input.user.provider_name?.split(' ')[0] }
                if (!acc.some(x => x.value === provider.value)) {
                    acc.push(provider)
                }
                return acc
            }, [])
            return providers
        },
    },
    mounted(){
        this.loadFailuredRequests()
    },
    methods: {
        loadFailuredRequests() {
            this.loading = true
            this.$api.get('failured-requests')
                .then(res => { this.failuredRequests = res.data })
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        deleteItem(item) {
            this.selectedfailuredRequest = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm () {
            if(this.selectedfailuredRequest.type != "undefined"){
                //Api delete failured-requests/{id}?type={type} if type is not undefined string
                this.$api.delete(`failured-requests/${this.selectedfailuredRequest.key}?type=${this.selectedfailuredRequest.type}`)
                    .then(res => {
                        this.loadFailuredRequests()
                        this.closeDelete()
                    })
                    .catch(this.showError)
            }
            else{
                this.$api.delete(`failured-requests/${this.selectedfailuredRequest.key}`)
                    .then(res => {
                        this.loadFailuredRequests()
                    })
                    .catch(this.showError)
            }
            this.closeDelete()
        },
        closeDelete () {
            this.dialogDelete = false
        },
        onFilter(){
            this.searchFilter = this.tableSearch
        },
        dateFromPickerSelected(){
            this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
            this.showDateFromPicker = false
        },
        dateToPickerSelected(){
            this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
            this.showDateToPicker = false
        },
    }
};
</script>

<style scoped>
    :deep(.v-data-table)>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
</style>
