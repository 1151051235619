<template>
    <v-container fluid>

        <AppBar title="Billing Dashboard" icon="mdi-view-dashboard" />

        <v-row class="pl-2 pt-2 pb-0" align='center'>
            <v-btn variant="text" color="accent" @click="setFilters">
                <v-icon start>mdi-filter</v-icon>Filters
            </v-btn>

            <span class="pl-2 pr-2">
                Invoices 
                <template v-if="selectedProvider">
                    {{ providerPartyCountry ? `of provider ${providerPartyCountry}` : `of provider #${selectedProvider}` }}
                </template>
                <template v-if="selectedMonth">
                    created in {{ monthFormat(selectedMonth) }} 
                </template>
                <template v-if="selectedDateFrom && !selectedDateTo">
                    created after {{ dateFormat(selectedDateFrom) }} 
                </template>
                <template v-if="selectedDateFrom && selectedDateTo">
                    created between {{ dateFormat(selectedDateFrom) }} and {{ dateFormat(selectedDateTo) }}
                </template>
            </span>

        </v-row>

        <v-row>
            <v-col cols=12 md=8>
                <v-card class="accent-progress-bar" height="100%" :loading="invoicesLoading">
                    <v-card-title class="d-flex align-center">     
                        <v-icon color="accent" start>mdi-chart-timeline-variant</v-icon>
                        <h3 class="black--text">
                            Invoice Quantity
                        </h3>
                    </v-card-title>

                    <v-card-text>
                        <InvoiceQuantityGraph :invoices="invoices" />
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols=12 md=4>
                <v-card height="100%">
                    <v-card-title class="d-flex align-center">     
                        <v-icon color="accent" start>mdi-calculator-variant-outline</v-icon>
                        <h3 class="black--text">
                            Charge session price calculator
                        </h3>
                    </v-card-title>

                    <v-card-text class="d-flex align-center" height="100%">
                        <ChargeSessionPriceCalculator class="not-outlined"></ChargeSessionPriceCalculator>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols=12>
                <v-card class="accent-progress-bar" height="100%" :loading="invoicesLoading">
                    <v-card-title class="d-flex align-center">     
                        <v-icon color="accent" start>mdi-receipt-text-check-outline</v-icon>
                        <h3 class="black--text">
                            Invoices
                        </h3>
                    </v-card-title>

                    <v-card-text>
                        <InvoiceSummaryTable :invoices="invoices" @seeAllClicked="seeAllInvoices" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      
        <FiltersDialog />
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import { EventBus } from "@/event-bus"
import ChargeSessionPriceCalculator from "@/components/tools/ChargeSessionPriceCalculator.vue"
import InvoiceQuantityGraph from "@/components/billing-dashboard/InvoiceQuantityGraph.vue"
import InvoiceSummaryTable from "@/components/billing-dashboard/InvoiceSummaryTable.vue"
import FiltersDialog from "@/components/billing-dashboard/FiltersDialog.vue"

export default {
    name: "wenea",
    components: {
        AppBar,
        ChargeSessionPriceCalculator,
        InvoiceQuantityGraph,
        InvoiceSummaryTable,
        FiltersDialog
    },
    inject:['$api'],
    data() {
       return {
            invoicesLoading: false,
            invoices: [],
            selectedProvider: null,
            selectedMonth: null,
            selectedDateFrom: null,
            selectedDateTo: null,
            selectedFilterBy: null,
       }
    },
    async created() {
        var vm = this

        EventBus().on("dialog-filters:filters-applied", async function (args) {
            vm.selectedProvider = args.selectedProvider
            vm.selectedMonth = args.selectedMonth
            vm.selectedDateFrom = args.selectedDateFrom
            vm.selectedDateTo = args.selectedDateTo
            vm.selectedFilterBy = args.selectedFilterBy, 
            
            vm.loadInvoices()
        })
    },
    async mounted() {
        this.selectedMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().substr(0, 7)
        this.selectedFilterBy = 'month'

        this.loadInvoices()
    },
    computed: {
        providerPartyCountry(){
            if (!this.selectedProvider){ return null }

            if (this.invoices?.length > 0){
                return `${this.invoices[0].receiverProvider.country_code }-${this.invoices[0].item.receiverProvider.party_id}`
            }
        }
    },
    methods: {
        async loadInvoices(){
            this.invoicesLoading = true

            let queryParams = `filter[sender_provider_id]=1`
            if (this.selectedProvider) { queryParams += `&filter[receiver_provider_id]=${this.selectedProvider}` }

            if (this.selectedDateFrom) { queryParams += `&start_date=${this.dateFormat(this.selectedDateFrom)} 00:00:00` }
            if (this.selectedDateTo) { queryParams += `&end_date=${this.dateFormat(this.selectedDateTo)} 23:59:59` }
            
            if (this.selectedMonth) { 
                const lastDayOfMonth = new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 0)
                const stringLastDayOfMonth = `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth()+1}-${lastDayOfMonth.getDate()} 23:59:59`
                queryParams += `&start_date=${this.dateFormat(this.selectedMonth)} 00:00:00&end_date=${stringLastDayOfMonth}`
            }

            this.invoices = await this.$api.get(`sessions?${queryParams}`)
                .then(resp => {
                    if( Array.isArray(resp.data) ){
                        resp.data.map(invoice => 
                            {
                                invoice.id,
                                invoice.start_date_time,
                                invoice.end_date_time,

                                invoice.location_id,
                                invoice.location_name = invoice.location.name,
                                invoice.chargepoint_id = invoice.evse.chargepoint.id,
                                invoice.chargepoint_name = invoice.evse.chargepoint.reference_name,
                                invoice.connector_id,

                                invoice.cdr_id = invoice.cdr?.id, 
                                invoice.kwh,
                                invoice.total_cost = invoice.total_cost?.excl_vat,
                                
                                invoice.dtm_session_id,
                                invoice.backend_uuid,
                                invoice.ocpi_id
                        })   
                    }
                    return resp.data
                })
                .catch(this.showError)
                .finally(() => this.invoicesLoading = false)
        },
        setFilters() {           
            EventBus().emit("dialog-filters:open", { 
                selectedProvider: this.selectedProvider,         
                selectedMonth: this.selectedMonth,
                selectedDateFrom: this.selectedDateFrom,
                selectedDateTo: this.selectedDateTo,
                selectedFilterBy: this.selectedFilterBy, 
            })
        },
        seeAllInvoices(){
            let queryParams = `?filterBy=${this.selectedFilterBy}`
            if (this.selectedProvider){ queryParams += `&provider=${this.selectedProvider}`}
            if (this.selectedMonth){ queryParams += `&month=${this.selectedMonth}`}
            if (this.selectedDateFrom){ queryParams += `&dateFrom=${this.selectedDateFrom}`}
            if (this.selectedDateTo){ queryParams += `&dateTo=${this.selectedDateTo}`}

            this.goTo(`/invoices${queryParams}`)
        }
    }
};
</script>

<style scoped>
    .not-outlined {
        border: none !important;
    }

    :deep(.v-card){
        height: 100%;
    }

    :deep(.v-data-table__wrapper){
        margin-bottom: 59px;
    }

    :deep(.v-data-table__empty-wrapper)td{
        position: absolute;
        text-align: center;
        padding-left: 0 !important;
        padding-right: 32px !important;
        width: 100%;
        top: 50%;
    }

    :deep(.v-data-footer){
        position: absolute;
        border-radius: inherit;
        bottom: 0;
        left: 0;
        right: 0;
    }

    :deep(.prepend-footer-content){
        position: absolute;
        z-index: 1;
        bottom: 0;
    }
</style>