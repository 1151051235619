<template>
    <v-container fluid>

        <AppBar title="Invoices" icon="mdi-receipt-text-check-outline" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedCountry"
                    :items="countries"
                    label="Country"
                    variant="outlined"
                    clearable
                    hide-details
                />
            </v-col>

            <v-col cols="12" md="2" v-if="selectedFilterBy == 'month'">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showMonthPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            v-model="selectedMonth"
                            class="filter-field accent"
                            label="Month"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            variant="outlined"
                            hide-details
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedMonthPicker"
                        @update:modelValue="updatePickerSelected"
                        header-color="accent"
                        view-mode="months"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2" v-if="selectedFilterBy == 'day'">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateFrom"
                            label="Date From"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            hide-details
                            variant="outlined"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFromPicker"
                        @update:modelValue="updateFromPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2" v-if="selectedFilterBy == 'day'">
                <v-menu
                    ref="menu"
                    class="filter-field"
                    v-model="showDateToPicker"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-bind="props"
                            class="filter-field accent"
                            v-model="selectedDateTo"
                            label="Date To"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            variant="outlined"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateToPicker"
                        @update:modelValue="updateToPickerSelected"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2" class="no-flex">
                <v-btn-toggle
                    v-model="selectedFilterBy"
                    mandatory
                    variant="outlined"
                    density="compact"
                >
                    <v-btn class="min-with-80" value="month" color="accent"> Month </v-btn>
                    <v-btn class="min-with-80" value="day" color="accent"> Day </v-btn>
                </v-btn-toggle>
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />

             <v-spacer />

            <v-btn 
                color="accent"
                :loading="loadingCSV"
                variant="outlined" 
                @click="createCSV"
            >
                <v-icon start>mdi-file-download-outline</v-icon> CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table-server
            v-if="cdr_sessions"
            :headers="[
                { key: 'action', sortable:false, cellClass: 'td-linkto', maxWidth: 50 },   
                { title: 'Start', key: 'start_date_time', width: 110 },
                { title: 'End', key: 'end_date_time', width: 110 },
                { title: 'Provider', key: 'provider' },
                { title: 'Location', key: 'location' },
                { title: 'Charge point', key: 'chargepoint' },
                { title: 'Conn. Number', key: 'connector.number', width: 100 },
                { title: 'Conn. Max power', key: 'connector.max_electric_power', width: 100 },
                
                { title: 'Energy', key: 'kwh', width: 100 },
                { title: 'Tariff', key: 'tariff', width: 100 },
                { title: 'Cost', key: 'total_cost' },

                { title: 'DTM Id.', key: 'dtm_session_id' },
                { title: 'Core UID.', key: 'backend_uuid' },

            ]"
            :items="cdr_sessions"
            :items-per-page="tableOptions.itemsPerPage"
            :items-length="pagination.serverItemsLength"
            :loading="tableLoading"
            :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
            @update:options="this.updateTableOptions"
            disable-sort
            density="compact"
            class="accent-table rounded-table"
        >
            <template v-slot:item.action="{ item }">
                <v-btn variant="plain" size="small" :to="`/session/${item.id}`">
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.start_date_time="{ item }">
                {{ dateFullFormat(item.start_date_time)  }}
            </template>
            <template v-slot:item.end_date_time="{ item }">
                {{ dateFullFormat(item.end_date_time) }}
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                  :provider="item.receiverProvider"
                  role="emsp"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.location"
                    :location="{ id: item.location_id, name: item.location.name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.connector.max_electric_power="{ item }">
                {{ item.connector.max_electric_power / 1000 }} kW
            </template>
            <template v-slot:item.kwh="{ item }">
                {{ item.kwh }} kWh
            </template>
            <template v-slot:item.tariff="{ item }">
                <v-btn v-if="item.cdr && item.cdr.tariff_id"
                    variant="text"
                    :to="`/tariffs/${item.cdr.tariff_id}`" 
                >
                    <v-icon left>mdi-tag-text-outline</v-icon> {{ item.cdr.tariff_id }}
                </v-btn>
            </template>
            <template v-slot:item.total_cost="{ item }">
                <strong>
                    <v-chip 
                        v-if="!item.end_date_time" 
                        class="text-uppercase px-1" size="small" label variant="outlined" color="accent"
                    >
                        In progress
                    </v-chip>
                    <v-chip 
                        v-if="(item.total_cost && item.end_date_time) || item.cdr"
                        class="text-uppercase px-1" size="small" label variant="outlined" color="accent"
                    >
                        {{ item.total_cost}} {{ item.cdr.currency }}
                    </v-chip>
                    <v-tooltip v-if="!item.total_cost && item.end_date_time && !item.cdr" bottom>
                        <template v-slot:activator="{ props }">
                            <v-icon 
                                v-bind="props"
                                :color="item.kwh <= 0.2 ? 'warning' : 'error'"
                            >
                                mdi-close-circle
                            </v-icon>
                        </template>
                        <span v-if="item.kwh <= 0.2">Less or equal than 0.2 kWh, it is correct to not have CDR</span>
                        <span v-else>CDR should have been created</span>
                    </v-tooltip>
                </strong>
            </template>

            <template v-slot:item.backend_uuid="{ item }">
                <v-btn variant="text" @click="clipboard(item.backend_uuid)">
                    <span style="width: 100px" class="text-truncate">{{ item.backend_uuid }}</span>
                </v-btn>
            </template>


        </v-data-table-server>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import Countries from "../../../shared/resources/WorldCountryCodes_EN.json"

export default {
    name: "wenea",
    components: {
        AppBar,
        SearchButton,
        ProviderName,
        LocationName,
        ChargepointName,
        ProviderSelectorTemplate
    },
    inject: ['$api'],
    data() {
        return {
            selectedProvider: undefined,
            selectedCountry: null,
            selectedMonth: null,
            selectedDateFrom: new Date().toISOString().substr(0, 10),
            selectedDateTo: new Date().toISOString().substr(0, 10),
            selectedMonthPicker: null,
            selectedDateFromPicker: null,
            selectedDateToPicker: null,
            selectedFilterBy: null,
            showMonthPicker: false,
            showDateFromPicker: false,
            showDateToPicker: false,
            tableLoading: false,
            providers: [],
            cdr_sessions: [],
            pagination: {
                itemsPerPageOptions: [25, 50, 100, -1],
                serverItemsLength: 0
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1
            },
            loadingCSV: false,
            initialLoad: true
        }
    },
    mounted(){
        this.load()
    },
    computed:{
        countries() { 
            return Countries.map(c => { 
                return { title: `${c.name} - ${c.alpha3}`, value: c.alpha3 } 
            }) 
        }
    },
    watch: {
        selectedFilterBy: function (newValue) {
            if(!newValue) { this.selectedFilterBy = null }

            if (newValue == 'day') {
                this.selectedMonth = null
                if (this.selectedDateFrom == null) {
                    this.selectedDateFrom = new Date().toISOString().substr(0, 10)
                    this.selectedDateTo = new Date().toISOString().substr(0, 10)
                }
            }

            if (newValue == 'month') {
                this.selectedDateFrom = null
                this.selectedDateTo = null
                if (this.selectedMonth == null) {
                    this.selectedMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString().substr(0, 7)
                }
            }
        },
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load() {
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        return { 
                            ...p, 
                            title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)
        },
        async loadData(){
            this.tableLoading = true

            this.getData(true)
                .then(resp => {
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                    this.cdr_sessions = resp.data
                })
                .catch(this.showError)
                .finally(() => {
                    this.tableLoading = false
                    this.initialLoad = false
                })
        },
        async getData(paginatedResponse){

            this.tableLoading = true

            let queryParams = ''

            if(paginatedResponse) { 
            queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
            if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }

            queryParams += '&orderBy[id]=desc'
            queryParams += `&filter[sender_provider_id]=1`
            if (this.selectedProvider) { queryParams += `&filter[receiver_provider_id]=${this.selectedProvider}` }
            if (this.selectedCountry) { queryParams += `&location_country=${this.selectedCountry}` }
            if (this.selectedDateFrom) { queryParams += `&start_date=${this.dateFormat(this.selectedDateFrom)} 00:00:00` }
            if (this.selectedDateTo) { queryParams += `&end_date=${this.dateFormat(this.selectedDateTo)} 23:59:59` }

            if (this.selectedMonth) { 
                const lastDayOfMonth = new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 0)
                const stringLastDayOfMonth = `${lastDayOfMonth.getFullYear()}-${lastDayOfMonth.getMonth()+1}-${lastDayOfMonth.getDate()} 23:59:59`
                queryParams += `&start_date=${this.dateFormat(this.selectedMonth)} 00:00:00&end_date=${stringLastDayOfMonth}`
            }

            return await this.$api.get(`sessions?${queryParams}`)
                .then(resp => {
                    if( Array.isArray(resp.data) ){
                        resp.data.map(invoice => 
                         {
                                invoice.id,
                                invoice.start_date_time,
                                invoice.end_date_time,

                                invoice.location_id,
                                invoice.location_name = invoice.location.name,
                                invoice.location_country = invoice.location.country,
                                invoice.chargepoint_id = invoice.evse.chargepoint.id,
                                invoice.chargepoint_name = invoice.evse.chargepoint.reference_name,
                                invoice.connector_id,

                                invoice.cdr_id = invoice.cdr?.id, 
                                invoice.kwh,
                                invoice.total_cost = invoice.total_cost?.excl_vat,
                                
                                invoice.dtm_session_id,
                                invoice.backend_uuid,
                                invoice.ocpi_id
                        })   
                    }
                    return resp
                })
                .catch(this.showError)


        },
        onFilter(){
            if(this.tableLoading) return

            if (this.selectedFilterBy == null) {
                this.selectedFilterBy = 'day'
                this.selectedMonth = null
            }else if(this.selectedFilterBy == 'month'){
                this.selectedDateFrom = null
                this.selectedDateTo = null
            }

            this.urlPush('provider', this.selectedProvider)
            this.urlPush('country', this.selectedCountry)
            this.urlPush('month', this.selectedMonth)
            this.urlPush('dateFrom', this.selectedDateFrom)
            this.urlPush('dateTo', this.selectedDateTo)
            this.urlPush('filterBy', this.selectedFilterBy)

            this.loadData()
        },
        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadData()
            }
        },
        updatePickerSelected() {
            this.selectedMonth = this.monthFormat(this.selectedMonthPicker.toISOString())
            this.showMonthPicker = false
        },

        updateFromPickerSelected() {
            this.selectedDateFrom = this.dateFormat(this.selectedDateFromPicker.toISOString())
            this.showDateFromPicker = false
        },

        updateToPickerSelected() {
            this.selectedDateTo = this.dateFormat(this.selectedDateToPicker.toISOString())
            this.showDateToPicker = false
        },
        async createCSV(){
            this.loadingCSV = true

            const cdr_sessions = await this.getData(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)   

            let content = ``
                + `"Session ID";`
                + `"Session Auth Ref.";`
                + `"Start Date";`
                + `"End Date";"Provider";`
                + `"Location ID";`
                + `"Location Name";`
                + `"Location Country";`
                + `"Evse UID";`
                + `"Evse ID";`
                + `"Energy (kWh)";`
                + `"Tariff ID";`
                + `"Total Cost (excl_vat)";`
                + `"Currency";`
                + `"CDR ID";`
                + `"CDR Received";`
                + `"Auth Method";`
                + `"Auth ID / Token UID";`
                + `"Connector number";`
                + `"Conn max power";`
                + `\n`
            
            for( const row of cdr_sessions ){
                content += ``
                    + `"${row.ocpi_id || ''}";`
                    + `"${row.authorization_reference || ''}";`
                    + `"${row.start_date_time}";`
                    + `"${row.end_date_time || ''}";`
                    + `"${row.receiverProvider?.country_code || ''}-${row.receiverProvider?.party_id || ''}";`
                    + `"${row.location?.ocpi_id || ''}";`
                    + `"${row.location_name || ''}";`
                    + `"${row.location_country || ''}";`
                    + `"${row.evse?.uid || ''}";`
                    + `"${row.evse?.evse_id || ''}";`
                    + `"${row.kwh || 0}";`
                    + `"${row.cdr?.tariffs[0]?.id || ''}";`
                    + `"${row.total_cost || ''}";`
                    + `"${row.cdr?.currency || ''}";`
                    + `"${row.cdr?.ocpi_id || ''}";`
                    + `"${row.cdr?.sent_to_provider || false}";`
                    + `"${row.auth_method || ''}";`
                    + `"${(row.cdr_token?.auth_id || row.cdr_token?.uid) || ''}";`
                    + `"${row.connector?.number || ''}";`
                    + `"${row.connector?.max_electric_power ? row.connector?.max_electric_power / 1000 + ' kW' : ''}";`
                    + `\n`
            }

            this.downloadBlob(content, `invoices.csv`, "text/csv")
            this.tableLoading = false
        }
    }
};
</script>

<style scoped>
    .min-with-80 {
        min-width: 80px!important;
    }
    .no-flex {
        flex: 0 !important;
    }
</style>