<template>
    <v-container fluid>

        <AppBar title="Out session queues" icon="mdi-tray-full" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="tableSearch"
                    :searchFunction="onFilter"
                />
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />
        </v-row>

        <!-- Data table -->
        <v-data-table
            class="accent-table rounded-table"
            :headers="[
                { title: 'Event type', key: 'event_type', align: 'center'  },
                { title: 'Key', key: 'key' , align: 'center' },
                { title: 'Fails', key: 'fails', align: 'center'  },
                { title: 'Errors', key: 'errors', align: 'center'  },
            ]"
            :items="queues"
            :search="searchFilter"
            :loading="tableLoading"               
            :group-by="[{ key: 'qname', order: 'asc' }]"
            :sort-by="[{ key: 'key', order: 'desc' }]"
            density="compact"
        >
            <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
                <tr>
                    <td :colspan="columns.length">
                        <v-btn @click="toggleGroup(item)" variant="flat">
                            <v-icon v-if="isGroupOpen(item)">mdi-minus</v-icon>
                            <v-icon v-else>mdi-plus</v-icon>
                        </v-btn>
                        <span class="ml-5 font-weight-bold">Session queue</span>
                        <v-btn class="ml-0 pl-1" variant="plain" size="small" :to="`/session/${item.value.split(':')[1]}`">
                            #{{ item.value.split(':')[1] }}
                            <v-icon size="small">mdi-arrow-top-right</v-icon>
                        </v-btn>

                        <v-btn class="float-right" 
                            @click="finishQueue(item.value.split(':')[1])" 
                            variant="plain" 
                            color="red"
                            :loading="finishBtnLoading"
                        >
                            <v-icon left>mdi-tray-remove</v-icon>
                            Finish queue
                        </v-btn>
                    </td>
                </tr>
            </template>

            <template v-slot:item.errors="{ item }">
                <json-viewer v-if="item.errors"
                    class="pa-2 text-left"
                    :value="item.errors"
                    :expand-depth="0"
                    >
                </json-viewer>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"

export default {
    name: "wenea",
    components: {
        AppBar,
        SearchTextField,
        SearchButton
    },
    inject: ['$api'],
    data() {
        return {
            queues: [],
            tableSearch: "",
            searchFilter: "",
            tableLoading: false,
            finishBtnLoading: false
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        load() {
            this.tableLoading = true
            this.$api.get("/out-session-queues")
                .then(res => { this.queues = res.data })
                .catch(this.showError)
                .then(() => this.tableLoading = false)
        },
        finishQueue(session_id){
            const confirmed = confirm('Are you sure to end queue for this session?')
            if(!confirmed){ return }
            
            this.finishBtnLoading = true
            this.$api.delete(`/out-session-queues/${session_id}`)
                .then(this.showSuccess('Queue has been finished'))
                .catch(this.showError)
                .then(() => { 
                    this.load()
                    this.finishBtnLoading = false
                })
        },
        onFilter(){
            this.searchFilter = this.tableSearch
        }
    }
};
</script>
