<template>

    <v-navigation-drawer
        v-if="$store.getters.isLoggedIn"
        v-model="$store.state.navigator"
        app
        hide-overlay
        class="accent-navigation-drawer"
    >
        <slot name="prepend"></slot>

        <v-list class="pt-0">
            <template v-for="(item, iIndex) of structure">                   
                <NavigationItem :item="item">
                    <template v-for="(index, name) in $slots" v-slot:[name]>
                        <slot :name="name" />
                    </template>
                </NavigationItem>
            </template>
        </v-list>

        <template v-slot:append>
            <slot name="append"></slot>
        </template>
    </v-navigation-drawer>

</template>

<script>
import NavigationItem from '@/components/navigation-drawer/NavigationItem'

export default {
    name: 'navigation-drawer',
    /**
     * @slot prepend - Content that will be displayed before navigation items
     */
    /**
     * @slot append - Content to be displayed after navigation items
     */
    props: {
        /**
         * List of NavigationItem objects
         * 
         * NavigationItem type definition
         * @param {string} [id] - Identifier used to identify to whom a slot belongs
         * @param {string} text - Text to be displayed in menu item
         * @param {string} icon - Icon to be displayed in menu item
         * @param {string} [link] - Link of page that redirects when pressing menu item
         * @param {boolean} [external] - Boolean used to determine if link is external
         * @param {string} [activeRegex] - RegExp to be used to check if the current path triggers the menu item
         * @param {string} [customClass] - Class or classes css to add to that menu item
         * @param {Array.<NavigationItem>} [childs] - Items to be displayed when hovering over parent menu item
         */
        structure: {
            type: Array,
            required: true
        }
    },
    components: {
        NavigationItem
    }
}
</script>