<template>
    <v-text-field
        variant="outlined"
        class="search-field accent"
        :placeholder="label ? label : 'Search'"
        :autofocus="autofocus"
        hide-details
        clearable
        @keyup.enter="searchFunction"
        prepend-inner-icon="mdi-magnify"
    >
    </v-text-field>
</template>

<script>
export default ({
    props: {
        label: String,
        searchFunction: {
            type: Function,
            default: () => {}
        },
        autofocus: {
            type: Boolean,
            default: true
        }
    },
})
</script>

