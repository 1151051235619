<template>
    <v-container fluid>
        <AppBar title="Providers" icon="mdi-earth" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <SearchTextField
                    v-model="selectedSearch"
                    :searchFunction="onFilter"
                />   
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedType"
                    variant="outlined"
                    class="filter-field accent"
                    :items="[
                        { title: 'Provider', value: 'provider' },
                        { title: 'Hub', value: 'hub' },
                    ]"
                    label="Type"
                    clearable
                    hide-details
                ></v-autocomplete>
                
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />
            
            <v-spacer/>

            <v-btn
                class="mr-2"
                to="/provider/create"
                color="accent"
                variant="outlined"
            >
                <v-icon start>mdi-plus</v-icon> Create
            </v-btn>

            <v-btn
                variant="outlined"
                href="https://wenea.atlassian.net/wiki/spaces/WIKI/pages/561774605/Providers" 
                target="_blank"
            ><v-icon start>mdi-open-in-new</v-icon> Confluence List</v-btn>
        </v-row>

        <v-data-table
            :headers="[
                { key: 'linkto', sortable: false, cellClass: 'td-linkto', width: '5px' },
                { title: 'Id.', key: 'id' },
                { title: 'Provider', key: 'provider' },
                { title: 'Name', key: 'name' },
                { title: 'Type', key: 'user.type' },
                { title: 'OCPI Ver.', key: 'user.ocpi_version' },
                { title: 'Roles', key: 'role' },
                { title: 'Flags', key: 'flag' },
                { title: 'Updated', key: 'updated_at' },
            ]"
            :items="filteredProviders"
            :loading="tableLoading"
            :search="tableSearch"
            :custom-filter="dataTableDeepSearch"
            disable-pagination
            hide-default-footer
            :items-per-page="-1"
            :fixed-header="true"
            class="accent-table rounded-table"
            density="compact"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn variant="plain" density="compact" size="small" slim :to="`/provider/${item.id}`">
                    <v-icon start >mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.id, country_code: item.country_code , party_id: item.party_id }"
                    extra="no-arrow"
                />
            </template>
            <template v-slot:item.name="{ item }">

                <span v-if="item.company">
                    {{ item.company.name }}
                </span>

                <span v-else-if="item.user.company">
                    {{ item.user.company.name }}
                </span>

                <span v-else>
                    {{ item.user.username }}
                </span>
            </template>
            <template v-slot:item.user.type="{ item }">
                <v-chip class="text-chip text-uppercase"
                    size="small"
                    label
                    variant="outlined"
                >
                {{ item.user.type === 'provider' ? 'direct' : item.user.type }}
                </v-chip>
            </template>
            <template v-slot:item.user.ocpi_version="{ item }">
                <v-chip
                    v-if="item.user.ocpi_version"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                    {{ item.user.ocpi_version }}
                </v-chip>
            </template>
            <template v-slot:item.role="{ item }">
                <v-chip
                    v-for="(role, index) of splitRoles(item.role)"
                    :key="`${item.id}_${role}`"
                    :class="`${index > 0 ? 'ml-1' : ''}`"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                    {{ role }}
                </v-chip>
            </template>
            <template v-slot:item.flag="{ item }">
                <v-chip
                    v-if = "item.flag_real_time_auth"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                  Send RTA
                </v-chip>
                <v-chip
                    v-if = "item.flag_evse_status"
                    class="ml-1"
                    color="accent"
                    variant="outlined"
                    size="small"
                >
                  Send Evse Status
                </v-chip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import _ from "lodash"
import ProviderName from "@/components/provider/ProviderName"
import SearchTextField from "@/components/ui-components/SearchTextField.vue"
import SearchButton from "@/components/ui-components/SearchButton.vue"

export default {
    name: "providers",
    components: {
        AppBar,
        ProviderName,
        SearchTextField,
        SearchButton,
    },
    data() {
        return {
            providers: [],
            filteredProviders: [],
            tableLoading: false,
            selectedSearch: null,
            selectedType: null,
            tableSearch: null,
        };
    },
    inject: ['$api'],
    mounted() {
        EventBus().on("dialog-provider:close", (data) => {
            this.loadList()
        })
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },          
    },
    methods: {
        async loadList() {

            this.tableLoading = true

            this.providers = await this.$api.get("/providers-external")
                .then((res) => {
                    res.data.map((p) => {
                        p.via = `${p.user.company?.name} (${p.user.type})`
                        p.provider_name = `${p.country_code} - ${p.party_id}`
                    })
                    return res.data
                })
                .catch(this.showError)

            this.tableLoading = false

        },
        splitRoles(roles) {
            return roles ? roles.split(",") : ""
        },
        async onFilter(){
            if(this.tableLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)
            this.urlPush('type', this.selectedType)

            await this.loadList()

            if(this.selectedType){
                this.filteredProviders = this.providers.filter(p => p.user.type === this.selectedType)
            } else {
                this.filteredProviders = this.providers
            }
        }
    },
};
</script>