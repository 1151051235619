<template>
    <v-container fluid>
            <v-form ref="form">
                <v-card class="mt-4 mb-8" variant="outlined" :loading="loading">
                    <v-row  class="px-6 mt-2">
                        <v-col cols="12" md="3" v-if="!formUser.id">
                            <v-text-field
                                v-model="formUser.username"
                                label="Username"
                                :rules="[v => !!v || 'Username is required']"
                                required
                                variant="underlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="!formUser.id">
                            <v-text-field
                                v-model="newPassword"
                                label="Password"
                                :rules="[v => !!v || 'Password is required']"
                                required
                                name="password"
                                :type="fieldTypes.password"
                                autocomplete="new-password"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.email"
                                label="e-mail"
                                variant="underlined"
                                :rules="[v => !!v || 'e-mail is required']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.url"
                                label="Web"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_url_versions"
                                label="URL versions"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_own_url"
                                label="Wenea Credentials URL"
                                variant="underlined"
                                disabled
                            ></v-text-field>    
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                :items="status"
                                v-model="formUser.status"
                                label="Status"
                                variant="underlined"
                                :rules="[(v) => !!v || 'Status is required']"
                                required
                            >
                                <template v-slot:selection="{item}">
                                    <StatusChip v-if="item" :status="item.raw" />
                                </template>
                                <template v-slot:item="{props, item}">
                                    <v-list-item v-bind="props" v-if="item" title>
                                        <StatusChip :status="item.raw" />
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-divider />

                    <v-row class="px-6 mt-1">
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_url_endpoints"
                                label="URL endpoints"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-select
                                :items="ocpiVersionsAvailable"
                                v-model="formUser.ocpi_version"
                                label="OCPI version"
                                variant="underlined"
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip 
                                        size="small" 
                                        variant="outlined" 
                                        color="accent"
                                    >
                                        {{ item.raw }}
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="formUser.type"
                                :items="[
                                    { value: 'hub', text: 'hub' },
                                    { value: 'provider', text: 'direct' },
                                ]"
                                label="Type"
                                variant="underlined"
                                :rules="[(v) => !!v || 'Type is required']"
                                required
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip 
                                        size="small" 
                                        variant="outlined" 
                                        color="accent"
                                    >
                                        {{ item.raw.text.toUpperCase() }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="{props, item}">
                                    <v-list-item v-bind="props" title>
                                        <v-chip 
                                            size="small" 
                                            variant="outlined" 
                                            color="accent"
                                        >
                                            {{ item.raw.text.toUpperCase() }}
                                        </v-chip>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_token_a"
                                label="Token A"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_token_c"
                                label="Token C"
                                variant="underlined"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-divider />

                    <v-row class="px-6">
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.token_update_auto"
                            label="Automatic token update"
                            color="accent"
                            hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.flag_send_encoded_auth"
                            label="Send encoded auth"
                            color="accent"
                            hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.flag_url_trailing_slash"
                            label="Add URL trailing slash"
                            color="accent"
                            hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>

            <v-row class="d-flex justify-end pr-4 pb-4">
                <slot name="actions"></slot>
                <v-btn class="ml-2" color="accent" variant="flat" @click="save">
                    <v-icon left>mdi-content-save</v-icon>{{ buttonSave }}
                </v-btn>
            </v-row>
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus";
import StatusChip from "@/components/connection/StatusChip";

export default {
    name: "connection-form",
    props: [ 'user' ],
    components: { StatusChip },
    inject: ['$api'],
    data() {
        return {            
            loading: false,
            ocpiVersionsAvailable: ['2.1.1', '2.2', '2.2.1'],
            status: ['CONNECTED', 'SUSPENDED', 'DISCONNECTED', 'PLANNED', 'OFFLINE'],
            fieldTypes: {
                password: 'text',
            },
            newPassword: '',
            formUser: this.user || { status: 'DISCONNECTED', flag_send_encoded_auth: true, ocpi_own_url: process.env.VUE_APP_WENEA_CREDENTIALS_URL },
        }
    },
    watch: {
        newPassword: {
            handler: function(val) {
                if(val) {
                    this.fieldTypes.password = 'password'
                }
                
            },
        },
        user: {
            immediate: true,
            handler(newVal) {
                this.formUser = newVal || { status: 'DISCONNECTED', flag_send_encoded_auth: true, ocpi_own_url: process.env.VUE_APP_WENEA_CREDENTIALS_URL };
            }
        }
    },
    computed: {
        buttonSave: function() { return this.formUser.id ? 'Save' : 'Create connection' }
    },
    methods: {
        save(){
            this.formUser = { ...this.formUser, password: this.newPassword }
            if (!this.$refs.form.validate()){ 
                return this.showError("All required fields must be provided")
            }
            let data = (({ tokens, endpoints, company, ...user }) => user)(this.formUser)

            if( this.formUser.id ){
                
                this.$api.patch(`/user/${this.formUser.id}`, data)
                    .then(resp => {
                        this.formUser = resp.data
                        this.showSuccess("Data saved successfully")
                    })
                    .catch(this.showError)

            } else {

                this.$api.post(`/user`, data)
                    .then((resp) => EventBus().emit("connection-created", { user: resp.data }))
                    .catch(this.showError)

            }
        }
    }
};
</script>
