<template>
    <v-container fluid>
        <AppBar title="Pending locations export to Core" icon="mdi-database-export" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    variant="outlined"
                    clearable
                    hide-details
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            title
                        >
                            <ProviderSelectorTemplate :provider="item.raw" />
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-autocomplete
                    class="filter-field accent"
                    v-model="selectedCountry"
                    :items="countries"
                    label="Country"
                    item-title="name"
                    item-value="alpha3"
                    clearable
                    variant="outlined"
                    hide-details
                >
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
                <v-text-field
                    class="filter-field accent"
                    v-model.number="selectedMinimumChargerPower"
                    type="number"
                    label="Minimum CP power (kW)"
                    variant="outlined"
                    clearable
                    hide-details
                ></v-text-field>
            </v-col>

            <SearchButton
                :loading="tableLoading"
                :searchFunction="onFilter"
            />
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { title: 'Provider', key: 'provider' },
                { title: 'Id.', key: 'id' },
                { title: 'Name', key: 'name' },
                { title: 'Connectors', key: 'max_electric_powers' },
                { title: `Connector greater than ${locationsMinimumChargerPower || 0} kW`, value: 'connector_greater_than_filter' },
                { title: '', key: 'actions' },
            ]"
            :items="locations"
            :loading="tableLoading"
            disable-sort
            density="compact"
            class="accent-table rounded-table"
            :no-data-text="initialLoadMessage"
        >
            <template v-slot:item.provider="{ item }">
                <ProviderName
                :provider="{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }"
                />
            </template>

            <template v-slot:item.id="{ item }">
                {{ item.id }}
                <v-btn class="pl-0" variant="plain" size="small" :to="`/location/${item.id}`" style="min-width: 0px">
                    <v-icon>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            
            <template v-slot:item.max_electric_powers="{ item }">
                <v-chip variant="outlined" color="accent" size="small">
                    <v-icon start>mdi-power-plug</v-icon>
                    {{ item.max_electric_powers.length }}
                </v-chip>
            </template>

            <template v-slot:item.connector_greater_than_filter="{ item }">
                <v-chip variant="outlined" color="accent" size="small">
                    <v-icon start>mdi-power-plug</v-icon>
                    {{ item.max_electric_powers.filter(p => p >= selectedMinimumChargerPower).length }}
                </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                      <v-btn v-bind="props"
                        class="mr-2"
                        label="Provider"
                        color="accent"
                        variant="text"
                        size="small"
                        @click.stop="sendLocation(item.id)"
                      >
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                  </template>
                  <span>Send location to core</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import SearchButton from "@/components/ui-components/SearchButton.vue"
import ProviderName from "@/components/provider/ProviderName"
import Countries from "@/../../shared/resources/WorldCountryCodes_EN.json"

export default {
    components: { 
        AppBar,
        ProviderSelectorTemplate,
        SearchButton,
        ProviderName 
    },
    inject: ['$api'],
    data() {
        return {
            tableLoading: false,

            selectedMinimumChargerPower: 50,
            selectedProvider: null,
            selectedCountry: null,
            providers: [],
            countries: Countries,

            locations: [],
            locationsMinimumChargerPower: null,

            initialLoad: true,
            initialLoadMessage: 'Click the filter button to load the data'
        }
    },
    mounted(){
        this.$api.get("/providers")
            .then(res => {
                this.providers = res.data
                    .filter(p => p.role.includes('CPO'))
                    .map(p => { 
                        return { 
                            ...p, 
                            title: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                    
                this.selectedProvider = this.selectedProvider || this.providers[0]?.id || null
                })
            .catch(this.showError)
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        async load(){
            this.tableLoading = true
            
            let queryParams = ''

            if (this.selectedProvider) { queryParams += `&provider_id=${this.selectedProvider}` } 
            if (this.selectedCountry) { queryParams += `&country=${this.selectedCountry}` } 
            if (this.selectedMinimumChargerPower) { 
                queryParams += `&minimum_charger_power=${this.selectedMinimumChargerPower}` 
                this.locationsMinimumChargerPower = this.selectedMinimumChargerPower
            } 

            this.$api.get(`reports/pending-locations-export-to-core?${queryParams}`)
                .then(resp => this.locations = resp.data || [] )
                .catch(this.showError)
                .finally(() => this.tableLoading = false)
        },
        onFilter(){
            if(this.tableLoading || this.initialLoad){
                this.initialLoad = false
                return
            }

            this.urlPush('provider', this.selectedProvider)
            this.urlPush('country', this.selectedCountry)
            this.urlPush('minchargerpower', this.selectedMinimumChargerPower)
            this.initialLoadMessage = 'No locations found'

            this.load()
        },
        sendLocation(location_id) {
            this.$api.post(`settings/export-locations-to-core/${location_id}`)
                .then(() => {
                    this.locations = this.locations.filter(l => l.id != location_id)
                    this.showSuccess('Location has been successfully sent to core')
                })
                .catch(this.showError)            
        },
    }
}
</script>