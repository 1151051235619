
export default {

    colorPalette: [ 
        '#8c4799', 
        '#fb0046', 
        '#fbb800', 
        '#2196F3',
        '#FA1300',
        '#EDAE4A',
        '#D6D638',
        '#ED6194',
        '#EDAE4A',
        '#9BED32',
        '#3EED4B',
        '#3295ED',
        '#ED3ED0',
    ],

    generateColorList(number){
        if (this.colorPalette.length > number) {
            return this.colorPalette.slice(0, number)
        }

        const colors = this.colorPalette
        for (let i = this.colorPalette.length; i < number; i++) {
            colors.push(this.colorPalette[Math.floor(Math.random() * this.colorPalette.length)])
        }

        return colors
    }

}