<template>
    <v-dialog v-model="show" persistent max-width="1000px" content-class="custom-scollbar" @click:outside="close">
        <v-card>
            <v-card-title class="bg-accent">
                <v-icon start color="white">mdi-car-multiple</v-icon> 
                <span class="headline">Evse</span>
            </v-card-title>

            <v-spacer></v-spacer>

            <v-divider />

            <v-card-text class="pa-4">
                <v-row>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{evse.id}}</v-list-item-title>
                                <v-list-item-subtitle>Id.</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{evse.status}}</v-list-item-title>
                                <v-list-item-subtitle>Status</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>
                                    <v-btn class="pl-0" variant="text" @click="clipboard(evse.evse_id)">
                                        {{evse.evse_id}}
                                        <v-icon size="small" end>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>Evse Id.</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item lines="two" class="pb-0 pt-0">
                            
                                <v-list-item-title>
                                    <v-btn class="pl-0" variant="text" @click="clipboard(evse.uid)">
                                        {{evse.uid}}
                                        <v-icon size="small" end>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>UID</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" md="6" class="pb-0 pt-0">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>
                                    {{ evse.floor_level }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Floor Level</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" v-if="evse.coordinates" class="pb-0 pt-0">
                        <v-list-item lines="two">
                            
                                <v-list-item-title v-if="evse.coordinates.latitude && evse.coordinates.longitude">
                                    <v-btn class="pl-0" variant="text" @click="openMaps(evse.coordinates)">
                                        {{evse.coordinates.latitude}}, {{evse.coordinates.longitude}}
                                        <v-icon size="small">mdi-map-marker</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>Coordinates</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <v-list disabled>
                            <v-list-item-subtitle class="pl-4 pt-4">Capabilities</v-list-item-subtitle>
                            
                            <v-list-item
                                v-for="(item) in evse.capabilities"
                                style="opacity: 1;"
                            >
                            
                                <v-list-item-title>
                                    <v-chip color="accent" size="small">
                                        {{ item }}
                                    </v-chip>
                                </v-list-item-title>
                            
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 pt-0">
                        <v-list disabled>
                            <v-list-item-subtitle class="pl-4 pt-4">Parking Restrictions</v-list-item-subtitle>

                                <v-list-item
                                v-for="(item) in evse.parking_restrictions"
                                style="opacity: 1;"
                                >
                                
                                    <v-list-item-title>
                                        <v-chip color="accent" size="small">
                                            {{ item }}
                                        </v-chip>
                                    </v-list-item-title>
                                
                                </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>
                                    <json-viewer
                                        v-if="evse.directions"
                                        class="pa-2"
                                        :value="evse.directions"
                                        :copyable="{
                                        copyText: 'Copiar',
                                        copiedText: 'Copiado!',
                                        timeout: 2000,
                                        }"
                                        :expand-depth="3"
                                    >
                                    </json-viewer>
                                </v-list-item-title>
                                <v-list-item-subtitle>Directions</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>
                                    <json-viewer
                                        v-if="evse.status_schedule"
                                        class="pa-2"
                                        :value="evse.status_schedule"
                                        :copyable="{
                                        copyText: 'Copiar',
                                        copiedText: 'Copiado!',
                                        timeout: 2000,
                                        }"
                                        :expand-depth="3"
                                    >
                                    </json-viewer>
                                </v-list-item-title>
                                <v-list-item-subtitle>Status Schedule</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{ adonisDateFullFormat(evse.created_at) }}</v-list-item-title>
                                <v-list-item-subtitle>Created</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{ adonisDateFullFormat(evse.updated_at) }}</v-list-item-title>
                                <v-list-item-subtitle>Updated</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{ dateFullFormat(evse.last_updated) }}</v-list-item-title>
                                <v-list-item-subtitle>Last Updated</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item lines="two">
                            
                                <v-list-item-title>{{ dateFullFormat(evse.deleted_at) }}</v-list-item-title>
                                <v-list-item-subtitle>Deleted</v-list-item-subtitle>
                            
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row>
                    <h3 class="mt-6 mb-3">
                        <v-icon class="ml-5" color="accent">mdi-power-plug</v-icon> Connectors
                    </h3>
                    
                    <v-data-table 
                    class="rounded-table"
                    :headers="[
                        { title: '', key: 'data-table-expand'},
                        { title: 'Id.', key: 'id' },
                        { title: 'Standard', key: 'standard'},
                        { title: 'Format', key: 'format'},
                        { title: 'Power type', key: 'power_type'},
                        { title: 'Label', key: 'label' },
                        { title: 'Core Id.', key: 'backend_id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Last Updated', key: 'last_updated' },
                        { title: 'Deleted', key: 'deleted_at' }
                    ]" 
                    :items="connectors"
                    :expanded="connectors"
                    disable-sort
                    disable-pagination 
                    hide-default-footer
                    show-expand
                    density="compact"
                    >
                        <template v-slot:item.data-table-expand="{ internalItem, toggleExpand, isExpanded }">
                            <v-row class="d-flex">
                                <v-btn @click="toggleExpand(internalItem)" variant="flat" icon size="small">
                                    <v-icon v-if="isExpanded(internalItem)">mdi-chevron-up</v-icon>
                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                </v-btn>
                            </v-row>
                        </template>
                        <template v-slot:expanded-row="{ columns, item }">
                            <td class="pa-0 connector-table-container" :colspan="columns.length">
                            <v-row class="pt-5 pb-2">
                                <v-chip class="ml-15" variant="outlined" color="accent" size="small">
                                    Max power: {{ parseFloat(item.max_electric_power / 1000).toFixed(1) }}kW
                                </v-chip>

                                <v-chip class="ml-2" variant="outlined" color="accent" size="small">
                                    Max voltage: {{ item.max_voltage }}
                                </v-chip>

                                <v-chip class="ml-2" variant="outlined" color="accent" size="small">
                                    Max amperage: {{ item.max_amperage }}
                                </v-chip>
                            </v-row>
                            <v-row class="pb-5">
                                <v-chip v-for="tariff_id in item.tariff_ids" :key="tariff_id" class="ml-15" 
                                    variant="outlined" color="accent" size="small"
                                    @click="goTo(`/tariffs?search=${tariff_id}`)"
                                >
                                   Tariff: {{ tariff_id }}
                                </v-chip>
                            </v-row>
                            </td>

                        </template>
                        <template v-slot:item.last_updated="{ item }">
                            {{ dateFullFormat(item.last_updated) }}
                        </template>
                        <template v-slot:item.deleted_at="{ item }">
                            {{ dateFullFormat(item.deleted_at) }}
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false" depressed>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            loading: false,
            evse: {},
            connectors: {},
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.loading = false
                EventBus().emit("dialog-evses:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus().on("dialog-evses:open", async function( args ) {
            vm.evse = args.evse
            vm.connectors = args.connectors
            vm.show = true
        })
    },
    methods: {
        close() {
            this.show = false
        },
    }
}
</script>

<style scoped>
    :deep(.v-data-table)>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
    :deep(.v-list-item)>.v-list-item__content {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    :deep(.v-list-item){
        min-height: 0 !important;
    }
    :deep(.v-data-table__wrapper){
        overflow: hidden !important;
    }
</style>