<template>
  <div class="full-screen">
    <div 
      class="full-screen" 
      ref="googleMap" 
      :style="`border-radius:${borderRadius};`"
    >
    </div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'

export default {
  props: {
    mapConfig: Object,
    borderRadius: {
      type: String,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      google: null,
      map: null
    }
  },
  async mounted() {
    GoogleMapsApiLoader({ apiKey: this.apiKey })
      .then((google) => {
        this.google = google
        this.initializeMap()
      })
  },
  methods: {
    initializeMap() {
        if (this.google) {
            this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig)
        }
    }
  }
}
</script>
<style scoped>
  .full-screen {
    width: 100%;
    height: 100% !important;
  }
</style>