<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ props }">
            <div>
                <v-btn  
                    v-bind="props"
                    class="mr-2"
                    :class="{ 'shake': shake }"
                    to='/alerts'
                    icon="mdi-bell-outline"
                    variant="plain"
                >
                </v-btn>
                <v-badge :content="alerts" color="red" inline />
            </div>
        </template>
        <span>Current Alerts</span>
    </v-tooltip>
</template>

<script>
export default ({
    data() {
        return {
            shake: false
        }
    },
    computed: {
        alerts () {
            return this.$store.state.alerts
        }
    },
    watch: {
        alerts (newCount, oldCount) {
            if (newCount > oldCount) {
                this.shake = true
                setTimeout(() => this.shake = false, 1000)
            }
        }
    }
})
</script>


<style scoped>
    :deep(.v-btn){
        height: 36px !important;
        width: 36px !important;
    }
    :deep(.v-btn--active:not(:hover)::before){
        opacity: 0 !important;
    }
    :deep(.v-badge--inline).v-badge__badge,.v-badge--inline .v-badge__wrapper {
        position: absolute;
    }
    :deep(.v-badge--inline .v-badge__wrapper) {
        margin-right: -12px;
    }
    :deep(.v-badge__badge){
        position: relative;
        left: -28px;
        top: -10px;
        height: 17px;
    }
    :deep(.shake)>* {
        animation: animate-shake .1s ease-in .2s infinite alternate;
    }
    @keyframes animate-shake{
        from{
            transform:rotate(13deg)
        }
        to{
            transform:rotate(-13deg)
        }
    }
</style>
