<template>
    <v-container fluid>

        <AppBar icon="mdi-map-marker" v-if="provider" :breadcrumbs="[
            { text: name, to: `/provider/${provider.id}` },
            { text: 'Locations', to: `/provider/${provider.id}/cpo/locations` },
            { text: locationName, icon: 'mdi-map-marker' }
        ]"/>

        <div class="d-flex flex-row">
            <v-col cols="12" md="8" class="pa-0">
                <v-card :loading="loadingLocation" variant="outlined">
                    <v-row v-if="location">
                        <v-col cols="12" md="5">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <LocationName
                                            :location="{ name: location.name }"
                                            extra="no-arrow"
                                        />     
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Name</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ `${location.country_code}-${location.party_id}` }}</v-list-item-title>
                                    <v-list-item-subtitle>Country - Party</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.id}}</v-list-item-title>
                                    <v-list-item-subtitle>Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="5" v-if="location.coordinates">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <v-btn @click="openMaps(location.coordinates)" icon variant="text">
                                            <v-icon color="accent">mdi-map</v-icon>
                                        </v-btn>
                                        {{location.coordinates.latitude}}, {{location.coordinates.longitude}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Coordinates</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.ocpi_id}}</v-list-item-title>
                                    <v-list-item-subtitle>OCPI Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{location.backend_id}}</v-list-item-title>
                                    <v-list-item-subtitle>Core Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                    </v-row>

                    <v-divider class="mb-2" />

                    <h3 class="ml-4">Provider</h3>
                    <v-row v-if="provider">
                        <v-col cols="12" md="5">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <ProviderName
                                            :provider="provider"
                                        />
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Company</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="5">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ provider.role }}</v-list-item-title>
                                    <v-list-item-subtitle>Roles</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{provider.id}}</v-list-item-title>
                                    <v-list-item-subtitle>Id.</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pa-0 pl-3">
                <GoogleMapLoader v-if="mapConfig"
                    :map-config="mapConfig"
                    border-radius="5px"
                >
                    <template v-slot="{ google, map }">
                        <v-marker
                            :position="coordinates"
                            :google="google"
                            :map="map"
                        ></v-marker>
                    </template>
                </GoogleMapLoader>
            </v-col>
        </div>
        
        <v-expansion-panels 
            class="accent-expansion-panels depressed-expansion-panels compressed-expansion-panels mt-3" 
            focusable multiple v-if="location" elevation="0">

            <v-expansion-panel>
                <v-expansion-panel-title>
                        Address
                </v-expansion-panel-title>
                <v-expansion-panel-text>   
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.country ? location.country : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Country</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="3">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.state ? location.state : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>State</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.city ? location.city : '-'}}</v-list-item-title>
                                    <v-list-item-subtitle>City</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.postal_code ? location.postal_code : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Postal code</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.address }}</v-list-item-title>
                                    <v-list-item-subtitle>Address</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.directions">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.directions"
                                                :expand-depth="0"
                                            >
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Directions</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                    </v-row>         
                </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-title>
                        Parking and charge information
                </v-expansion-panel-title>
                <v-expansion-panel-text >   
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.parking_type ? location.parking_type : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Parking type</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.opening_times">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.opening_times"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Opening_times</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>


                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.time_zone ? location.time_zone : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Time_zone</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.charging_when_closed ? location.charging_when_closed : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Charging when closed</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="8">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.energy_mix">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.energy_mix"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Energy mix</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                    </v-row>         
                </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-title>
                        Other info
                </v-expansion-panel-title>
                <v-expansion-panel-text >   
                    <v-row>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.owner">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.owner"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Owner</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.operator">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.operator"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Operator</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.suboperator">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.suboperator"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Suboperator</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location.publish_allowed_to ? location.publish_allowed_to : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Publish allowed to</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>{{ location ? location.publish : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Publish</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>
                        
                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.related_locations">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.related_locations"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Related locations</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.facilities">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.facilities"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Facilities</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-list-item lines="two">
                                
                                    <v-list-item-title>
                                        <template v-if="location.images">
                                            <json-viewer
                                                style="width:100%;"
                                                :value="location.images"
                                                :expand-depth="0">
                                            </json-viewer>
                                        </template>
                                        <template v-else>-</template>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Images</v-list-item-subtitle>
                                
                            </v-list-item>
                        </v-col>

                    </v-row>         
                </v-expansion-panel-text>
            </v-expansion-panel>

        </v-expansion-panels>
        
        <!-- Operaciones -->
        <h2 class="my-3 ml-2">
            <v-icon start size="x-small" color="accent">mdi-wrench</v-icon> 
            Operations
        </h2>
        <v-row>
            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <SendLocationTool class="fill-height" :locationId="location.id" :providers="externalProviders" :locationGroupProviders="locationGroupProviders"></SendLocationTool>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-chip class="mr-2" color="deep-purple" variant="outlined" size="small">PATCH</v-chip>Evse status</p>
                        <p class="mb-4">Updates status of all evses in location to selected provider.</p>
                        <v-autocomplete class="mb-4"
                            v-model="providerToSendEvseStatus"
                            :items="locationGroupProviders"
                            :rules="[v => !!v]"
                            :filter="autocompleteDeepSearch"
                            item-value="id"
                            label="Send to"
                            variant="outlined"
                            hide-details
                        >
                            <template v-slot:selection="{ props, item }">
                                <v-chip v-bind="props" size="small" variant="outlined">
                                    <v-icon color="black">mdi-earth</v-icon>{{ `${item.raw.country_code}-${item.raw.party_id}` }}
                                </v-chip>
                            </template>
                            <template v-slot:item="{ props, item  }">
                                <v-list-item v-bind="props" title>
                                    <v-icon small color="black">mdi-earth</v-icon>
                                    <span class="ml-2">{{ `${item.raw.country_code}-${item.raw.party_id}` }}</span>
                                    <v-chip v-if="item.raw.role" class="ml-2" small variant="outlined">{{ item.raw.role.replaceAll(',', '/') }}</v-chip>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-card-text>
                    
                    <v-card-actions class="mb-4">
                        <v-btn @click="sendEvseStatusToProvider" text block :loading="loadingSendEvseStatus" color="accent">
                            <v-icon left>mdi-upload</v-icon>
                            PATCH Evse status
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id == 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-import</v-icon>Import Location from DTM</p>
                        <p class="mb-4">Obtains Location from DTM and updates its data in OCPI. Includes location's chargepoints, evses, connectors and locations group relations.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="importLocationFromDtm" variant="text" block :loading="loadingSendLocationToBackend" color="accent">
                            <v-icon start>mdi-upload</v-icon>
                            Import from DTM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1 && hasRole('CPO')">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-chip class="mr-2" color="amber accent-4" variant="outlined" size="small">GET</v-chip>Location</p>
                        <p>Update Location from Provider.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="updateLocationFromProvider" variant="text" block :loading="loadingUpdateLocationFromProvider" color="accent">
                            <v-icon start>mdi-download</v-icon>
                            Update Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-sync</v-icon>Sync to Backend</p>
                        <p class="mb-4">Send Location to Backend.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="sendLocationToBackend" variant="text" block :loading="loadingSendLocationToBackend" color="accent">
                            <v-icon start>mdi-send</v-icon>
                            Send to Backend
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Soft Delete Location -->
            <v-col cols="12" sm="6" md="4" v-if="location && !location.deleted_at && provider.id !== 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text class="pb-0">
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-map-marker-remove</v-icon>Delete Location</p>
                        <p>Delete all Chargepoints, Evses and Connectors from this Location. This will <b>NOT</b> perform actions to Backend.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="deleteLocation" variant="text" block :loading="loadingDeleteLocation" color="accent">
                            <v-icon start>mdi-delete-outline</v-icon>
                            Delete Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Soft UnDelete Location -->
            <v-col cols="12" sm="6" md="4" v-if="location && location.deleted_at && provider.id !== 1">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text class="pb-0">
                        <p class="text-subtitle-2 mb-4"><v-icon start color="accent">mdi-undo</v-icon>UnDelete Location</p>
                        <p>UnDelete all Chargepoints, Evses and Connectors from this Location where status is NOT 'REMOVED'. This will not perform actions to Backend.</p>                        
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="unDeleteLocation" variant="text" block :loading="loadingUnDeleteLocation" color="accent">
                            <v-icon start>mdi-arrow-u-left-top</v-icon>
                            UnDelete Location
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="4" v-if="provider && provider.id != 1 && hasRole('CPO')">
                <v-card variant="outlined" class="fill-height d-flex flex-column">
                    <v-card-text>
                        <p class="text-subtitle-2 mb-4"><v-icon left color="accent">mdi-compare</v-icon>Compare local DB/Provider Location data</p>
                        <p class="mb-4">Do a GET Location on Provider and compare it with saved in DB.</p>
                    </v-card-text>
                    <v-card-actions class="mb-4">
                        <v-btn @click="compareLocation" variant="text" block :loading="loadingCompareLocation" color="accent">
                            <v-icon start>mdi-file-compare</v-icon>
                            Show comparsion
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

        </v-row>

        <!-- Location Structure -->
        <v-row class="d-flex flex-row align-center">
            <h2 class="my-3 ml-2"><v-icon start  color="accent">mdi-file-tree-outline</v-icon> Structure</h2>
            <v-btn-toggle
                    v-model="locationStructureMode"
                    rounded="lg"
                    class="ml-4 align-center no-flex"
                    mandatory
                >
                    <v-btn value="simple" color="accent" height="30" class="toggle__opacity">
                        <span>Simple</span>
                    </v-btn>
                
                    <v-btn value="advanced" color="accent" height="30" class="toggle__opacity">
                        <span>Advanced</span>
                    </v-btn>
            </v-btn-toggle>
        </v-row>
        <LocationStructure :chargepoints="chargepoints" :mode="locationStructureMode"/>

        <h2 class="my-3 ml-2" v-if="provider && provider.id == 1"><v-icon start color="accent">mdi-folder-marker</v-icon> Location Groups</h2>
        <v-data-table
            v-if="groups && provider && provider.id == 1"
            :headers="[
                { key: 'linkto', sortable: false, cellClass: 'td-linkto' },
                { title: 'Id.', key: 'id' },
                { title: 'Name', key: 'name' },
                { title: 'Reference', key: 'reference' },
                { title: 'Private', key: 'is_private' },
                { title: 'Backend Id', key: 'backend_id' },
                { title: 'Updated at', key: 'updated_at' },
            ]"
            :items="groups"
            disable-sort
            disable-pagination
            hide-default-footer
            density="compact"
            class="accent-table rounded-table"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn variant="plain" :to="`/location-group/${item.id}`">
                    <v-icon>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.is_private="{ item }">
                <v-icon v-if="item.is_private">mdi-check</v-icon>
            </template>
        </v-data-table>

        <JsonDialog />

    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import GoogleMapLoader from '@/components/map/GoogleMapLoader'
import VMarker from '@/components/map/VMarker'
import JsonDialog from "@/components/JsonDialog"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"
import LocationStructure from "@/components/location/LocationStructure"
import SendLocationTool from "@/components/location/SendLocationTool"

export default {
    name: "providers",
    components: {
        AppBar,
        GoogleMapLoader,
        VMarker,
        JsonDialog,
        ProviderName,
        LocationName,
        ChargepointName,
        LocationStructure,
        SendLocationTool
    },
    inject: ['$api'],
    data() {
        return {
            loadingLocation: false,
            location: null,
            chargepoints: [],
            groups: [],
            provider: null,
            externalProviders: [],
            locationGroupProviders: [],
            providerToSend: null,
            providerToSendEvseStatus: null,
            loadingSendEvseStatus: false,
            loadingImportLocationFromBackend: false,
            loadingSendLocationToBackend: false,
            loadingDeleteLocation: false,
            loadingUnDeleteLocation: false,
            loadingUpdateLocationFromProvider: false,
            loadingCompareLocation: false,
            toBackend: null,
            coordinates: null,
            mapConfig: null,
            reportsChannel: null,
            locationStructureMode: 'simple'
        }
    },
    computed: {
        locationName: function(){
            return (this.location) ? this.location.name : "-"
        },
        providerInfo: function(){
            return (this.provider) ? { name: `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`, id: this.provider.id } : { name: '-', id: null }
        },
        name(){
           return this.provider.user?.company?.name || this.provider.user.username
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            let id = this.$route.params.id
            if( !id ){ this.showError('No hay Id.!'); return; }
            
            this.loadingLocation = true
            this.$api.get(`location/${id}`)
                .then(resp => {
                    this.location = resp.data
                    this.provider = resp.data.provider
                    this.chargepoints = resp.data.chargepoints
                    this.loadMap(this.location)
                    this.getLocationGroupProviders()
                    if (this.provider.id == 1) this.getLocationGroups()
                    this.getConnectorsApplicableTariffsIds()
                })
                .catch(this.showError)
                .then(_ => { this.loadingLocation = false })
            
            this.getExternalProviders()
        },
        loadMap(location) {
            this.coordinates = { 
                lat: Number(location.coordinates?.latitude), 
                lng: Number(location.coordinates?.longitude) 
            }

            this.mapConfig = {
                center: this.coordinates,
                zoom: 16,
                minZoom: 4,
            }
        },
        getLocationGroupProviders() {
            let id = this.$route.params.id

            this.$api.get(`location/${id}/location-groups/providers`)
                .then(resp => this.locationGroupProviders = resp.data.filter(p => p.role.includes('EMSP') || p.role.includes('NSP')))
        },
        getExternalProviders(){
            this.$api.get("/providers-external")
                .then(res => this.externalProviders = res.data.filter(p => p.role.includes('EMSP') || p.role.includes('NSP')))
                .catch(this.showError)
        },
        getLocationGroups() {
            let id = this.$route.params.id

            this.$api.get(`location/${id}/location-groups`)
                .then(resp => {
                    this.groups = resp.data
                })
        },
        getConnectorsApplicableTariffsIds(){
            if( this.provider.id == 1 ){ 
                const connector_ids = this.chargepoints.flatMap(cp => cp.evses.flatMap(evse => evse.connectors.map(connector => connector.id)))
                if( !connector_ids.length ) return 

                this.$api.get(`tariffs/connectors/${connector_ids.join(',')}`)
                .then(resp => {
                    this.chargepoints.forEach(cp => {
                        cp.evses.forEach(evse => {
                            evse.connectors.forEach(connector => {
                                let applicable_tariffs = resp.data.filter(tariff => tariff.connector_id == connector.id)
                                connector.applicable_tariffs = applicable_tariffs
                            })
                        })
                    })
                })
                .catch(this.showError)

            }else{
                const tariff_ids = this.chargepoints.flatMap(cp => cp.evses.flatMap(evse => evse.connectors.map(connector => connector.tariff_ids)))
                if( !tariff_ids.length ) return 

                this.$api.get(`tariffs/${tariff_ids.join(',')}/provider/${this.provider.id}`)
                .then(resp => {
                    this.chargepoints.forEach(cp => {
                        cp.evses.forEach(evse => {
                            evse.connectors.forEach(connector => {
                                let applicable_tariffs = resp.data.filter(tariff => connector.tariff_ids.includes(tariff.ocpi_id))
                                connector.applicable_tariffs = applicable_tariffs
                            })
                        })
                    })
                })
                .catch(this.showError)
            }

        },
        sendLocationToProvider(){
            if( !this.providerToSend ){ this.showError('Provider must be selected to send Location'); return; }

            this.loadingSendLocation = true
            this.$api.get(`location/${this.location.id}/send-to/provider/${this.providerToSend}`)
                .then(() => this.showSuccess('Data sent successfully'))
                .catch(this.showError)
                .then(() => { this.loadingSendLocation = false })
        },
        sendEvseStatusToProvider(){
            if( !this.providerToSendEvseStatus ){ this.showError('Provider must be selected to send evse status'); return; }

            this.loadingSendEvseStatus = true
            this.$api.get(`location/${this.location.id}/evse-status/send-to/providers/${this.providerToSendEvseStatus}`)
                .then(() => this.showSuccess('Data sent successfully'))
                .catch(() => this.showCustomAlert('error', 'Errors have occurred while sending evse status to provider', { to:`/provider/${this.providerToSendEvseStatus}/http-logs`, text:'Check PATCH calls', icon:'mdi-arrow-top-right' }))
                .then(() => { this.loadingSendEvseStatus = false })
        },
        importLocationFromDtm(){
            this.loadingImportLocationFromBackend = true

            let workerUrl = `location/${this.location.backend_id}/update-from-dtm`

            this.$api.get(workerUrl)
                .then(resp => {
                    this.showSuccess(resp.data)
                    this.load()
                })
                .catch(this.showError)
                .then(() => { this.loadingImportLocationFromBackend = false })
        },
        sendLocationToBackend(){
            this.loadingSendLocationToBackend = true
            let workerUrl = `location/${this.location.id}/send-location-to-backend`

            this.$api.get(workerUrl)
                .then(resp => {
                    this.showSuccess('Data sent successfully')
                })
                .catch(this.showError)
                .then(_ => { this.loadingSendLocationToBackend = false })
        },
        deleteLocation(){
            const confirmed = confirm('Are you sure you want to mark this Location as DELETED?')
            if(!confirmed){ return }

            this.loadingDeleteLocation = true
            this.$api.get(`location/${this.location.id}/delete-location`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingDeleteLocation = false })
        },
        unDeleteLocation(){
            const confirmed = confirm('Are you sure you want to mark this Location as UNDELETED?')
            if(!confirmed){ return }

            this.loadingUnDeleteLocation = true
            this.$api.get(`location/${this.location.id}/un-delete-location`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingUnDeleteLocation = false })
        },
        updateLocationFromProvider() {
            if (!this.provider.id) { return }

            this.loadingUpdateLocationFromProvider = true
            
            this.$api
                .get(`locations/${this.location.ocpi_id}/fetch`)
                .catch(this.showError)
                .then((resp)=>{
                    if (!resp?.data?.success) {
                        this.showError(resp?.data?.errorMessage)
                    }else{
                        this.showSuccess('Location updated successfully')
                        this.load()
                    }
                })
            this.loadingUpdateLocationFromProvider = false
        },
        hasRole(role){
            return this.provider.role && this.provider.role.includes(role)
        },
        async compareLocation(){
            this.loadingCompareLocation = true
            const jsons = await this.$api.get(`locations/${this.location.id}/local-and-provider-data`)
                .then(resp => resp.data)
                .catch(this.showError)

                this.loadingCompareLocation = false

            EventBus().emit(
                "dialog-json:open",
                { title: 'Local db / provider Location', json: jsons.local_db_location, json2: jsons.provider_location }
            )

            
        }

    }
}
</script>

<style scoped>
    :deep(.jv-container).jv-code{
        padding: 0px;
    }

    :deep(.fill-height){
        margin-bottom: 24px;
    }

    :deep(.fill-height).v-card__actions{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 10px;
        padding-inline: 14px;
    }

    .toggle__opacity .v-btn__overlay{
        opacity: 0.5;
    }

</style>