<template>
        <div>
            <v-icon 
                size="small" 
                color="black" 
                class="v-left-icon mr-1" 
                v-if="extra !== 'no-icon'"
                icon="mdi-map-marker"
            />
            <span :style="getClass()"> {{ location.name }} </span>
            <v-btn 
                variant="text" 
                size="x-small" 
                :to="`/location/${location.id}`" 
                v-if="extra !== 'no-arrow'"
                icon="mdi-arrow-top-right"
            />
        </div>
   
</template>
  
<script>
    export default {
    name: "locationName",
    props: ["location", "extra", "type"],

    methods: {
        getClass() {
            switch(this.type){
                case "table":
                    return "font-size: 0.85rem; font-weight: 500;";
                default:
                    return "font-size: 1rem; font-weight: 500;";
            }
        },
    },
  }

</script>

<style scoped>
  :deep(.v-btn:not(.v-btn--round).v-size--small){
    height: 28px;
    min-width: 0px !important;
    padding: 0 0 4px 0px !important;
  }

  :deep(.v-left-icon){
    height: 10px;
    min-width: 0px !important;
    padding: 0 0 2px 0px !important;
  }

</style>