<template>
  <v-container fluid>
    
    <AppBar title="Tariffs" icon="mdi-tag-text-outline" />

    <TariffForm 
      :value="tariff"
      @cancel="$router.go(-1)"
      @updated="(tariff) => $router.push(`/tariffs/${tariff.id}`)" >
    </TariffForm>

  </v-container>
</template>
<script>
import AppBar from "@/components/AppBar"
import TariffForm from "@/components/tariff/Tariff.edit"

export default {
    components: {
      AppBar,
      TariffForm
    },
    inject: ['$api'],
    data() {
        return {
          tariffId: this.$route.params.id,
          tariff: null
        }
    },
    async mounted(){
      if( !this.tariffId ){ return }

      this.tariff = await this.$api.get(`/tariffs/${this.tariffId}`)
        .then((response) => response.data)
        .catch(this.showError)
    }
}
</script>