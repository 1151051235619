<template>
    <div>
        <AppBar />
        <v-container class="pa-0">
            <div class="text-center align-center" style="width: 100%; height: 100%;">
                <v-img src="@/assets/page404.png" height="600" contain></v-img>
                <span class="text-overline text-center" style="font-size: 1rem!important;">The resource requested could not be found on this server!</span>
            </div>
        </v-container>
    </div>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    components: { AppBar },
}
</script>

<style scoped>
    h2,span {
        user-select: none;
    }
</style>