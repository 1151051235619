<template>
  <div>
    <template v-if="invoiceNumbersPerDay && Object.keys(invoiceNumbersPerDay).length > 0">
      <highcharts :options="chartOptions"></highcharts>
    </template>

    <template v-else>
      <p class="ma-0 centered-element">No invoices have been found for these search parameters. Try other filters.</p>
    </template>

  </div>
</template>

<script>
export default {
  name: "invoice-quantity-graph",
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartOptions(){
        return {
            plotOptions: {
                spline: { marker: { enabled: false }},
                series: { lineWidth: 4 }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                shared: true,
                xDateFormat: '%e %b %Y'
            },
            xAxis: {
                type: 'datetime',
                labels: { format: "{value:%e %b %Y}", step: 2 }
            },
            yAxis: {
                title: { text: 'Invoices Count' },
                labels: { format: '{value}' , step: 2 }
            },
            series: [{
                name: 'Invoices',
                type: 'spline',
                color: '#8c4799',
                data: this.chartData
            }]
        }
    },
    chartData() {
        const chartData = []
        const invoiceDates = Object.keys(this.invoiceNumbersPerDay)
        const minDate = new Date(Math.min(...invoiceDates.map(date => new Date(date))))
        const maxDate = new Date(Math.max(...invoiceDates.map(date => new Date(date))))

        for (let date = minDate; date <= maxDate; date.setDate(date.getDate() + 1)) {
            const dateString = date.toISOString().split('T')[0]
            chartData.push({
                x: new Date(date).getTime(),
                y: Number(this.invoiceNumbersPerDay[dateString] || 0)
            })
        }
        return chartData
    },

    invoiceNumbersPerDay(){
        const invoiceNumbersPerDay =  this.invoices?.reduce((invoices, item) => ({
            ...invoices,
            [this.dateFormat(item.created_at)]: (invoices[this.dateFormat(item.created_at)] || 0) + 1
        }), {})

        return Object.keys(invoiceNumbersPerDay).sort().reduce(
            (obj, key) => { 
                obj[key] = invoiceNumbersPerDay[key]; 
                return obj;
            }, 
            {}
        )
    },
  },
};
</script>

<style scoped>
  .centered-element {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
  }
</style>