<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="bg-accent white--text">
                <span class="headline">Not sent Locations</span>
            </v-card-title>
            
            <v-card-text class="pa-4">
                <v-row class="filer-row">
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="2">
                        <v-text-field
                            class="filter-field"
                            v-model="tableSearch"
                            hide-details
                            append-inner-icon="mdi-magnify"
                            placeholder="Search"
                            color="accent"
                            variant="outlined"
                        >
                        </v-text-field>
                    </v-col>
                    <v-btn 
                        variant="text"
                        color="accent"
                        @click="sendAllLocation()">
                        Send all
                        <v-icon end>mdi-send</v-icon>
                    </v-btn>
                </v-row>

                <v-data-table
                    :headers="[
                        { title: 'Id.', key: 'id' },
                        { title: 'OCPI Id.', key: 'ocpi_id' },
                        { title: 'Name', key: 'name' },
                        { title: 'EVSEs', key: 'evses.length' },
                        { title: 'City', key: 'city' },
                        { title: 'Last updated', key: 'last_updated' },
                        { title: 'Actions', key: 'actions', sortable: false }
                    ]"
                    :items="locations"
                    :search="tableSearch"
                    :loading="loading"    
                    disable-sort
                    density="compact"
                    >
                    <template v-slot:item.actions="{ item }">
                        <v-btn
                        class="mr-2"
                        color="accent"
                        size="small"
                        @click="sendLocation(item)"
                        >Send</v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false" variant="text">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    inject: ['$api'],
    data() {
        return {
            show: false,
            loading: false,
            locations: [],
            providerId: null,
            tableSearch: ""
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.locations = []
                this.providerId = null
                this.loading = false
                EventBus().emit("dialog-not-sent-locations:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus().on("dialog-not-sent-locations:open", async function( args ) {
            
            if( !args.locations ){
                vm.showError("Data error, no locations")
                return false
            }

            if( !args.providerId ){
                vm.showError("Data error, no providerId")
                return false
            }

            vm.providerId = args.providerId
            vm.filterAllowedLocations(args.locations, args.providerId)
            vm.show = true

        })
    },
    methods: {
        filterAllowedLocations( locations, providerId ) {
            if ( !locations ) { return [] }

            this.loading = true
            this.$api
                .get(`provider/${providerId}/allowed-locations`)
                .then(resp => {
                    const allowedLocationIds = resp.data.map(location => location.id)
                    this.locations = locations.filter(input => allowedLocationIds.includes(input.id))
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        },
        sendLocation(location){
        
            this.loading = true

            this.$api
                .get(`location/${location.id}/send-to/providers/${this.providerId}`)
                .then(resp => {
                    this.locations = this.locations.filter(input => input.id !== location.id)
                    this.showSuccess(`Location has been sent`)
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        },
        sendAllLocation(){

            this.loading = true

            const data = {
                location_ids: this.locations.map(location => location.id)
            }

            this.$api
                .post(`locations/send-to/providers/${this.providerId}`, data)
                .then(resp => {
                    this.locations = this.locations.filter(input => !resp.data.sent.includes(input.id))
                    if (resp.data.allSuccess){
                        this.showSuccess('Locations have been sent')
                    } else {
                        this.showError('There was an error sending any of Locations')
                    }
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        }
    }
}
</script>